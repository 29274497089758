import { useUserAuthQuery, useUserQuery, type UserQuery } from 'codegen/generated/user'
import { isLoggedInQuerySelector } from './selectors'
import { useQueryClient } from '@tanstack/react-query'

export function useIsLoggedInQuery() {
  return useUserAuthQuery(undefined, {
    select: isLoggedInQuerySelector,
  })
}

useIsLoggedInQuery.fetcher = useUserAuthQuery.fetcher
useIsLoggedInQuery.getKey = useUserAuthQuery.getKey
useIsLoggedInQuery.rootKey = useUserAuthQuery.rootKey
useIsLoggedInQuery.document = useUserAuthQuery.document

export function useSetUserQuery() {
  const queryClient = useQueryClient()

  function setUserQuery(user: UserQuery) {
    queryClient.setQueryData<UserQuery>(useUserQuery.getKey(), user)
  }

  return { setUserQuery }
}
