import { useLogInMutation } from 'codegen/generated/user'
import { ChallengeType } from 'codegen/types'
import { AnimatePresence, motion } from 'framer-motion'
import { SpinnerIcon } from 'primitives/icons'
import * as React from 'react'
import { UserAuthResponse, getDeviceId, saveToken } from 'utils/auth'
import { isError } from 'utils/js'

import { isLoggedInSelector } from 'auth/selectors'
import { DialogButton } from '../dialog-button'
import { LoginInput } from '../login-input'

const CF_URL = process.env.NEXT_PUBLIC_CF_URL || process.env.VITE_CF_URL

export function LoginOtp({
  onSuccess,
  formState,
}: {
  onSuccess(user: UserAuthResponse): void
  formState: FormData | null
}) {
  const loginMutation = useLogInMutation({
    onSuccess: async function onSuccessCallback(data) {
      if (data.login?.token) {
        await saveToken(data.login.token, 'login').catch(() => {
          throw new Error('CF_INVALID_TOKEN')
        })
        onSuccess(data.login)
        return
      }

      if (data.login?.challenge?.type === ChallengeType.ChallengeOtp) {
        return
      }

      if (!data.login?.challenge) {
        throw new Error('CF_INVALID_USER')
      }

      throw new Error('UNKNOWN_LOGIN_OTP_ERROR')
    },
  })
  const ref = React.useRef<HTMLFormElement>(null)

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const currentFormState = new FormData(e.currentTarget)

    const email = formState?.get('email')?.toString() ?? ''
    const password = formState?.get('password')?.toString() ?? ''
    const deviceId = getDeviceId()
    const otp = currentFormState?.get('otp')?.toString() ?? ''

    loginMutation.mutate({
      input: {
        email,
        deviceId,
        password,
        otp,
      },
    })
  }

  function onResendOTP() {
    if (loginMutation.isLoading) {
      return null
    }

    const email = formState?.get('email')?.toString() ?? ''
    const password = formState?.get('password')?.toString() ?? ''
    const deviceId = getDeviceId()

    loginMutation.mutate(
      {
        input: {
          email,
          deviceId,
          password,
        },
      },
      {
        onSettled(data) {
          if (data?.login?.challenge?.otp) {
            const element = ref.current?.querySelector('#otp')
            if (element instanceof HTMLInputElement) {
              element.value = data.login.challenge.otp.toString()
            }
          }
        },
      },
    )
  }

  const email = formState?.get('email')?.toString()

  function resolveErrorMessage() {
    if (loginMutation.isLoading || !loginMutation.isError) {
      return null
    }

    if (loginMutation.isPaused) {
      return (
        <>It seems like you are offline, please check your internet connection and try again.</>
      )
    }

    if (isError(loginMutation.error) && loginMutation.error.message === 'CF_INVALID_OTP') {
      return <>Invalid OTP, please check your email and try again.</>
    }

    return (
      <>
        Opps, something went wrong.
        <br />
        Please try again or get in touch if the problem persists.
      </>
    )
  }

  const disabled =
    loginMutation.isLoading ||
    isLoggedInSelector(loginMutation.data?.login) ||
    Boolean(loginMutation.data?.login?.token)

  return (
    <form ref={ref} onSubmit={onSubmit} className="contents w-full">
      <div className="mb-[16px] mt-auto w-full space-y-[16px]">
        <h2 className="text-left text-xl font-extrabold text-[#272b2f]">Verify email address</h2>

        {email ? (
          <div className="text-sm text-[#4b5563]">
            <p>To verify your email, we&apos;ve sent a One Time Password (OTP) to</p>
            <p className="break-words font-bold">{email}</p>
          </div>
        ) : (
          <p className="text-sm text-[#4b5563]">
            To verify your email, we&apos;ve sent a One Time Password (OTP) to your email.
          </p>
        )}

        <div>
          <label className="mb-[4px] block cursor-pointer text-sm text-[#4b5563]" htmlFor="otp">
            OTP
          </label>

          <LoginInput.Root>
            <LoginInput.Field
              required
              defaultValue={formState?.get('otp')?.toString()}
              type="otp"
              name="otp"
              id="otp"
              autoFocus
              disabled={disabled}
            />
          </LoginInput.Root>
        </div>

        <div>
          <AnimatePresence initial={false}>
            {loginMutation.isError || loginMutation.isPaused ? (
              <motion.div
                exit={{
                  height: 0,
                  opacity: 0,
                }}
                initial={{
                  height: 0,
                  opacity: 0,
                }}
                animate={{
                  height: 'auto',
                  opacity: 1,
                }}
                className="overflow-hidden"
              >
                <p className="my-4 text-center text-[#ff3b00]">{resolveErrorMessage()}</p>
              </motion.div>
            ) : null}
          </AnimatePresence>
        </div>

        <DialogButton disabled={disabled} type="submit" className="w-full bg-violet-500">
          {disabled ? (
            <SpinnerIcon className="text-service-white h-[16px] w-[16px] animate-spin" />
          ) : (
            'Verify email address'
          )}
        </DialogButton>
        <a
          className="block text-sm text-violet-500 underline hover:text-[#272b2f]"
          href={`${CF_URL}/my-account/lost-password/`}
          target="_blank"
          rel="noreferrer"
        >
          I forgot my password
        </a>
      </div>
      <p className="mt-auto w-full text-center text-sm text-[#4b5563]">
        No email yet?{' '}
        <a href="#" onClick={onResendOTP} className="text-green-500 hover:text-[#272b2f]">
          Resend OTP
        </a>
      </p>
    </form>
  )
}
