type Target = EventTarget | Window
type Options = AddEventListenerOptions
type Type = string
type Fn = EventListener

const listenersOfTarget = new Map<Target, Map<Type, Fn>>()
const callbacksOfTarget = new Map<Target, Map<Type, Fn[]>>()

function getKey(type: Type, options: Options = {}) {
  return JSON.stringify({ type, options })
}

function createListener(target: Target, type: Type, options: Options) {
  const key = getKey(type, options)

  function listener(evt: Event) {
    const callbacks = callbacksOfTarget.get(target)!
    const current = callbacks.get(key) ?? []
    current.forEach(cb => cb(evt))
  }

  const listeners = listenersOfTarget.get(target) || new Map()
  listeners.set(key, listener)
  listenersOfTarget.set(target, listeners)

  target.addEventListener(type, listener, options)
}

function cleanupListener(target: Target, type: Type, options: Options) {
  const listeners = listenersOfTarget.get(target)
  if (!listeners) {
    return
  }

  const key = getKey(type, options)
  callbacksOfTarget.get(target)?.delete(key)

  const listener = listeners.get(key)
  if (!listener) {
    return
  }

  target.removeEventListener(type, listener)
  listeners.delete(key)
  listenersOfTarget.set(target, listeners)
}

function addCallback(target: Target, type: Type, callback: Fn, options: Options) {
  const callbacks = callbacksOfTarget.get(target) || new Map()
  const key = getKey(type, options)
  const current = callbacks.get(key)
  const next = current ? [...current, callback] : [callback]
  callbacks.set(key, next)
  callbacksOfTarget.set(target, callbacks)
}

function removeCallback(target: Target, type: Type, callback: Fn, options: Options) {
  const callbacks = callbacksOfTarget.get(target)

  if (!callbacks) {
    return
  }
  const key = getKey(type, options)
  const current = callbacks.get(key) ?? []
  const next = current ? current.filter(cb => cb !== callback) : []

  if (!next?.length) {
    cleanupListener(target, type, options)
  } else {
    callbacks.set(key, next)
    callbacksOfTarget.set(target, callbacks)
  }
}

const defaultOptions: Options = {
  passive: true,
}

export function getOrMakeListener(
  target: Target,
  type: Type,
  callback: Fn,
  options = defaultOptions,
) {
  if (!listenersOfTarget.get(target)) {
    listenersOfTarget.set(target, new Map())
  }

  const listeners = listenersOfTarget.get(target)
  const key = getKey(type, options)

  if (!listeners?.get(key)) {
    createListener(target, type, options)
  }

  addCallback(target, type, callback, options)

  return () => {
    removeCallback(target, type, callback, options)
  }
}
