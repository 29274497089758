import { useQueryClient } from '@tanstack/react-query'
import { useCfMenuUserQuery } from 'codegen/generated/cf-menu'
import {
  useUserAuthQuery,
  useUserQuery,
  useUserSubscriptionsGraphicsQuery,
} from 'codegen/generated/user'

export function useRefetchAuth() {
  const queryClient = useQueryClient()

  function refetchAuth() {
    const queries = [
      useUserSubscriptionsGraphicsQuery.rootKey,
      useCfMenuUserQuery.rootKey,
      useUserAuthQuery.rootKey,
      useUserQuery.rootKey,
    ]

    // invalidates all queries too
    queryClient.invalidateQueries()

    return Promise.all(queries.map(queryKey => queryClient.invalidateQueries([queryKey])))
  }

  return { refetchAuth }
}
