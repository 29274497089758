'use client'
import * as React from 'react'
import { tw } from 'utils/classnames'
import { useLocale } from 'next-intl'

import { Link } from '@ask-cf/components/link'

type StyledLinkProps = {
  active?: boolean
}

export function StyledLink({
  active,
  ...props
}: StyledLinkProps & React.ComponentProps<typeof Link>) {
  const locale = useLocale()

  return (
    <Link
      {...props}
      locale={locale}
      href={props.href}
      className={tw(
        'text-md',
        'text-text-tertiary flex flex-row items-center font-normal leading-6',
        'hover:text-text-hover',
        active && 'font-medium text-blue-700',
        props.className,
      )}
    >
      {props.children}
    </Link>
  )
}
