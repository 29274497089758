import * as React from 'react'
import { tw } from 'utils/classnames'

function LoginInputRoot(props: React.ComponentProps<'div'>) {
  return <div {...props} className={tw('relative', props.className)} />
}

type LoginInputFieldProps = React.ComponentProps<'input'>

const LoginInputField = React.forwardRef(function LoginInputField(
  props: LoginInputFieldProps,
  ref: React.Ref<HTMLInputElement>,
) {
  return (
    <input
      {...props}
      ref={ref}
      className={tw(
        'block min-h-[24px] w-full rounded-[8px] border border-gray-300 p-[16px] font-normal',
        // ring classes
        'outline-none outline-offset-0 ring-0 hover:border-violet-500 hover:outline-1 hover:outline-violet-500 active:border-violet-500 active:outline-1 active:outline-violet-500',
        props.className,
      )}
    />
  )
})

function LoginInputIconRight({ className, ...props }: React.ComponentProps<'div'>) {
  return (
    <div
      {...props}
      className={tw(
        'pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 text-current',
        className,
      )}
    />
  )
}

function LoginInputIconLeft({ className, ...props }: React.ComponentProps<'div'>) {
  return (
    <div
      {...props}
      className={tw(
        'pointer-events-none absolute inset-y-0 left-0 flex items-center px-3 text-current',
        className,
      )}
    />
  )
}

export const LoginInput = Object.assign(LoginInputRoot, {
  Root: LoginInputRoot,
  Field: LoginInputField,
  IconRight: LoginInputIconRight,
  IconLeft: LoginInputIconLeft,
})
