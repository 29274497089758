import { tw } from 'utils/classnames'

export function Tag({
  className,
  children,
  ...rest
}: PropsWithChildren<React.ComponentProps<'div'>>) {
  return (
    <div className={tw('text-12 w-fit gap-1 rounded-[16px] border px-2 py-1', className)} {...rest}>
      {children}
    </div>
  )
}
