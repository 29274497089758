'use client'
import { motion } from 'framer-motion'
import { tw } from 'utils/classnames'

export function SkeletonItem({ className }: { className?: string }) {
  const shimmerVariants = {
    animate: {
      backgroundPosition: ['100% 50%', '-100% 50%'],
      transition: {
        duration: 1.5,
        ease: 'linear',
        repeat: Infinity,
      },
    },
  }

  const gradientStyles = {
    backgroundImage: 'linear-gradient(to right, #E7E5E5 0%, #F9F8F8 50%, #E7E5E5 100%)',
    backgroundSize: '200% 100%',
  }

  return (
    <motion.div
      className={tw('h-5 w-full rounded-[6px] bg-gray-800', className)}
      style={gradientStyles}
      variants={shimmerVariants}
      animate="animate"
    />
  )
}
