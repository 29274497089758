import * as React from 'react'
import { tw } from 'utils/classnames'
import { XCloseIcon } from 'primitives/icons'

export type CloseButtonVariant = 'primary' | 'secondary'

function CloseButtonRoot(
  {
    children,
    variant = 'secondary',
    ...props
  }: React.ComponentProps<'button'> & { variant?: CloseButtonVariant },
  ref: React.Ref<HTMLButtonElement>,
) {
  return (
    <button
      {...props}
      ref={ref}
      className={tw(
        'group flex h-[24px] w-[24px] items-center justify-center rounded-full p-[4px]',
        'hover:bg-gray-700 hover:text-gray-700',
        'outline-none focus-visible:ring-2 focus-visible:ring-violet-500',
        variant === 'primary' &&
          'hover:bg-gray-700 hover:text-gray-300 focus:bg-gray-900 focus:text-gray-400 active:bg-gray-900 active:text-gray-400',
        props.className,
      )}
    >
      {children}
      <XCloseIcon className="group-hover:text-service-white" />
    </button>
  )
}

export const CloseButton = React.forwardRef(CloseButtonRoot)
