/* 👻🚨👻🚨👻 Let op! This code has been auto generated, so best not to touch it 👻🚨👻🚨👻 */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Time: { input: any; output: any; }
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  line1: Scalars['String']['output'];
  line2: Scalars['String']['output'];
  postalCode: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

export type AddressInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  line1: Scalars['String']['input'];
  line2: Scalars['String']['input'];
  postalCode: Scalars['String']['input'];
  state: Scalars['String']['input'];
};

export enum AdvertisingAssetType {
  Image = 'image',
  Logo = 'logo',
  Unspecified = 'unspecified',
  Video = 'video'
}

export type AdvertisingCreateCampaignInput = {
  campaignCtaText?: InputMaybe<Scalars['String']['input']>;
  campaignText?: InputMaybe<Scalars['String']['input']>;
  campaignTitle?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  destinationUrl: Scalars['String']['input'];
  endDate: Scalars['Time']['input'];
  images?: InputMaybe<Array<CampaignAssetInput>>;
  logos?: InputMaybe<Array<CampaignAssetInput>>;
  name: Scalars['String']['input'];
  startDate: Scalars['Time']['input'];
  targetGeolocation: AdvertisingGeolocation;
  template: CampaignTemplateType;
  videos?: InputMaybe<Array<CampaignAssetInput>>;
};

export type AdvertisingCreateCampaignResponse = {
  __typename?: 'AdvertisingCreateCampaignResponse';
  campaign?: Maybe<Campaign>;
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type AdvertisingDeleteCampaignInput = {
  id: Scalars['ID']['input'];
};

export type AdvertisingDeleteCampaignResponse = {
  __typename?: 'AdvertisingDeleteCampaignResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export enum AdvertisingGeolocation {
  Global = 'global'
}

export type AdvertisingGetCampaignInput = {
  id: Scalars['ID']['input'];
};

export type AdvertisingGetCampaignResponse = {
  __typename?: 'AdvertisingGetCampaignResponse';
  campaign?: Maybe<Campaign>;
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type AdvertisingGetRandomNCampaignsInput = {
  assetType?: InputMaybe<AdvertisingAssetType>;
  campaignQuantity?: InputMaybe<Scalars['Int']['input']>;
  geolocation?: InputMaybe<AdvertisingGeolocation>;
};

export type AdvertisingGetRandomNCampaignsResponse = {
  __typename?: 'AdvertisingGetRandomNCampaignsResponse';
  campaigns?: Maybe<Array<Campaign>>;
  errors?: Maybe<Array<ErrorMessage>>;
};

export type AdvertisingGetTotalActionsByMonthInput = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
};

export type AdvertisingGetTotalActionsByMonthResponse = {
  __typename?: 'AdvertisingGetTotalActionsByMonthResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  monthlyActions?: Maybe<Array<MonthlyAction>>;
  pagination?: Maybe<AdvertisingMonthlyActionListPagination>;
};

export type AdvertisingGetUploadedInput = {
  uploadId: Scalars['String']['input'];
};

export type AdvertisingGetUploadedResponse = {
  __typename?: 'AdvertisingGetUploadedResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  imageUrl?: Maybe<Scalars['String']['output']>;
};

export type AdvertisingIncreaseClickForCampaignInput = {
  clickTime: Scalars['Time']['input'];
  id: Scalars['String']['input'];
};

export type AdvertisingIncreaseClickForCampaignResponse = {
  __typename?: 'AdvertisingIncreaseClickForCampaignResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type AdvertisingIncreaseViewForCampaignInput = {
  id: Scalars['String']['input'];
  viewTime: Scalars['Time']['input'];
};

export type AdvertisingIncreaseViewForCampaignResponse = {
  __typename?: 'AdvertisingIncreaseViewForCampaignResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type AdvertisingListCampaignInput = {
  currentPage: Scalars['Int']['input'];
  itemsPerPage: Scalars['Int']['input'];
};

export type AdvertisingListCampaignResponse = {
  __typename?: 'AdvertisingListCampaignResponse';
  campaigns?: Maybe<Array<Campaign>>;
  errors?: Maybe<Array<ErrorMessage>>;
  pagination?: Maybe<AdvertisingListPagination>;
};

export type AdvertisingListPagination = {
  __typename?: 'AdvertisingListPagination';
  currentPage: Scalars['Int']['output'];
  itemsPerPage: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type AdvertisingMonthlyActionListPagination = {
  __typename?: 'AdvertisingMonthlyActionListPagination';
  currentPage: Scalars['Int']['output'];
  itemsPerPage: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type AdvertisingUpdateCampaignInput = {
  campaignCtaText?: InputMaybe<Scalars['String']['input']>;
  campaignText?: InputMaybe<Scalars['String']['input']>;
  campaignTitle?: InputMaybe<Scalars['String']['input']>;
  deleteAssetsIds?: InputMaybe<Array<Scalars['String']['input']>>;
  description?: InputMaybe<Scalars['String']['input']>;
  destinationUrl?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Time']['input']>;
  id: Scalars['ID']['input'];
  images?: InputMaybe<Array<CampaignAssetInput>>;
  logos?: InputMaybe<Array<CampaignAssetInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['Time']['input']>;
  targetGeolocation?: InputMaybe<AdvertisingGeolocation>;
  template?: InputMaybe<CampaignTemplateType>;
  videos?: InputMaybe<Array<CampaignAssetInput>>;
};

export type AdvertisingUpdateCampaignResponse = {
  __typename?: 'AdvertisingUpdateCampaignResponse';
  campaign?: Maybe<Campaign>;
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type AdvertisingUploadsCreateSignedUrlInput = {
  uploadFile: Scalars['String']['input'];
};

export type AdvertisingUploadsCreateSignedUrlResponse = {
  __typename?: 'AdvertisingUploadsCreateSignedUrlResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  id: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

export type AffiliateInfo = {
  __typename?: 'AffiliateInfo';
  affiliateId: Scalars['ID']['output'];
  affiliatePercentage: Scalars['Float']['output'];
};

export type AllAccessBanner = {
  __typename?: 'AllAccessBanner';
  backgroundColor: Scalars['String']['output'];
  bannerAboveNav: Scalars['String']['output'];
  bannerAboveNavPromo: Scalars['Int']['output'];
  bannerAboveNavState: Scalars['String']['output'];
  button: AllAccessBannerButton;
  countdown?: Maybe<SiteBannerCountdown>;
  text: Scalars['String']['output'];
  textColor: Scalars['String']['output'];
  type: SiteBannerType;
};

export type AllAccessBannerButton = {
  __typename?: 'AllAccessBannerButton';
  backgroundColor: Scalars['String']['output'];
  hoverTextColor: Scalars['String']['output'];
  link: Scalars['String']['output'];
  text: Scalars['String']['output'];
  textColor: Scalars['String']['output'];
};

export type AllAccessYearlyBanner = {
  __typename?: 'AllAccessYearlyBanner';
  backgroundImage: Scalars['String']['output'];
  bannerAboveNav: Scalars['String']['output'];
  bannerAboveNavState: Scalars['String']['output'];
  buttonText: Scalars['String']['output'];
  buttonUrl: Scalars['String']['output'];
  features: Array<Scalars['String']['output']>;
  headline: Scalars['String']['output'];
  isHidden: Scalars['Boolean']['output'];
  showInHeader: Scalars['Boolean']['output'];
  type: SiteBannerType;
  yearly: Scalars['Boolean']['output'];
};

export type AllAccessYearlyExtendBanner = {
  __typename?: 'AllAccessYearlyExtendBanner';
  backgroundImage: Scalars['String']['output'];
  bannerAboveNav: Scalars['String']['output'];
  bannerAboveNavState: Scalars['String']['output'];
  buttonText: Scalars['String']['output'];
  countdownDt?: Maybe<Scalars['String']['output']>;
  features: Array<Scalars['String']['output']>;
  headline: Scalars['String']['output'];
  inProgress: Scalars['Boolean']['output'];
  lastOrderPaymentUrl: Scalars['String']['output'];
  subscriptionId: Scalars['String']['output'];
  type: SiteBannerType;
};

export type Answer = {
  __typename?: 'Answer';
  answer: Scalars['String']['output'];
  assets?: Maybe<Array<AskCfAsset>>;
  comments?: Maybe<Array<Comment>>;
  createTime: Scalars['DateTime']['output'];
  downvotes: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  isBestAnswer: Scalars['Boolean']['output'];
  isGenieAnswer: Scalars['Boolean']['output'];
  upvotes: Scalars['Int']['output'];
  user: PublicUserProfile;
  /** @deprecated Use user field instead */
  userId?: Maybe<Scalars['ID']['output']>;
};

export type AskCfAsset = {
  __typename?: 'AskCFAsset';
  id: Scalars['ID']['output'];
  optimizedUrl?: Maybe<Scalars['String']['output']>;
  type: AskCfAssetType;
  url: Scalars['String']['output'];
};

export type AskCfAssetInput = {
  assetType: AskCfAssetTypeInput;
  assetUrl: Scalars['String']['input'];
};

export enum AskCfAssetType {
  Image = 'image',
  Unspecified = 'unspecified',
  Video = 'video'
}

export enum AskCfAssetTypeInput {
  Image = 'image',
  Unspecified = 'unspecified',
  Video = 'video'
}

export type AskCfConfirmQuestionInput = {
  questionId: Scalars['String']['input'];
};

export type AskCfConfirmQuestionResponse = {
  __typename?: 'AskCFConfirmQuestionResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  question?: Maybe<Question>;
  success: Scalars['Boolean']['output'];
};

export type AskCfConfirmTopicInput = {
  topicId?: InputMaybe<Scalars['ID']['input']>;
  topicName?: InputMaybe<Scalars['String']['input']>;
  topicSlug: Scalars['String']['input'];
};

export type AskCfConfirmTopicResponse = {
  __typename?: 'AskCFConfirmTopicResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type AskCfDeleteAnswerInput = {
  answerId: Scalars['String']['input'];
  deleteReason: Scalars['String']['input'];
};

export type AskCfDeleteAnswerResponse = {
  __typename?: 'AskCFDeleteAnswerResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type AskCfDeleteAnswersFromUserInput = {
  deleteReason: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type AskCfDeleteAnswersFromUserResponse = {
  __typename?: 'AskCFDeleteAnswersFromUserResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type AskCfDeleteQuestionInput = {
  deleteReason: Scalars['String']['input'];
  questionId: Scalars['String']['input'];
};

export type AskCfDeleteQuestionResponse = {
  __typename?: 'AskCFDeleteQuestionResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type AskCfDeleteQuestionsFromUserInput = {
  deleteReason: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type AskCfDeleteQuestionsFromUserResponse = {
  __typename?: 'AskCFDeleteQuestionsFromUserResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type AskCfDiscardQuestionInput = {
  questionId: Scalars['String']['input'];
};

export type AskCfDiscardQuestionResponse = {
  __typename?: 'AskCFDiscardQuestionResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type AskCfFeedResponse = {
  __typename?: 'AskCFFeedResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  pagination?: Maybe<AskCfListQuestionsPagination>;
  questions?: Maybe<Array<Question>>;
};

export type AskCfGetQuestionInput = {
  answersPage: Scalars['Int']['input'];
  answersPerPage: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['ID']['input']>;
  questionSlug?: InputMaybe<Scalars['String']['input']>;
};

export type AskCfGetQuestionResponse = {
  __typename?: 'AskCFGetQuestionResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  question?: Maybe<Question>;
  success: Scalars['Boolean']['output'];
};

export type AskCfGetTopicInput = {
  topicId?: InputMaybe<Scalars['ID']['input']>;
  topicSlug?: InputMaybe<Scalars['String']['input']>;
};

export type AskCfGetUploadedInput = {
  uploadId: Scalars['String']['input'];
};

export type AskCfGetUploadedResponse = {
  __typename?: 'AskCFGetUploadedResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  imageUrl?: Maybe<Scalars['String']['output']>;
};

export type AskCfGetUserStatsInput = {
  userId: Scalars['ID']['input'];
};

export type AskCfHomeFeedInput = {
  currentPage: Scalars['Int']['input'];
  itemsPerPage: Scalars['Int']['input'];
};

export type AskCfListQuestionsPagination = {
  __typename?: 'AskCFListQuestionsPagination';
  currentPage: Scalars['Int']['output'];
  itemsPerPage: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type AskCfPostAnswerInput = {
  answer: Scalars['String']['input'];
  assets?: InputMaybe<Array<AskCfAssetInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  questionId: Scalars['String']['input'];
};

export type AskCfPostAnswerResponse = {
  __typename?: 'AskCFPostAnswerResponse';
  answer?: Maybe<Answer>;
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type AskCfPostQuestionInput = {
  assets?: InputMaybe<Array<AskCfAssetInput>>;
  context?: InputMaybe<Scalars['String']['input']>;
  question: Scalars['String']['input'];
};

export type AskCfPostQuestionResponse = {
  __typename?: 'AskCFPostQuestionResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  question?: Maybe<Question>;
  success: Scalars['Boolean']['output'];
};

export type AskCfSetBestAnswerInput = {
  answerId: Scalars['ID']['input'];
  questionId: Scalars['ID']['input'];
};

export type AskCfSetBestAnswerResponse = {
  __typename?: 'AskCFSetBestAnswerResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type AskCfTopic = {
  __typename?: 'AskCFTopic';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  questionCount: Scalars['Int']['output'];
  textIdentifier: Scalars['String']['output'];
  topicSlug: Scalars['String']['output'];
  userFollows: Scalars['Boolean']['output'];
};

export type AskCfTopicDeleteInput = {
  topicId: Scalars['ID']['input'];
};

export type AskCfTopicDeleteResponse = {
  __typename?: 'AskCFTopicDeleteResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type AskCfTopicFeedInput = {
  currentPage: Scalars['Int']['input'];
  itemsPerPage: Scalars['Int']['input'];
  topicId?: InputMaybe<Scalars['ID']['input']>;
  topicSlug?: InputMaybe<Scalars['String']['input']>;
};

export type AskCfUploadCreateSignedUrlInput = {
  uploadFile: Scalars['String']['input'];
};

export type AskCfUploadCreateSignedUrlResponse = {
  __typename?: 'AskCFUploadCreateSignedUrlResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  id: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

export type AskCfUserAnsweredFeedInput = {
  currentPage: Scalars['Int']['input'];
  itemsPerPage: Scalars['Int']['input'];
  userId: Scalars['ID']['input'];
};

export type AskCfUserFeedInput = {
  currentPage: Scalars['Int']['input'];
  itemsPerPage: Scalars['Int']['input'];
  userId?: InputMaybe<Scalars['ID']['input']>;
  userSlug?: InputMaybe<Scalars['String']['input']>;
};

export type AskCfUserStats = {
  __typename?: 'AskCFUserStats';
  answerCount: Scalars['Int']['output'];
  questionCount: Scalars['Int']['output'];
  userId: Scalars['ID']['output'];
};

export type AssetDownloadResponse = {
  __typename?: 'AssetDownloadResponse';
  assetId: Scalars['ID']['output'];
  /** This field is populated when skip trial is triggered */
  paymentRequired?: Maybe<ContentPaymentRequired>;
  /** Note that we currently only support the ID (`product.id`) */
  product: ContentProduct;
  /** @deprecated Use `product.id` instead */
  product_id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type AssetDownloadResponseV2 = {
  __typename?: 'AssetDownloadResponseV2';
  assetId: Scalars['ID']['output'];
  productId: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export type AssetsDownloadResponseV2 = {
  __typename?: 'AssetsDownloadResponseV2';
  assets: Array<AssetDownloadResponseV2>;
  errors: Array<ErrorMessage>;
};

export type AuthChallenge = {
  __typename?: 'AuthChallenge';
  email?: Maybe<Scalars['String']['output']>;
  otp?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  type: ChallengeType;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  currency?: Maybe<Currency>;
  errors?: Maybe<Array<ErrorMessage>>;
  subscriptionId: Scalars['String']['output'];
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export enum AuthType {
  AuthTypeApple = 'authTypeApple',
  AuthTypeFacebook = 'authTypeFacebook',
  AuthTypeGoogle = 'authTypeGoogle'
}

export enum AvailableCurrencyCode {
  Eur = 'eur',
  Gbp = 'gbp',
  Usd = 'usd'
}

export enum AvailablePaymentMethod {
  BacsDebit = 'bacsDebit',
  Card = 'card',
  Paypal = 'paypal',
  SepaDebit = 'sepaDebit'
}

export type AwaitingRenewalConfirmationBanner = {
  __typename?: 'AwaitingRenewalConfirmationBanner';
  backgroundColor: Scalars['String']['output'];
  bannerAboveNav: Scalars['String']['output'];
  bannerAboveNavState: Scalars['String']['output'];
  text: Scalars['String']['output'];
  type: SiteBannerType;
};

export type BillingDetails = {
  __typename?: 'BillingDetails';
  address: Address;
  email: Scalars['String']['output'];
  euTaxId: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
};

export type BillingDetailsInput = {
  address: AddressInput;
  email: Scalars['String']['input'];
  euTaxId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
};

export enum BillingPeriod {
  Month = 'month',
  Year = 'year'
}

export type BlocksRange = {
  __typename?: 'BlocksRange';
  maxBlocksAllowed: Scalars['Int']['output'];
  minBlocksAllowed: Scalars['Int']['output'];
};

export type CfMainEnterpriseRequestInput = {
  answer: Scalars['String']['input'];
  asset: Scalars['String']['input'];
  createdAt?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  employees: EnterpriseRequestEmployees;
  job?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  owner?: InputMaybe<Scalars['Int']['input']>;
  project?: InputMaybe<Scalars['String']['input']>;
  question: Scalars['String']['input'];
  usage: Scalars['String']['input'];
};

export type CfMainEnterpriseRequestResponse = {
  __typename?: 'CFMainEnterpriseRequestResponse';
  enterpriseRequests?: Maybe<EnterpriseRequest>;
  errors?: Maybe<Array<ErrorMessage>>;
};

export type CfMainGetEnterpriseRequestInput = {
  id: Scalars['Int']['input'];
};

export type CfMainGetEnterpriseRequestResponse = {
  __typename?: 'CFMainGetEnterpriseRequestResponse';
  enterpriseRequest?: Maybe<EnterpriseRequest>;
  errors?: Maybe<Array<ErrorMessage>>;
};

export type CfMainListEnterpriseRequestsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type CfMainListEnterpriseRequestsPagination = {
  __typename?: 'CFMainListEnterpriseRequestsPagination';
  limit: Scalars['Int']['output'];
  nextOffset: Scalars['Int']['output'];
  offset: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type CfMainListEnterpriseRequestsResponse = {
  __typename?: 'CFMainListEnterpriseRequestsResponse';
  enterpriseRequests?: Maybe<Array<EnterpriseRequest>>;
  errors?: Maybe<Array<ErrorMessage>>;
  pagination?: Maybe<CfMainListEnterpriseRequestsPagination>;
};

export type CfMainUpdateEnterpriseRequestInput = {
  id: Scalars['Int']['input'];
  owner: Scalars['Int']['input'];
};

export type CfMainUpdateEnterpriseRequestResponse = {
  __typename?: 'CFMainUpdateEnterpriseRequestResponse';
  enterpriseRequests?: Maybe<EnterpriseRequest>;
  errors?: Maybe<Array<ErrorMessage>>;
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type CalculatePricesInput = {
  currency: CurrencyCode;
  match?: InputMaybe<Scalars['Boolean']['input']>;
  prices: Array<Scalars['Int']['input']>;
};

export type CalculatePricesResponse = {
  __typename?: 'CalculatePricesResponse';
  currency: CurrencyCode;
  prices: Array<TransformedPricesResponse>;
};

export type Campaign = {
  __typename?: 'Campaign';
  clicks: Scalars['Int']['output'];
  content: Contents;
  createTime: Scalars['DateTime']['output'];
  ctr: Scalars['Float']['output'];
  description?: Maybe<Scalars['String']['output']>;
  endDate: Scalars['DateTime']['output'];
  geolocation: AdvertisingGeolocation;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  views: Scalars['Int']['output'];
};

export type CampaignAsset = {
  __typename?: 'CampaignAsset';
  assetType: AdvertisingAssetType;
  assetUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  placementOrder: Scalars['Int']['output'];
};

export type CampaignAssetInput = {
  assetType: AdvertisingAssetType;
  assetUrl: Scalars['String']['input'];
  placementOrder: Scalars['Int']['input'];
};

export enum CampaignTemplateType {
  ImageAndLogo = 'imageAndLogo',
  ImageOnly = 'imageOnly',
  ImagesAndText = 'imagesAndText',
  VideoAndImage = 'videoAndImage',
  VideoOnly = 'videoOnly'
}

export enum ChallengeType {
  Challenge2fa = 'challenge2fa',
  ChallengeOtp = 'challengeOtp'
}

export enum ChangeUserRoleStatus {
  AwaitingAdminApproval = 'AwaitingAdminApproval',
  Unknown = 'Unknown',
  Updated = 'Updated'
}

export type ClassesBanner = {
  __typename?: 'ClassesBanner';
  backgroundColor: Scalars['String']['output'];
  bannerTitleClass: Scalars['String']['output'];
  button: ClassesBannerButton;
  fontSize: Scalars['String']['output'];
  hideByDefault: Scalars['Boolean']['output'];
  lineHeight: Scalars['String']['output'];
  text: Scalars['String']['output'];
  textColor: Scalars['String']['output'];
  type: SiteBannerType;
};

export type ClassesBannerButton = {
  __typename?: 'ClassesBannerButton';
  backgroundColor: Scalars['String']['output'];
  link: Scalars['String']['output'];
  text: Scalars['String']['output'];
  textColor: Scalars['String']['output'];
};

export enum ClientType {
  ClientTypeAndroid = 'ClientTypeAndroid',
  ClientTypeIos = 'ClientTypeIOS',
  ClientTypeWeb = 'ClientTypeWeb'
}

export type Comment = {
  __typename?: 'Comment';
  comment: Scalars['String']['output'];
  createTime: Scalars['DateTime']['output'];
  downvotes: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  upvotes: Scalars['Int']['output'];
  user: PublicUserProfile;
};

export type ContentAlternativeSlug = {
  __typename?: 'ContentAlternativeSlug';
  language: ContentLanguage;
  slug: Scalars['String']['output'];
};

export type ContentAsset = {
  __typename?: 'ContentAsset';
  downloadable: Scalars['Boolean']['output'];
  downloads_count: Scalars['Int']['output'];
  file_path: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  product_id: Scalars['ID']['output'];
  resolution?: Maybe<ContentAssetResolution>;
  size_bytes: Scalars['Int']['output'];
  type: ContentAssetType;
  variant: ContentAssetVariant;
};

export type ContentAssetResolution = {
  __typename?: 'ContentAssetResolution';
  height: Scalars['Int']['output'];
  width: Scalars['Int']['output'];
};

export type ContentAssetResolutionV2 = {
  __typename?: 'ContentAssetResolutionV2';
  height: Scalars['Int']['output'];
  width: Scalars['Int']['output'];
};

export enum ContentAssetType {
  AssetTypeBackgroundRemoved = 'ASSET_TYPE_BACKGROUND_REMOVED',
  AssetTypeDownscaled = 'ASSET_TYPE_DOWNSCALED',
  AssetTypeOriginal = 'ASSET_TYPE_ORIGINAL',
  AssetTypeOriginalUnoptimized = 'ASSET_TYPE_ORIGINAL_UNOPTIMIZED',
  AssetTypePreview = 'ASSET_TYPE_PREVIEW',
  AssetTypePrintable = 'ASSET_TYPE_PRINTABLE',
  AssetTypeThumbnail = 'ASSET_TYPE_THUMBNAIL',
  AssetTypeUnspecified = 'ASSET_TYPE_UNSPECIFIED',
  AssetTypeUpscaled = 'ASSET_TYPE_UPSCALED'
}

export enum ContentAssetTypeV2 {
  /** This asset type is generated from the original image by applying a background remove algorithm. */
  AssetTypeBackgroundRemoved = 'ASSET_TYPE_BACKGROUND_REMOVED',
  AssetTypeDownscaled = 'ASSET_TYPE_DOWNSCALED',
  AssetTypeOriginal = 'ASSET_TYPE_ORIGINAL',
  AssetTypePreview = 'ASSET_TYPE_PREVIEW',
  AssetTypeThumbnail = 'ASSET_TYPE_THUMBNAIL',
  AssetTypeUnspecified = 'ASSET_TYPE_UNSPECIFIED',
  AssetTypeUpscaled = 'ASSET_TYPE_UPSCALED'
}

export type ContentAssetV2 = {
  __typename?: 'ContentAssetV2';
  downloadsCount: Scalars['Int']['output'];
  filePath: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  productId: Scalars['ID']['output'];
  resolution?: Maybe<ContentAssetResolutionV2>;
  sizeBytes: Scalars['Int']['output'];
  type: ContentAssetTypeV2;
  variant: ContentAssetVariantV2;
};

export enum ContentAssetVariant {
  AssetVariantDesktop = 'ASSET_VARIANT_DESKTOP',
  AssetVariantLarge = 'ASSET_VARIANT_LARGE',
  AssetVariantMedium = 'ASSET_VARIANT_MEDIUM',
  AssetVariantMobile = 'ASSET_VARIANT_MOBILE',
  AssetVariantSmall = 'ASSET_VARIANT_SMALL',
  AssetVariantUnspecified = 'ASSET_VARIANT_UNSPECIFIED',
  AssetVariantWatermarked = 'ASSET_VARIANT_WATERMARKED',
  AssetVariantXlarge = 'ASSET_VARIANT_XLARGE',
  AssetVariantXsmall = 'ASSET_VARIANT_XSMALL'
}

export enum ContentAssetVariantV2 {
  AssetVariantDesktop = 'ASSET_VARIANT_DESKTOP',
  AssetVariantLarge = 'ASSET_VARIANT_LARGE',
  AssetVariantMedium = 'ASSET_VARIANT_MEDIUM',
  AssetVariantMobile = 'ASSET_VARIANT_MOBILE',
  AssetVariantSmall = 'ASSET_VARIANT_SMALL',
  AssetVariantUnspecified = 'ASSET_VARIANT_UNSPECIFIED',
  AssetVariantWatermarked = 'ASSET_VARIANT_WATERMARKED',
  AssetVariantXlarge = 'ASSET_VARIANT_XLARGE'
}

export type ContentCategory = {
  __typename?: 'ContentCategory';
  _language: ContentLanguage;
  alternativeSlugs: Array<ContentCategorySlug>;
  ancestors: Array<Scalars['String']['output']>;
  contentBottom: Scalars['String']['output'];
  contentTop: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parentId: Scalars['String']['output'];
  parents: Array<ContentCategory>;
  root?: Maybe<ContentCategory>;
  seo: ContentCategorySeo;
  slug: Scalars['String']['output'];
  totalProducts: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type ContentCategorySeo = {
  __typename?: 'ContentCategorySEO';
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ContentCategorySeoV2 = {
  __typename?: 'ContentCategorySeoV2';
  description: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ContentCategorySlug = {
  __typename?: 'ContentCategorySlug';
  language: ContentLanguage;
  slug: Scalars['String']['output'];
};

export type ContentCategorySlugV2 = {
  __typename?: 'ContentCategorySlugV2';
  language: ContentLanguageV2;
  slug: Scalars['String']['output'];
};

export type ContentCategoryV2 = {
  __typename?: 'ContentCategoryV2';
  _language: ContentLanguageV2;
  alternativeSlugs: Array<ContentCategorySlugV2>;
  ancestors: Array<Scalars['String']['output']>;
  contentBottom: Scalars['String']['output'];
  contentTop: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parentId: Scalars['String']['output'];
  parents: Array<ContentCategoryV2>;
  root?: Maybe<ContentCategoryV2>;
  seo: ContentCategorySeoV2;
  slug: Scalars['String']['output'];
  totalProducts: Scalars['Int']['output'];
  type: Scalars['String']['output'];
};

export type ContentCreateFavoriteListInput = {
  name: Scalars['String']['input'];
};

export type ContentCreateReview = {
  body: Scalars['String']['input'];
  getInTouch?: InputMaybe<Scalars['Boolean']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['String']['input'];
  rating?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentDownloadAssetsInput = {
  ids: Array<Scalars['ID']['input']>;
};

export enum ContentDownloadSource {
  Studio = 'Studio',
  Website = 'Website'
}

export type ContentEditReview = {
  body?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['ID']['input'];
  rating?: InputMaybe<Scalars['Int']['input']>;
};

export type ContentFavorite = {
  __typename?: 'ContentFavorite';
  categories?: Maybe<Array<ContentCategory>>;
  categoryIds?: Maybe<Array<Scalars['ID']['output']>>;
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  listIds: Array<Scalars['ID']['output']>;
  lists: Array<ContentFavoriteList>;
  product: ContentProduct;
  productId: Scalars['ID']['output'];
  updatedAt: Scalars['Time']['output'];
  userId: Scalars['ID']['output'];
};

export type ContentFavoriteList = {
  __typename?: 'ContentFavoriteList';
  createdAt: Scalars['Time']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  updatedAt: Scalars['Time']['output'];
  userId: Scalars['ID']['output'];
};

export type ContentFetchLicenseInput = {
  key?: InputMaybe<Scalars['ID']['input']>;
  productID: Scalars['ID']['input'];
  source?: InputMaybe<ContentDownloadSource>;
};

export enum ContentLanguage {
  LanguageDe = 'LANGUAGE_DE',
  LanguageEn = 'LANGUAGE_EN',
  LanguageEs = 'LANGUAGE_ES',
  LanguageFr = 'LANGUAGE_FR',
  LanguageIt = 'LANGUAGE_IT',
  LanguageNl = 'LANGUAGE_NL',
  LanguagePl = 'LANGUAGE_PL',
  LanguagePtBr = 'LANGUAGE_PT_BR'
}

export enum ContentLanguageV2 {
  LanguageDe = 'LANGUAGE_DE',
  LanguageEn = 'LANGUAGE_EN',
  LanguageEs = 'LANGUAGE_ES',
  LanguageFr = 'LANGUAGE_FR',
  LanguageIt = 'LANGUAGE_IT',
  LanguageNl = 'LANGUAGE_NL',
  LanguagePl = 'LANGUAGE_PL',
  LanguagePtBr = 'LANGUAGE_PT_BR'
}

export type ContentLicense = {
  __typename?: 'ContentLicense';
  createdAt: Scalars['DateTime']['output'];
  key: Scalars['ID']['output'];
};

export type ContentLinkBlock = {
  __typename?: 'ContentLinkBlock';
  id: Scalars['ID']['output'];
  links: Array<ContentLinkBlockLink>;
  title: Scalars['String']['output'];
  type: ContentLinkBlockType;
};

export type ContentLinkBlockLink = {
  __typename?: 'ContentLinkBlockLink';
  anchor: Scalars['String']['output'];
  url: Scalars['String']['output'];
};

export enum ContentLinkBlockType {
  Generated = 'GENERATED',
  Manual = 'MANUAL'
}

export type ContentListFavoritesInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  listId?: InputMaybe<Scalars['ID']['input']>;
};

export type ContentMetadata = {
  __typename?: 'ContentMetadata';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type ContentPagination = {
  __typename?: 'ContentPagination';
  categoryId: Scalars['ID']['output'];
  currentPage: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
  pages: Scalars['Int']['output'];
  products: Array<ContentProduct>;
  type: ContentPaginationType;
};

export enum ContentPaginationType {
  DiscoverMore = 'DiscoverMore',
  Popularity = 'Popularity',
  Recommended = 'Recommended',
  Unspecified = 'Unspecified'
}

export type ContentPaymentRequired = {
  __typename?: 'ContentPaymentRequired';
  paymentUrl: Scalars['String']['output'];
  status: Scalars['String']['output'];
};

export type ContentProduct = {
  __typename?: 'ContentProduct';
  _language: ContentLanguage;
  alternativeSlugs?: Maybe<Array<ContentAlternativeSlug>>;
  assets: Array<ContentAsset>;
  author_id: Scalars['String']['output'];
  canonicalId: Scalars['String']['output'];
  category: ContentCategory;
  category_id: Scalars['String']['output'];
  created_at: Scalars['Time']['output'];
  deleted_at?: Maybe<Scalars['Time']['output']>;
  description: Scalars['String']['output'];
  downloads_count: Scalars['Int']['output'];
  format: ContentProductFormat;
  id: Scalars['String']['output'];
  licenses: Array<ContentLicense>;
  linkBlocks: Array<ContentLinkBlock>;
  metadata: Array<ContentMetadata>;
  pricing: ContentProductPricing;
  publicAssets: Array<ContentAsset>;
  /** @deprecated Use top-level fields instead, e.g: title, description */
  seo: ContentSeo;
  slug: Scalars['String']['output'];
  status: ContentProductStatus;
  tags?: Maybe<Array<Scalars['String']['output']>>;
  title: Scalars['String']['output'];
  type: ContentProductType;
  updated_at: Scalars['Time']['output'];
  url: Scalars['String']['output'];
  user_id: Scalars['String']['output'];
  views_count: Scalars['Int']['output'];
  woocommerce_product_id?: Maybe<Scalars['Int']['output']>;
};

export type ContentProductFavoriteCount = {
  __typename?: 'ContentProductFavoriteCount';
  total: Scalars['Int']['output'];
  userIds: Array<Scalars['ID']['output']>;
  users: Array<PublicUserProfile>;
};

export enum ContentProductFormat {
  ProductFormatLandscape_3_2 = 'PRODUCT_FORMAT_LANDSCAPE_3_2',
  ProductFormatLandscape_16_9 = 'PRODUCT_FORMAT_LANDSCAPE_16_9',
  ProductFormatPortrait_2_3 = 'PRODUCT_FORMAT_PORTRAIT_2_3',
  ProductFormatPortrait_4_5 = 'PRODUCT_FORMAT_PORTRAIT_4_5',
  ProductFormatPortrait_9_16 = 'PRODUCT_FORMAT_PORTRAIT_9_16',
  ProductFormatSquare_1_1 = 'PRODUCT_FORMAT_SQUARE_1_1',
  ProductFormatUnspecified = 'PRODUCT_FORMAT_UNSPECIFIED'
}

export type ContentProductPricing = {
  __typename?: 'ContentProductPricing';
  price: Scalars['Int']['output'];
  sale_price?: Maybe<Scalars['Int']['output']>;
};

export type ContentProductReviews = {
  __typename?: 'ContentProductReviews';
  _language: ContentLanguage;
  cursor: Scalars['String']['output'];
  product: ContentProduct;
  productId: Scalars['ID']['output'];
  rating: ContentReviewRating;
  replies: Array<ContentReview>;
  reviews: Array<ContentReview>;
};

export type ContentProductReviewsInput = {
  cursor?: InputMaybe<Scalars['String']['input']>;
  language: ContentLanguage;
  limit: Scalars['Int']['input'];
  productId: Scalars['ID']['input'];
};

export enum ContentProductStatus {
  StatusApproved = 'STATUS_APPROVED',
  StatusBlocked = 'STATUS_BLOCKED',
  StatusDeleted = 'STATUS_DELETED',
  StatusPending = 'STATUS_PENDING',
  StatusPublished = 'STATUS_PUBLISHED',
  StatusUnspecified = 'STATUS_UNSPECIFIED'
}

export enum ContentProductType {
  ProductTypeCraft = 'PRODUCT_TYPE_CRAFT',
  ProductTypeGraphics = 'PRODUCT_TYPE_GRAPHICS',
  ProductTypeUnspecified = 'PRODUCT_TYPE_UNSPECIFIED'
}

export type ContentReview = {
  __typename?: 'ContentReview';
  body: Scalars['String']['output'];
  createdAt: Scalars['Time']['output'];
  id: Scalars['String']['output'];
  parentId?: Maybe<Scalars['String']['output']>;
  productId: Scalars['String']['output'];
  rating?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['Time']['output'];
  user: PublicUserProfile;
  userId: Scalars['String']['output'];
};

export type ContentReviewRating = {
  __typename?: 'ContentReviewRating';
  average: Scalars['Float']['output'];
  count: Scalars['Int']['output'];
  groups: Array<ContentReviewRatingGroup>;
};

export type ContentReviewRatingGroup = {
  __typename?: 'ContentReviewRatingGroup';
  count: Scalars['Int']['output'];
  rating: Scalars['Int']['output'];
};

export type ContentSeo = {
  __typename?: 'ContentSeo';
  description: Scalars['String']['output'];
  h1: Scalars['String']['output'];
  image_alt: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ContentUpdateFavoriteInput = {
  listIds: Array<Scalars['ID']['input']>;
  productId: Scalars['ID']['input'];
};

export type ContentUpdateFavoriteListInput = {
  id: Scalars['ID']['input'];
  name: Scalars['String']['input'];
};

export type ContentUserFavoritesCountInput = {
  categoryIds?: InputMaybe<Array<Scalars['ID']['input']>>;
  language?: InputMaybe<ContentLanguage>;
};

export type ContentUserListCountPerCategory = {
  __typename?: 'ContentUserListCountPerCategory';
  category: ContentCategory;
  categoryId: Scalars['ID']['output'];
  language: ContentLanguage;
  total: Scalars['Int']['output'];
};

export type ContentUserListFavorites = {
  __typename?: 'ContentUserListFavorites';
  favorites?: Maybe<Array<ContentFavorite>>;
  total: Scalars['Int']['output'];
};

export type ContentUserListFavoritesCount = {
  __typename?: 'ContentUserListFavoritesCount';
  total: Scalars['Int']['output'];
  totalPerCategory?: Maybe<Array<ContentUserListCountPerCategory>>;
};

export type ContentUserListFavoritesInput = {
  categoryId?: InputMaybe<Scalars['ID']['input']>;
  language: ContentLanguage;
  limit?: InputMaybe<Scalars['Int']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type Contents = {
  __typename?: 'Contents';
  destinationUrl: Scalars['String']['output'];
  template: CampaignTemplateType;
  templateFormatImageOnly?: Maybe<TemplateFormatImageOnly>;
  templateFormatImagesAndLogo?: Maybe<TemplateFormatImagesAndLogo>;
  templateFormatImagesAndText?: Maybe<TemplateFormatImagesAndText>;
  templateFormatVideoAndImage?: Maybe<TemplateFormatVideoAndImage>;
  templateFormatVideoOnly?: Maybe<TemplateFormatVideoOnly>;
};

export type Currency = {
  __typename?: 'Currency';
  code?: Maybe<CurrencyCode>;
  symbol: Scalars['String']['output'];
};

export enum CurrencyCode {
  Aud = 'aud',
  Cad = 'cad',
  Cny = 'cny',
  Dkk = 'dkk',
  Eur = 'eur',
  Gbp = 'gbp',
  Jpy = 'jpy',
  Pln = 'pln',
  Usd = 'usd'
}

export enum DateField {
  LastViewTime = 'lastViewTime',
  UpdateTime = 'updateTime'
}

export enum DateRange {
  Last30Days = 'last30Days',
  Last90Days = 'last90Days',
  LastYear = 'lastYear',
  Today = 'today',
  Yesterday = 'yesterday'
}

export type DeactivateAccountResponse = {
  __typename?: 'DeactivateAccountResponse';
  challenge?: Maybe<AuthChallenge>;
  errors?: Maybe<Array<ErrorMessage>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type DesignReview = {
  __typename?: 'DesignReview';
  alignmentAndProximity?: Maybe<ReviewAspect>;
  colorSchemeHarmonious?: Maybe<ReviewAspect>;
  colorSchemeSuitsPurpose?: Maybe<ReviewAspect>;
  compositionAndBalance?: Maybe<ReviewAspect>;
  consistency?: Maybe<ReviewAspect>;
  contrast?: Maybe<ReviewAspect>;
  graphicsAndImages?: Maybe<ReviewAspect>;
  hierarchyAndEmphasis?: Maybe<ReviewAspect>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  overallScore?: Maybe<ReviewAspect>;
  status: DesignReviewStatusType;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  typographyFontChoices?: Maybe<ReviewAspect>;
  typographyLegibility?: Maybe<ReviewAspect>;
  whiteSpace?: Maybe<ReviewAspect>;
};

export enum DesignReviewStatusType {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Failed = 'failed',
  Pending = 'pending',
  Unspecified = 'unspecified'
}

export type DesignReviewerCancelReviewRequestInput = {
  id: Scalars['String']['input'];
};

export type DesignReviewerCancelReviewRequestResponse = {
  __typename?: 'DesignReviewerCancelReviewRequestResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type DesignReviewerDeleteAllReviewResponse = {
  __typename?: 'DesignReviewerDeleteAllReviewResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type DesignReviewerDeleteReviewInput = {
  id: Scalars['ID']['input'];
};

export type DesignReviewerDeleteReviewResponse = {
  __typename?: 'DesignReviewerDeleteReviewResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  id?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type DesignReviewerGetReviewInput = {
  id: Scalars['ID']['input'];
};

export type DesignReviewerGetReviewResponse = {
  __typename?: 'DesignReviewerGetReviewResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  review?: Maybe<DesignReview>;
  success: Scalars['Boolean']['output'];
};

export type DesignReviewerListPagination = {
  __typename?: 'DesignReviewerListPagination';
  currentPage: Scalars['Int']['output'];
  itemsPerPage: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type DesignReviewerListReviewsInput = {
  currentPage: Scalars['Int']['input'];
  itemsPerPage: Scalars['Int']['input'];
};

export type DesignReviewerListReviewsResponse = {
  __typename?: 'DesignReviewerListReviewsResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  pagination?: Maybe<DesignReviewerListPagination>;
  reviews?: Maybe<Array<DesignReview>>;
};

export type DesignReviewerUploadsCreateSignedUrlInput = {
  uploadFile: Scalars['String']['input'];
};

export type DesignReviewerUploadsCreateSignedUrlResponse = {
  __typename?: 'DesignReviewerUploadsCreateSignedUrlResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  id: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

export type EmailAddress = {
  __typename?: 'EmailAddress';
  email: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type EmailAddressInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type EnterpriseRequest = {
  __typename?: 'EnterpriseRequest';
  asset: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  employees: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  job?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  owner?: Maybe<Scalars['Int']['output']>;
  project?: Maybe<Scalars['String']['output']>;
  usages: Array<EnterpriseRequestUsage>;
};

export enum EnterpriseRequestEmployees {
  ElevenToFifty = 'ELEVEN_TO_FIFTY',
  FiftyOneToOneHundred = 'FIFTY_ONE_TO_ONE_HUNDRED',
  MoreThanFiveHundred = 'MORE_THAN_FIVE_HUNDRED',
  OneHundredOneToFiveHundred = 'ONE_HUNDRED_ONE_TO_FIVE_HUNDRED',
  OneToTen = 'ONE_TO_TEN'
}

export type EnterpriseRequestUsage = {
  __typename?: 'EnterpriseRequestUsage';
  answer: Scalars['String']['output'];
  enterpriseReqId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  question: Scalars['String']['output'];
  usage: Scalars['String']['output'];
};

export enum ErrorCode {
  BadRequest = 'badRequest',
  Conflict = 'conflict',
  FailedPrecondition = 'failedPrecondition',
  Forbidden = 'forbidden',
  Internal = 'internal',
  NotFound = 'notFound',
  PermissionDenied = 'permissionDenied',
  Unauthorized = 'unauthorized'
}

export type ErrorMessage = {
  __typename?: 'ErrorMessage';
  code: ErrorCode;
  fields?: Maybe<Array<Maybe<FieldError>>>;
  message: Scalars['String']['output'];
};

export type ExtendSubscriptionResult = {
  __typename?: 'ExtendSubscriptionResult';
  maxExtensionsReached?: Maybe<Scalars['Boolean']['output']>;
  nextPaymentDate?: Maybe<Scalars['DateTime']['output']>;
  payInvoiceUrl?: Maybe<Scalars['String']['output']>;
  paymentStatus: PaymentStatus;
};

export type FieldError = {
  __typename?: 'FieldError';
  errors: Array<Scalars['String']['output']>;
  field: Scalars['String']['output'];
};

export type Flow = {
  __typename?: 'Flow';
  createTime: Scalars['DateTime']['output'];
  deleteTime?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  iterations: Array<FlowIteration>;
  lastIteration: FlowIteration;
  owner: Scalars['Boolean']['output'];
  stats?: Maybe<FlowStats>;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  updateTime?: Maybe<Scalars['DateTime']['output']>;
  user: PublicUserProfile;
  visibility: FlowVisibility;
};

export enum FlowAspectRatio {
  Landscape_4_3 = 'landscape_4_3',
  Landscape_16_9 = 'landscape_16_9',
  Portrait_3_4 = 'portrait_3_4',
  Portrait_9_16 = 'portrait_9_16',
  Square = 'square'
}

export enum FlowEffect {
  Analog = 'analog',
  Glitch = 'glitch',
  LessSaturated = 'lessSaturated',
  MoreSaturated = 'moreSaturated',
  None = 'none',
  Pixelate = 'pixelate',
  Poster = 'poster',
  Sepia = 'sepia',
  Sketch = 'sketch',
  ThreeDGlasses = 'threeDGlasses'
}

export type FlowFeed = {
  __typename?: 'FlowFeed';
  flows: Array<Flow>;
};

export type FlowFlowsTippedInput = {
  id: Scalars['String']['input'];
};

export type FlowImage = {
  __typename?: 'FlowImage';
  createTime: Scalars['DateTime']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  published: Scalars['Boolean']['output'];
  state: FlowImageState;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
};

export type FlowImageGetReactions = {
  __typename?: 'FlowImageGetReactions';
  counter?: Maybe<FlowImageReactionsCounter>;
  imageId: Scalars['ID']['output'];
  userReaction?: Maybe<ImageReaction>;
};

export type FlowImageGetReactionsInput = {
  imageIds: Array<Scalars['ID']['input']>;
};

export type FlowImageGetReactionsResponse = {
  __typename?: 'FlowImageGetReactionsResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  reactions?: Maybe<Array<FlowImageGetReactions>>;
  success: Scalars['Boolean']['output'];
};

export type FlowImageReactInput = {
  imageId: Scalars['ID']['input'];
  reaction: ImageReaction;
};

export type FlowImageReactResponse = {
  __typename?: 'FlowImageReactResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type FlowImageReactionsCounter = {
  __typename?: 'FlowImageReactionsCounter';
  Like: Scalars['Int']['output'];
};

export type FlowImageRemoveReactInput = {
  imageId: Scalars['ID']['input'];
};

export type FlowImageRemoveReactResponse = {
  __typename?: 'FlowImageRemoveReactResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export enum FlowImageState {
  Failed = 'failed',
  Generated = 'generated'
}

export type FlowIteration = {
  __typename?: 'FlowIteration';
  aspectRatio?: Maybe<FlowAspectRatio>;
  createTime: Scalars['DateTime']['output'];
  /** @deprecated No longer supported */
  effect?: Maybe<FlowEffect>;
  id: Scalars['ID']['output'];
  imageId?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  pages: Array<FlowIterationPage>;
  pagesTotal: Scalars['Int']['output'];
  prompt?: Maybe<Scalars['String']['output']>;
  /** @deprecated No longer supported */
  quality?: Maybe<FlowQuality>;
  rating?: Maybe<IterationRating>;
  results: Array<FlowImage>;
  style: FlowStyle;
  type: FlowType;
};

export type FlowIterationPage = {
  __typename?: 'FlowIterationPage';
  pageID: Scalars['String']['output'];
  results: Array<FlowImage>;
};

export type FlowIterationRateInput = {
  iterationId: Scalars['String']['input'];
  rating: IterationRating;
};

export type FlowIterationRateResponse = {
  __typename?: 'FlowIterationRateResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type FlowIterationSettings = {
  __typename?: 'FlowIterationSettings';
  aspectRatio?: Maybe<FlowAspectRatio>;
  imageId?: Maybe<Scalars['String']['output']>;
  imageURL?: Maybe<Scalars['String']['output']>;
  prompt: Scalars['String']['output'];
  style: FlowStyle;
  type: FlowType;
  visibility?: Maybe<FlowVisibility>;
};

export type FlowIterationsInput = {
  after?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};

export type FlowPublishImageInput = {
  id: Scalars['String']['input'];
};

export type FlowPublishImageResponse = {
  __typename?: 'FlowPublishImageResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  upload?: Maybe<StudioUploadsFile>;
};

export enum FlowQuality {
  High = 'high',
  Low = 'low'
}

export type FlowQueue = {
  __typename?: 'FlowQueue';
  flowID: Scalars['ID']['output'];
  position: Scalars['Int']['output'];
};

export type FlowSettingsInput = {
  aspectRatio?: InputMaybe<FlowAspectRatio>;
  effect?: InputMaybe<FlowEffect>;
  imageId?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  prompt?: InputMaybe<Scalars['String']['input']>;
  quality?: InputMaybe<FlowQuality>;
  style: FlowStyle;
  type: FlowType;
  visibility?: InputMaybe<FlowVisibility>;
};

export type FlowStats = {
  __typename?: 'FlowStats';
  downloads: Scalars['Int']['output'];
  hearts: Scalars['Int']['output'];
  tips: Scalars['Int']['output'];
  views: Scalars['Int']['output'];
};

export type FlowStatus = {
  __typename?: 'FlowStatus';
  active: Scalars['Boolean']['output'];
  flowId: Scalars['ID']['output'];
};

export enum FlowStyle {
  CfVanillaClipart512x001 = 'CFVanillaClipart512x001',
  Deliberate = 'deliberate',
  Openjourney = 'openjourney',
  Photoreal = 'photoreal',
  Protogen = 'protogen',
  Redshift = 'redshift',
  Synthwave = 'synthwave',
  V2Cartoon = 'v2_cartoon',
  V2Cyberpunk = 'v2_cyberpunk',
  V2InkBlot = 'v2_inkBlot',
  V2Kawaii = 'v2_kawaii',
  V2LowPoly = 'v2_lowPoly',
  V2None = 'v2_none',
  V2Photoreal = 'v2_photoreal',
  V2Style3d = 'v2_style3d',
  V2Watercolor = 'v2_watercolor',
  Vectorartz = 'vectorartz'
}

export enum FlowType {
  Animation = 'animation',
  Full = 'full',
  Pattern = 'pattern',
  Transparent = 'transparent',
  V2Full = 'v2_full',
  V2Pattern = 'v2_pattern',
  V2Transparent = 'v2_transparent'
}

export type FlowViewersResponse = {
  __typename?: 'FlowViewersResponse';
  activeUsers: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  totalViewers: Scalars['Int']['output'];
};

export enum FlowVisibility {
  Private = 'private',
  Public = 'public'
}

export type FlowsFlowTippedResponse = {
  __typename?: 'FlowsFlowTippedResponse';
  tipped: Scalars['Boolean']['output'];
};

export type Font = {
  __typename?: 'Font';
  createdAt: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type: FontType;
  updatedAt?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export enum FontType {
  Otf = 'otf',
  Ttf = 'ttf',
  Undefined = 'undefined',
  Woff = 'woff',
  Woff2 = 'woff2'
}

export type FontsCreateSignedUrlInput = {
  checksum?: InputMaybe<Scalars['String']['input']>;
  fileName: Scalars['String']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type FontsCreateSignedUrlResponse = {
  __typename?: 'FontsCreateSignedUrlResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  id: Scalars['ID']['output'];
  signedUrl: Scalars['String']['output'];
};

export type FontsDeleteInput = {
  fontIds: Array<Scalars['ID']['input']>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type FontsDeleteResponse = {
  __typename?: 'FontsDeleteResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type FontsSearchInput = {
  search?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
  type?: InputMaybe<FontType>;
};

export type FontsSearchResponse = {
  __typename?: 'FontsSearchResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  fonts?: Maybe<Array<Font>>;
};

export type FontsUploadUpdatedInput = {
  id: Scalars['ID']['input'];
};

export type FontsUploadUpdatedResponse = {
  __typename?: 'FontsUploadUpdatedResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  font?: Maybe<Font>;
};

export type HealthCheckResponse = {
  __typename?: 'HealthCheckResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type HealthZResponse = {
  __typename?: 'HealthZResponse';
  timestamp: Scalars['String']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export enum HelpChannel {
  Studio = 'studio'
}

export type HelpConversation = {
  __typename?: 'HelpConversation';
  id: Scalars['ID']['output'];
  messages: Array<HelpMessage>;
};

export type HelpMessage = {
  __typename?: 'HelpMessage';
  content: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  rate: HelpMessageRate;
  sender: HelpMessageSender;
  type: HelpMessageType;
};

export enum HelpMessageRate {
  Negative = 'negative',
  Positive = 'positive',
  Unspecified = 'unspecified'
}

export enum HelpMessageSender {
  Bot = 'bot',
  User = 'user'
}

export enum HelpMessageType {
  Audio = 'audio',
  Error = 'error',
  File = 'file',
  ForbiddenTopic = 'forbiddenTopic',
  Image = 'image',
  NegativeFeedback = 'negativeFeedback',
  NegativeSentiment = 'negativeSentiment',
  PositiveFeedback = 'positiveFeedback',
  ProposedReply = 'proposedReply',
  Text = 'text',
  Update = 'update',
  Video = 'video'
}

export type Image = {
  __typename?: 'Image';
  height?: Maybe<Scalars['Int']['output']>;
  url: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
};

export enum ImageReaction {
  Like = 'Like'
}

export type IncreaseDownloadCountInput = {
  productId: Scalars['ID']['input'];
};

export type IncreaseDownloadCountResponse = {
  __typename?: 'IncreaseDownloadCountResponse';
  errors: Array<ErrorMessage>;
  success: Scalars['Boolean']['output'];
};

export type Intent = {
  __typename?: 'Intent';
  clientSecret: Scalars['String']['output'];
  stripeCustomerId: Scalars['String']['output'];
  stripeSubscriptionId: Scalars['String']['output'];
  type: IntentType;
};

export enum IntentType {
  Payment = 'payment',
  Setup = 'setup'
}

export enum InvitationStatus {
  Accepted = 'Accepted',
  AlreadyInvited = 'AlreadyInvited',
  AwaitingAdminApproval = 'AwaitingAdminApproval',
  Invited = 'Invited',
  Unknown = 'Unknown'
}

export type InvitedTeamMember = {
  __typename?: 'InvitedTeamMember';
  email: Scalars['String']['output'];
  invitationId: Scalars['String']['output'];
  role: Role;
};

export type IsUploadAllowedRequest = {
  size: Scalars['Int']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type IsUploadAllowedResponse = {
  __typename?: 'IsUploadAllowedResponse';
  allowed: Scalars['Boolean']['output'];
  errors?: Maybe<Array<ErrorMessage>>;
};

export enum IterationRating {
  Disliked = 'Disliked',
  Liked = 'Liked',
  Unspecified = 'Unspecified'
}

export enum Language {
  De = 'de',
  En = 'en',
  Es = 'es',
  Fr = 'fr',
  It = 'it',
  Nl = 'nl',
  Pl = 'pl',
  Pt = 'pt'
}

export type ListTransfersInput = {
  itemsPerPage: Scalars['Int']['input'];
  page: Scalars['Int']['input'];
  sort: TransferSort;
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type ListTransfersResponse = {
  __typename?: 'ListTransfersResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  pagination: ProjectListPagination;
  transfers?: Maybe<Array<Transfer>>;
};

export type LoginInput = {
  deviceId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  otp?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  remember?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  challenge?: Maybe<AuthChallenge>;
  errors?: Maybe<Array<ErrorMessage>>;
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type LogoutResponse = {
  __typename?: 'LogoutResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  logout?: Maybe<StatusMessage>;
};

export type LostPasswordInput = {
  email: Scalars['String']['input'];
};

export type LostPasswordResponse = {
  __typename?: 'LostPasswordResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type MonthlyAction = {
  __typename?: 'MonthlyAction';
  actionDate?: Maybe<Scalars['String']['output']>;
  campaign_id: Scalars['String']['output'];
  clickCount?: Maybe<Scalars['Int']['output']>;
  viewCount?: Maybe<Scalars['Int']['output']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  advertisingCreateCampaign: AdvertisingCreateCampaignResponse;
  advertisingDeleteCampaign: AdvertisingDeleteCampaignResponse;
  advertisingIncreaseClickForCampaign: AdvertisingIncreaseClickForCampaignResponse;
  advertisingIncreaseViewForCampaign: AdvertisingIncreaseViewForCampaignResponse;
  advertisingUpdateCampaign: AdvertisingUpdateCampaignResponse;
  advertisingUploadsCreateSignedUrl: AdvertisingUploadsCreateSignedUrlResponse;
  askCFConfirmQuestion: AskCfConfirmQuestionResponse;
  askCFConfirmTopic: AskCfConfirmTopicResponse;
  askCFDeleteAnswer: AskCfDeleteAnswerResponse;
  askCFDeleteAnswersFromUser: AskCfDeleteAnswersFromUserResponse;
  askCFDeleteQuestion: AskCfDeleteQuestionResponse;
  askCFDeleteQuestionsFromUser: AskCfDeleteQuestionsFromUserResponse;
  askCFDeleteTopic: AskCfTopicDeleteResponse;
  askCFDiscardQuestion: AskCfDiscardQuestionResponse;
  askCFPostAnswer: AskCfPostAnswerResponse;
  askCFPostQuestion: AskCfPostQuestionResponse;
  askCFSetBestAnswer: AskCfSetBestAnswerResponse;
  askCFUploadsCreateSignedUrl: AskCfUploadCreateSignedUrlResponse;
  cfmainCreateEnterpriseRequest: CfMainEnterpriseRequestResponse;
  cfmainUpdateEnterpriseRequest: CfMainUpdateEnterpriseRequestResponse;
  contentCreateFavoriteList: Scalars['String']['output'];
  contentCreateReview: Scalars['Boolean']['output'];
  contentDeleteFavoriteList: Scalars['Boolean']['output'];
  contentDeleteReview: Scalars['Boolean']['output'];
  contentEditReview: Scalars['Boolean']['output'];
  contentFavoriteProduct: Scalars['Boolean']['output'];
  contentIncreaseDownloadCount: Scalars['Boolean']['output'];
  contentUnfavoriteProduct: Scalars['Boolean']['output'];
  contentUpdateFavorite: Scalars['Boolean']['output'];
  contentUpdateFavoriteList: Scalars['Boolean']['output'];
  /** @deprecated Use flowCreateDownloadUrl */
  createDownloadUrl: Scalars['String']['output'];
  /** @deprecated Use flowCreate */
  createFlow: Flow;
  /** @deprecated Use flowCreateIterationPage */
  createFlowIterationPage: FlowIterationPage;
  /** @deprecated Use flowCreateUploadUrl */
  createUploadUrl: Scalars['String']['output'];
  deactivateAccount?: Maybe<DeactivateAccountResponse>;
  /** @deprecated Use flowDeleteAll */
  deleteAllFlows: Scalars['Boolean']['output'];
  /** @deprecated Use flowDelete */
  deleteFlow: Scalars['Boolean']['output'];
  designReviewerCancelReview: DesignReviewerCancelReviewRequestResponse;
  designReviewerDeleteAllReview: DesignReviewerDeleteAllReviewResponse;
  designReviewerDeleteReview: DesignReviewerDeleteReviewResponse;
  designReviewerUploadsCreateSignedUrl: DesignReviewerUploadsCreateSignedUrlResponse;
  flowCreate: Flow;
  flowCreateDownloadUrl: Scalars['String']['output'];
  flowCreateIterationPage: FlowIterationPage;
  flowCreateUploadUrl: Scalars['String']['output'];
  flowDelete: Scalars['Boolean']['output'];
  flowDeleteAll: Scalars['Boolean']['output'];
  flowImageReact: FlowImageReactResponse;
  flowImageRemoveReact: FlowImageRemoveReactResponse;
  flowIterationRate: FlowIterationRateResponse;
  flowLike: Scalars['Boolean']['output'];
  flowPublishImage: FlowPublishImageResponse;
  flowUpdate: Flow;
  flowUpdateVisibility: Flow;
  flowView: Scalars['Int']['output'];
  /** @deprecated Use userFollow */
  followUser: Scalars['Boolean']['output'];
  fontsCreateSignedUrl: FontsCreateSignedUrlResponse;
  fontsDelete: FontsDeleteResponse;
  giveTip: Scalars['Boolean']['output'];
  helpRateMessage?: Maybe<HelpMessage>;
  helpSendMessage?: Maybe<HelpConversation>;
  /** @deprecated Use flowLike */
  likeFlow: Scalars['Boolean']['output'];
  login?: Maybe<LoginResponse>;
  loginWithSocial?: Maybe<AuthResponse>;
  loginWithSocialRedirect?: Maybe<SocialRedirectLoginResponse>;
  logout?: Maybe<LogoutResponse>;
  lostPassword?: Maybe<LostPasswordResponse>;
  paymentsBuildPaymentForSubscription: Payment;
  paymentsCreateIntentForSubscription: Intent;
  paymentsExtendSubscription: ExtendSubscriptionResult;
  paymentsUpgradeSubscription: UpgradeSubscriptionResult;
  publishImage: Scalars['Boolean']['output'];
  refreshToken?: Maybe<RefreshTokenResponse>;
  register?: Maybe<RegisterResponse>;
  resetPassword?: Maybe<ResetPasswordResponse>;
  snippetsCreateSnippet: SnippetsCreateSnippetResponse;
  snippetsDeleteSnippet: SnippetsDeleteSnippetResponse;
  snippetsUpdateSnippet: SnippetsUpdateSnippetResponse;
  sparkArtDelete: SparkArtDeleteResponse;
  sparkArtGenerate: SparkArtGenerateResponse;
  sparkCrystallineDelete: SparkCrystallineDeleteResponse;
  sparkCrystallineGenerate: SparkCrystallineGenerateResponse;
  sparkImageMixDelete: SparkImageMixDeleteResponse;
  sparkImageMixDeleteAll: SparkImageMixDeleteAllResponse;
  sparkImageMixGenerate: SparkImageMixGenerateResponse;
  sparkInpaintDelete: SparkInpaintDeleteResponse;
  sparkInpaintDeleteAll: SparkInpaintDeleteAllResponse;
  sparkInpaintImage: SparkInpaintImageResponse;
  sparkOutpaintDelete: SparkOutpaintDeleteResponse;
  sparkOutpaintDeleteAll: SparkOutpaintDeleteAllResponse;
  sparkOutpaintImage: SparkOutpaintImageResponse;
  sparkRegisterAnonymousArtToUser: SparkRegisterArtResponse;
  sparkRegisterAnonymousBackgroundRemovedToUser: SparkRegisterBackgroundRemovedResponse;
  sparkRegisterAnonymousCrystallineToUser: SparkRegisterCrystallineResponse;
  sparkRegisterAnonymousImageMixToUser: SparkRegisterImageMixResponse;
  sparkRegisterAnonymousInpaintToUser: SparkRegisterInpaintResponse;
  sparkRegisterAnonymousOutpaintToUser: SparkRegisterOutpaintResponse;
  sparkRemoveBackground: SparkRemoveBackgroundResponse;
  sparkRemovedBackgroundCancel: SparkRemovedBackgroundCancelResponse;
  sparkRemovedBackgroundDelete: SparkRemovedBackgroundDeleteResponse;
  sparkRemovedBackgroundDeleteAll: SparkRemovedBackgroundDeleteAllResponse;
  sparkRemovedBackgroundPublishImage: RemoveBackgroundPublishImageResponse;
  sparkRemovedBackgroundUploadsCreateSignedUrl: SparkRemovedBackgroundUploadsCreateSignedUrlResponse;
  sparkUploadsCreateSignedUrl: SparkUploadsCreateSignedUrlResponse;
  statisticsRecordStudioEvent: StatisticsRecordStudioEventResponse;
  storageEnableOverages: StorageEnableOveragesResponse;
  storageUpdateSize: StorageUpdateSizeResponse;
  studioProjectsAddMember: StudioProjectsAddMemberResponse;
  studioProjectsAddUserToProjectSharingLink: StudioProjectsAddUserToProjectSharingLinkResponse;
  studioProjectsApproveInvite: StudioProjectsApproveInviteResponse;
  studioProjectsApproveProjectMemberUpdate: StudioProjectsApproveProjectMemberUpdateResponse;
  studioProjectsChangeUserRole: StudioProjectsChangeUserRoleResponse;
  studioProjectsConfirmInvite: StudioProjectsConfirmInviteResponse;
  studioProjectsCreateFolder: StudioProjectCreateFolderResponse;
  studioProjectsCreateFromTemplate: StudioProjectsCreateFromTemplateResponse;
  studioProjectsCreateProject: StudioProjectsCreateProjectResponse;
  studioProjectsCreateProjectComment: StudioProjectsCreateProjectCommentResponse;
  studioProjectsCreateProjectSharingLink: StudioProjectsCreateProjectSharingLinkResponse;
  studioProjectsDeleteProject: StudioProjectDeleteProjectResponse;
  studioProjectsEmptyTrash: StudioProjectEmptyTrashResponse;
  studioProjectsFolderDelete: StudioProjectFolderDeleteResponse;
  studioProjectsFolderRename: StudioProjectsFolderRenameResponse;
  studioProjectsFolderRestore: StudioProjectsFolderRestoreResponse;
  studioProjectsMarkProjectCommentDone: StudioProjectsMarkProjectCommentDoneResponse;
  studioProjectsMoveProjects: StudioProjectMoveProjectsResponse;
  studioProjectsOptimizeProject: StudioProjectOptimizeProjectResponse;
  studioProjectsRemoveUser: StudioProjectsRemoveUserResponse;
  studioProjectsRestoreProject: StudioProjectRestoreProjectResponse;
  studioProjectsUpdateProject: StudioProjectsUpdateProjectResponse;
  studioProjectsUpdateProjectLastView: StudioProjectsUpdateProjectLastViewResponse;
  studioTemplateDelete: StudioTemplateDeleteResponse;
  studioTemplatePublishFromProject: StudioTemplatePublishFromProjectResponse;
  studioTemplateRestore: StudioTemplateRestoreResponse;
  studioUploadsAddTagToFiles: StudioUploadsAddTagToFilesResponse;
  studioUploadsCreateFolder: StudioUploadsCreateFolderResponse;
  studioUploadsCreateSignedDownloadUrl: StudioUploadsCreateSignedDownloadUrlResponse;
  studioUploadsCreateSignedUrl: StudioUploadsCreateSignedUrlResponse;
  studioUploadsCreateTag: StudioUploadsCreateTagResponse;
  studioUploadsDeleteFiles: StudioUploadsDeleteFilesResponse;
  studioUploadsDeleteFolder: StudioUploadsDeleteFolderResponse;
  studioUploadsDeleteTag: StudioUploadsDeleteTagResponse;
  studioUploadsMoveFiles: StudioUploadsMoveFilesResponse;
  studioUploadsRemoveTagFromFile: StudioUploadsRemoveTagFromFileResponse;
  studioUploadsRenameFolder: StudioUploadsRenameFolderResponse;
  studioUploadsUpdateTag: StudioUploadsUpdateTagResponse;
  teamsApproveAdminRequest: TeamsApproveAdminRequestResponse;
  teamsApproveNewTeamMember: TeamsApproveNewTeamMemberResponse;
  teamsApproveTeamMemberRoleChange: TeamsApproveTeamMemberRoleChangeResponse;
  teamsCancelInvite: TeamsCancelInviteResponse;
  teamsChangeUserRole: TeamsChangeUserRoleResponse;
  teamsConfirmInvite: TeamsConfirmInviteResponse;
  teamsCreateTeam: TeamsCreateTeamResponse;
  teamsDeactivateTeam: TeamsDeactivateTeamResponse;
  teamsGetCheckoutURL: TeamsGetCheckoutUrlResponse;
  teamsInviteUsers: TeamsInviteUsersResponse;
  teamsLeaveTeam: TeamsLeaveTeamResponse;
  teamsRemoveUsers: TeamsRemoveUsersResponse;
  teamsResendInvite: TeamsResendInviteResponse;
  teamsUpdateTeam: TeamsUpdateTeamResponse;
  transfersAddRecipients: TransfersAddRecipientsResponse;
  transfersCancel: TransfersCancelResponse;
  transfersConfirmOwnerEmail: TransfersConfirmOwnerEmailResponse;
  transfersCreateSignedDownloadUrl: TransfersCreateSignedDownloadUrlResponse;
  transfersDelete: TransfersDeleteResponse;
  transfersInitiateTransfer: TransfersInitiateTransferResponse;
  transfersInitiateTransferV2: TransfersInitiateTransferV2Response;
  transfersNotifyTransferDownloadCompleted: NotifyTransferDownloadCompletedResponse;
  transfersVerifyOwnerEmail: TransfersVerifyOwnerEmailResponse;
  /** @deprecated Use userUnfollow */
  unfollowUser: Scalars['Boolean']['output'];
  /** @deprecated Use flowUpdate */
  updateFlow: Flow;
  /** @deprecated Use flowUpdateVisibility */
  updateFlowVisibility: Flow;
  updateUserGDPR: UpdateUserResponse;
  uploadsCreateSignedUrlV2: UploadsCreateSignedUrlResponseV2;
  userFollow: Scalars['Boolean']['output'];
  userUnfollow: Scalars['Boolean']['output'];
  usersUpdateCurrency: UpdateUserResponse;
  usersUpdateFlags: UpdateUserResponse;
  usersUpdateLanguage: UpdateUserResponse;
  vectorizerCancelVectorization: VectorizerCancelImageVectorizationResponse;
  vectorizerCreateImage: VectorizerCreateImageResponse;
  vectorizerCreateSignedUploadUrl: VectorizerCreateSignedUploadUrlResponse;
  vectorizerDeleteAllImages: VectorizerDeleteAllImagesResponse;
  vectorizerDeleteImage: VectorizerDeleteImageResponse;
  vectorizerPublishImage: VectorizerPublishImageResponse;
  vectorizerRegisterImagesToUser: VectorizerImagesRegisterToUserResponse;
  /** @deprecated Use flowView */
  viewFlow: Scalars['Int']['output'];
};


export type MutationAdvertisingCreateCampaignArgs = {
  input: AdvertisingCreateCampaignInput;
};


export type MutationAdvertisingDeleteCampaignArgs = {
  input: AdvertisingDeleteCampaignInput;
};


export type MutationAdvertisingIncreaseClickForCampaignArgs = {
  input: AdvertisingIncreaseClickForCampaignInput;
};


export type MutationAdvertisingIncreaseViewForCampaignArgs = {
  input: AdvertisingIncreaseViewForCampaignInput;
};


export type MutationAdvertisingUpdateCampaignArgs = {
  input: AdvertisingUpdateCampaignInput;
};


export type MutationAdvertisingUploadsCreateSignedUrlArgs = {
  input: AdvertisingUploadsCreateSignedUrlInput;
};


export type MutationAskCfConfirmQuestionArgs = {
  input: AskCfConfirmQuestionInput;
};


export type MutationAskCfConfirmTopicArgs = {
  input: AskCfConfirmTopicInput;
};


export type MutationAskCfDeleteAnswerArgs = {
  input: AskCfDeleteAnswerInput;
};


export type MutationAskCfDeleteAnswersFromUserArgs = {
  input: AskCfDeleteAnswersFromUserInput;
};


export type MutationAskCfDeleteQuestionArgs = {
  input: AskCfDeleteQuestionInput;
};


export type MutationAskCfDeleteQuestionsFromUserArgs = {
  input: AskCfDeleteQuestionsFromUserInput;
};


export type MutationAskCfDeleteTopicArgs = {
  input: AskCfTopicDeleteInput;
};


export type MutationAskCfDiscardQuestionArgs = {
  input: AskCfDiscardQuestionInput;
};


export type MutationAskCfPostAnswerArgs = {
  input: AskCfPostAnswerInput;
};


export type MutationAskCfPostQuestionArgs = {
  input: AskCfPostQuestionInput;
};


export type MutationAskCfSetBestAnswerArgs = {
  input: AskCfSetBestAnswerInput;
};


export type MutationAskCfUploadsCreateSignedUrlArgs = {
  input: AskCfUploadCreateSignedUrlInput;
};


export type MutationCfmainCreateEnterpriseRequestArgs = {
  input: CfMainEnterpriseRequestInput;
};


export type MutationCfmainUpdateEnterpriseRequestArgs = {
  input: CfMainUpdateEnterpriseRequestInput;
};


export type MutationContentCreateFavoriteListArgs = {
  input: ContentCreateFavoriteListInput;
};


export type MutationContentCreateReviewArgs = {
  input: ContentCreateReview;
};


export type MutationContentDeleteFavoriteListArgs = {
  id: Scalars['ID']['input'];
};


export type MutationContentDeleteReviewArgs = {
  id: Scalars['ID']['input'];
};


export type MutationContentEditReviewArgs = {
  input: ContentEditReview;
};


export type MutationContentFavoriteProductArgs = {
  productId: Scalars['ID']['input'];
};


export type MutationContentIncreaseDownloadCountArgs = {
  productId: Scalars['ID']['input'];
  source?: InputMaybe<ContentDownloadSource>;
};


export type MutationContentUnfavoriteProductArgs = {
  productId: Scalars['ID']['input'];
};


export type MutationContentUpdateFavoriteArgs = {
  input: ContentUpdateFavoriteInput;
};


export type MutationContentUpdateFavoriteListArgs = {
  input: ContentUpdateFavoriteListInput;
};


export type MutationCreateDownloadUrlArgs = {
  imageId: Scalars['ID']['input'];
};


export type MutationCreateFlowArgs = {
  input: FlowSettingsInput;
};


export type MutationCreateFlowIterationPageArgs = {
  iterationId: Scalars['ID']['input'];
};


export type MutationCreateUploadUrlArgs = {
  fileName: Scalars['String']['input'];
};


export type MutationDeactivateAccountArgs = {
  otp?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteFlowArgs = {
  id: Scalars['String']['input'];
};


export type MutationDesignReviewerCancelReviewArgs = {
  input: DesignReviewerCancelReviewRequestInput;
};


export type MutationDesignReviewerDeleteReviewArgs = {
  input: DesignReviewerDeleteReviewInput;
};


export type MutationDesignReviewerUploadsCreateSignedUrlArgs = {
  input: DesignReviewerUploadsCreateSignedUrlInput;
};


export type MutationFlowCreateArgs = {
  input: FlowSettingsInput;
};


export type MutationFlowCreateDownloadUrlArgs = {
  imageId: Scalars['ID']['input'];
};


export type MutationFlowCreateIterationPageArgs = {
  iterationId: Scalars['ID']['input'];
};


export type MutationFlowCreateUploadUrlArgs = {
  fileName: Scalars['String']['input'];
};


export type MutationFlowDeleteArgs = {
  id: Scalars['String']['input'];
};


export type MutationFlowImageReactArgs = {
  input: FlowImageReactInput;
};


export type MutationFlowImageRemoveReactArgs = {
  input: FlowImageRemoveReactInput;
};


export type MutationFlowIterationRateArgs = {
  input: FlowIterationRateInput;
};


export type MutationFlowLikeArgs = {
  flowID: Scalars['ID']['input'];
};


export type MutationFlowPublishImageArgs = {
  input: FlowPublishImageInput;
};


export type MutationFlowUpdateArgs = {
  id: Scalars['ID']['input'];
  input: FlowSettingsInput;
};


export type MutationFlowUpdateVisibilityArgs = {
  id: Scalars['ID']['input'];
  visibility: FlowVisibility;
};


export type MutationFlowViewArgs = {
  flowID: Scalars['ID']['input'];
};


export type MutationFollowUserArgs = {
  userID: Scalars['ID']['input'];
};


export type MutationFontsCreateSignedUrlArgs = {
  input: FontsCreateSignedUrlInput;
};


export type MutationFontsDeleteArgs = {
  input: FontsDeleteInput;
};


export type MutationGiveTipArgs = {
  flowID: Scalars['ID']['input'];
};


export type MutationHelpRateMessageArgs = {
  messageId: Scalars['ID']['input'];
  rate: HelpMessageRate;
};


export type MutationHelpSendMessageArgs = {
  channel: HelpChannel;
  conversationId?: InputMaybe<Scalars['ID']['input']>;
  language?: InputMaybe<Language>;
  message: Scalars['String']['input'];
};


export type MutationLikeFlowArgs = {
  flowID: Scalars['ID']['input'];
};


export type MutationLoginArgs = {
  input?: InputMaybe<LoginInput>;
};


export type MutationLoginWithSocialArgs = {
  input?: InputMaybe<SocialLoginInput>;
};


export type MutationLoginWithSocialRedirectArgs = {
  input?: InputMaybe<SocialRedirectLoginInput>;
};


export type MutationLostPasswordArgs = {
  input?: InputMaybe<LostPasswordInput>;
};


export type MutationPaymentsBuildPaymentForSubscriptionArgs = {
  subscriptionType: SubscriptionType;
};


export type MutationPaymentsCreateIntentForSubscriptionArgs = {
  currencyCode: AvailableCurrencyCode;
  promo?: InputMaybe<Scalars['String']['input']>;
  subscriptionType: SubscriptionType;
  user: UserInput;
};


export type MutationPaymentsExtendSubscriptionArgs = {
  stripeSubscriptionId: Scalars['String']['input'];
};


export type MutationPaymentsUpgradeSubscriptionArgs = {
  stripeSubscriptionId: Scalars['String']['input'];
  upgradeTo?: InputMaybe<SubscriptionTypeUpgrade>;
};


export type MutationPublishImageArgs = {
  imageId: Scalars['ID']['input'];
};


export type MutationRegisterArgs = {
  input?: InputMaybe<RegisterInput>;
};


export type MutationResetPasswordArgs = {
  input?: InputMaybe<ResetPasswordInput>;
};


export type MutationSnippetsCreateSnippetArgs = {
  input: SnippetsCreateSnippetInput;
};


export type MutationSnippetsDeleteSnippetArgs = {
  input: SnippetsDeleteSnippetInput;
};


export type MutationSnippetsUpdateSnippetArgs = {
  input: SnippetsUpdateSnippetInput;
};


export type MutationSparkArtDeleteArgs = {
  input: SparkArtDeleteInput;
};


export type MutationSparkArtGenerateArgs = {
  input: SparkArtGenerateInput;
};


export type MutationSparkCrystallineDeleteArgs = {
  input: SparkCrystallineDeleteInput;
};


export type MutationSparkCrystallineGenerateArgs = {
  input: SparkCrystallineGenerateInput;
};


export type MutationSparkImageMixDeleteArgs = {
  input: SparkImageMixDeleteInput;
};


export type MutationSparkImageMixGenerateArgs = {
  input: SparkImageMixGenerateInput;
};


export type MutationSparkInpaintDeleteArgs = {
  input: SparkInpaintDeleteInput;
};


export type MutationSparkInpaintImageArgs = {
  input: SparkInpaintImageInput;
};


export type MutationSparkOutpaintDeleteArgs = {
  input: SparkOutpaintDeleteInput;
};


export type MutationSparkOutpaintImageArgs = {
  input: SparkOutpaintImageInput;
};


export type MutationSparkRegisterAnonymousArtToUserArgs = {
  input: SparkArtRegisterInput;
};


export type MutationSparkRegisterAnonymousBackgroundRemovedToUserArgs = {
  input: SparkBackgroundRemovedRegisterInput;
};


export type MutationSparkRegisterAnonymousCrystallineToUserArgs = {
  input: SparkCrystallineRegisterInput;
};


export type MutationSparkRegisterAnonymousImageMixToUserArgs = {
  input: SparkImageMixRegisterInput;
};


export type MutationSparkRegisterAnonymousInpaintToUserArgs = {
  input: SparkInpaintRegisterInput;
};


export type MutationSparkRegisterAnonymousOutpaintToUserArgs = {
  input: SparkOutpaintRegisterInput;
};


export type MutationSparkRemoveBackgroundArgs = {
  input: SparkRemoveBackgroundInput;
};


export type MutationSparkRemovedBackgroundCancelArgs = {
  input: SparkRemovedBackgroundCancelInput;
};


export type MutationSparkRemovedBackgroundDeleteArgs = {
  input: SparkRemovedBackgroundDeleteInput;
};


export type MutationSparkRemovedBackgroundPublishImageArgs = {
  input: RemoveBackgroundPublishImageInput;
};


export type MutationSparkRemovedBackgroundUploadsCreateSignedUrlArgs = {
  input: SparkRemovedBackgroundUploadsCreateSignedUrlInput;
};


export type MutationSparkUploadsCreateSignedUrlArgs = {
  input: SparkUploadsCreateSignedUrlInput;
};


export type MutationStatisticsRecordStudioEventArgs = {
  input: StatisticsRecordStudioEventInput;
};


export type MutationStorageEnableOveragesArgs = {
  input: StorageEnableOveragesInput;
};


export type MutationStorageUpdateSizeArgs = {
  input: StorageUpdateSizeInput;
};


export type MutationStudioProjectsAddMemberArgs = {
  input: StudioProjectsAddMemberInput;
};


export type MutationStudioProjectsAddUserToProjectSharingLinkArgs = {
  input: StudioProjectsAddUserToProjectSharingLinkInput;
};


export type MutationStudioProjectsApproveInviteArgs = {
  input: StudioProjectsApproveInviteInput;
};


export type MutationStudioProjectsApproveProjectMemberUpdateArgs = {
  input: StudioProjectsApproveProjectMemberUpdateInput;
};


export type MutationStudioProjectsChangeUserRoleArgs = {
  input: StudioProjectsChangeUserRoleInput;
};


export type MutationStudioProjectsConfirmInviteArgs = {
  input: StudioProjectsConfirmInviteInput;
};


export type MutationStudioProjectsCreateFolderArgs = {
  input: StudioProjectCreateFolderInput;
};


export type MutationStudioProjectsCreateFromTemplateArgs = {
  input: StudioProjectsCreateFromTemplateInput;
};


export type MutationStudioProjectsCreateProjectArgs = {
  input: StudioProjectsCreateProjectInput;
};


export type MutationStudioProjectsCreateProjectCommentArgs = {
  input: StudioProjectsCreateProjectCommentInput;
};


export type MutationStudioProjectsCreateProjectSharingLinkArgs = {
  input: StudioProjectsCreateProjectSharingLinkInput;
};


export type MutationStudioProjectsDeleteProjectArgs = {
  input: StudioProjectDeleteProjectInput;
};


export type MutationStudioProjectsEmptyTrashArgs = {
  input: StudioProjectEmptyTrashInput;
};


export type MutationStudioProjectsFolderDeleteArgs = {
  input: StudioProjectFolderDeleteInput;
};


export type MutationStudioProjectsFolderRenameArgs = {
  input: StudioProjectsFolderRenameInput;
};


export type MutationStudioProjectsFolderRestoreArgs = {
  input: StudioProjectsFolderRestoreInput;
};


export type MutationStudioProjectsMarkProjectCommentDoneArgs = {
  input: StudioProjectsMarkProjectCommentDoneInput;
};


export type MutationStudioProjectsMoveProjectsArgs = {
  input: StudioProjectMoveProjectsInput;
};


export type MutationStudioProjectsOptimizeProjectArgs = {
  input: StudioProjectOptimizeProjectInput;
};


export type MutationStudioProjectsRemoveUserArgs = {
  input: StudioProjectsRemoveUserInput;
};


export type MutationStudioProjectsRestoreProjectArgs = {
  input: StudioProjectRestoreProjectInput;
};


export type MutationStudioProjectsUpdateProjectArgs = {
  input?: InputMaybe<StudioProjectsUpdateProjectInput>;
};


export type MutationStudioProjectsUpdateProjectLastViewArgs = {
  input: StudioProjectsUpdateProjectLastViewInput;
};


export type MutationStudioTemplateDeleteArgs = {
  input: StudioTemplateDeleteInput;
};


export type MutationStudioTemplatePublishFromProjectArgs = {
  input: StudioTemplatePublishFromProjectInput;
};


export type MutationStudioTemplateRestoreArgs = {
  input: StudioTemplateRestoreInput;
};


export type MutationStudioUploadsAddTagToFilesArgs = {
  input: StudioUploadsAddTagToFilesInput;
};


export type MutationStudioUploadsCreateFolderArgs = {
  input: StudioUploadsCreateFolderInput;
};


export type MutationStudioUploadsCreateSignedDownloadUrlArgs = {
  input: StudioUploadsCreateSignedDownloadUrlInput;
};


export type MutationStudioUploadsCreateSignedUrlArgs = {
  input: StudioUploadsCreateSignedUrlInput;
};


export type MutationStudioUploadsCreateTagArgs = {
  input: StudioUploadsCreateTagInput;
};


export type MutationStudioUploadsDeleteFilesArgs = {
  input: StudioUploadsDeleteFilesInput;
};


export type MutationStudioUploadsDeleteFolderArgs = {
  input: StudioUploadsDeleteFolderInput;
};


export type MutationStudioUploadsDeleteTagArgs = {
  input: StudioUploadsDeleteTagInput;
};


export type MutationStudioUploadsMoveFilesArgs = {
  input: StudioUploadsMoveFilesInput;
};


export type MutationStudioUploadsRemoveTagFromFileArgs = {
  input: StudioUploadsRemoveTagFromFileInput;
};


export type MutationStudioUploadsRenameFolderArgs = {
  input: StudioUploadsRenameFolderInput;
};


export type MutationStudioUploadsUpdateTagArgs = {
  input: StudioUploadsUpdateTagInput;
};


export type MutationTeamsApproveAdminRequestArgs = {
  input: TeamsApproveAdminRequestInput;
};


export type MutationTeamsApproveNewTeamMemberArgs = {
  input: TeamsApproveNewTeamMemberInput;
};


export type MutationTeamsApproveTeamMemberRoleChangeArgs = {
  input: TeamsApproveTeamMemberRoleChangeInput;
};


export type MutationTeamsCancelInviteArgs = {
  input: TeamsCancelInviteInput;
};


export type MutationTeamsChangeUserRoleArgs = {
  input: TeamsChangeUserRoleInput;
};


export type MutationTeamsConfirmInviteArgs = {
  input: TeamsConfirmInviteInput;
};


export type MutationTeamsCreateTeamArgs = {
  input: TeamsCreateTeamInput;
};


export type MutationTeamsDeactivateTeamArgs = {
  input: TeamsDeactivateTeamInput;
};


export type MutationTeamsGetCheckoutUrlArgs = {
  input: TeamsGetCheckoutUrlInput;
};


export type MutationTeamsInviteUsersArgs = {
  input: TeamsInviteUsersInput;
};


export type MutationTeamsLeaveTeamArgs = {
  input: TeamsLeaveTeamInput;
};


export type MutationTeamsRemoveUsersArgs = {
  input: TeamsRemoveUsersInput;
};


export type MutationTeamsResendInviteArgs = {
  input: TeamsResendInviteInput;
};


export type MutationTeamsUpdateTeamArgs = {
  input: TeamsUpdateTeamInput;
};


export type MutationTransfersAddRecipientsArgs = {
  input: TransfersAddRecipientsInput;
};


export type MutationTransfersCancelArgs = {
  input: TransfersCancelInput;
};


export type MutationTransfersConfirmOwnerEmailArgs = {
  input: TransfersConfirmOwnerEmailInput;
};


export type MutationTransfersCreateSignedDownloadUrlArgs = {
  input: TransfersCreateSignedDownloadUrlInput;
};


export type MutationTransfersDeleteArgs = {
  input: TransfersDeleteInput;
};


export type MutationTransfersInitiateTransferArgs = {
  input: TransfersInitiateTransferInput;
};


export type MutationTransfersInitiateTransferV2Args = {
  input: TransfersInitiateTransferV2Input;
};


export type MutationTransfersNotifyTransferDownloadCompletedArgs = {
  input: NotifyTransferDownloadCompletedInput;
};


export type MutationTransfersVerifyOwnerEmailArgs = {
  input: TransfersVerifyOwnerEmailInput;
};


export type MutationUnfollowUserArgs = {
  userID: Scalars['ID']['input'];
};


export type MutationUpdateFlowArgs = {
  id: Scalars['ID']['input'];
  input: FlowSettingsInput;
};


export type MutationUpdateFlowVisibilityArgs = {
  id: Scalars['ID']['input'];
  visibility: FlowVisibility;
};


export type MutationUpdateUserGdprArgs = {
  input: UserUpdateGdprInput;
};


export type MutationUploadsCreateSignedUrlV2Args = {
  input: UploadsCreateSignedUrlInputV2;
};


export type MutationUserFollowArgs = {
  userID: Scalars['ID']['input'];
};


export type MutationUserUnfollowArgs = {
  userID: Scalars['ID']['input'];
};


export type MutationUsersUpdateCurrencyArgs = {
  currency: CurrencyCode;
};


export type MutationUsersUpdateFlagsArgs = {
  input: UserUpdateFlagsInput;
};


export type MutationUsersUpdateLanguageArgs = {
  language: Language;
};


export type MutationVectorizerCancelVectorizationArgs = {
  input: VectorizerCancelImageVectorizationInput;
};


export type MutationVectorizerCreateImageArgs = {
  input: VectorizerCreateInput;
};


export type MutationVectorizerCreateSignedUploadUrlArgs = {
  input: VectorizerCreateSignedUploadUrl;
};


export type MutationVectorizerDeleteImageArgs = {
  input: VectorizerDeleteImageInput;
};


export type MutationVectorizerPublishImageArgs = {
  input: VectorizerPublishImageInput;
};


export type MutationVectorizerRegisterImagesToUserArgs = {
  input: VectorizerImagesRegisterToUserInput;
};


export type MutationViewFlowArgs = {
  flowID: Scalars['ID']['input'];
};

export type NewsletterPopup = {
  __typename?: 'NewsletterPopup';
  backgroundImageSrc: Scalars['String']['output'];
  button: NewsletterPopupButton;
  closeText: Scalars['String']['output'];
  footerText: Scalars['String']['output'];
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: SitePopupType;
};

export type NewsletterPopupButton = {
  __typename?: 'NewsletterPopupButton';
  backgroundColor: Scalars['String']['output'];
  link?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};

export type NotificationBanner = {
  __typename?: 'NotificationBanner';
  backgroundColor: Scalars['String']['output'];
  bannerAboveNav: Scalars['String']['output'];
  bannerAboveNavState: Scalars['String']['output'];
  button: NotificationBannerButton;
  countdown?: Maybe<SiteCountdown>;
  text: Scalars['String']['output'];
  textColor: Scalars['String']['output'];
  type: SiteBannerType;
};

export type NotificationBannerButton = {
  __typename?: 'NotificationBannerButton';
  backgroundColor: Scalars['String']['output'];
  hoverBackgroundColor: Scalars['String']['output'];
  hoverTextColor: Scalars['String']['output'];
  link: Scalars['String']['output'];
  text: Scalars['String']['output'];
  textColor: Scalars['String']['output'];
};

export type NotificationPopup = {
  __typename?: 'NotificationPopup';
  backgroundColor: Scalars['String']['output'];
  button: NotificationPopupButton;
  closeText: Scalars['String']['output'];
  countdown?: Maybe<SiteCountdown>;
  text: Scalars['String']['output'];
  textColor: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: SitePopupType;
};

export type NotificationPopupButton = {
  __typename?: 'NotificationPopupButton';
  backgroundColor: Scalars['String']['output'];
  hoverBackgroundColor: Scalars['String']['output'];
  hoverTextColor: Scalars['String']['output'];
  link: Scalars['String']['output'];
  text: Scalars['String']['output'];
  textColor: Scalars['String']['output'];
};

export type NotifyTransferDownloadCompletedInput = {
  accessCode: Scalars['String']['input'];
  transferId: Scalars['String']['input'];
};

export type NotifyTransferDownloadCompletedResponse = {
  __typename?: 'NotifyTransferDownloadCompletedResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type OnHoldSubscriptionBanner = {
  __typename?: 'OnHoldSubscriptionBanner';
  backgroundColor: Scalars['String']['output'];
  bannerAboveNav: Scalars['String']['output'];
  bannerAboveNavState: Scalars['String']['output'];
  text: Scalars['String']['output'];
  type: SiteBannerType;
};

export enum OwnedBy {
  Teams = 'teams',
  Users = 'users'
}

export type Pagination = {
  __typename?: 'Pagination';
  itemsPerPage: Scalars['Int']['output'];
  page: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type Payment = {
  __typename?: 'Payment';
  allowedPaymentMethodTypes: Array<AvailablePaymentMethod>;
  currency: PaymentCurrency;
  lineItems: Array<PaymentLineItem>;
  totals: PaymentTotals;
  user: User;
};

export type PaymentCurrency = {
  __typename?: 'PaymentCurrency';
  code: AvailableCurrencyCode;
  symbol: Scalars['String']['output'];
};

export type PaymentLineItem = {
  __typename?: 'PaymentLineItem';
  billingPeriod: BillingPeriod;
  name: Scalars['String']['output'];
  price: Scalars['Int']['output'];
  trialPeriodDays: Scalars['Int']['output'];
};

export type PaymentLineItemInput = {
  billingPeriod: BillingPeriod;
  name: Scalars['String']['input'];
  price: Scalars['Int']['input'];
  trialPeriodDays: Scalars['Int']['input'];
};

export enum PaymentStatus {
  Failed = 'failed',
  Processing = 'processing',
  RequiresAction = 'requiresAction',
  Succeeded = 'succeeded'
}

export type PaymentTotals = {
  __typename?: 'PaymentTotals';
  subtotal: Scalars['Int']['output'];
  tax: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  totalAfterTrial: Scalars['Int']['output'];
};

export type PriceCalculationsResponse = {
  __typename?: 'PriceCalculationsResponse';
  data?: Maybe<CalculatePricesResponse>;
  errors?: Maybe<Array<ErrorMessage>>;
};

export type ProductCounts = {
  __typename?: 'ProductCounts';
  crafts: Scalars['Int']['output'];
  embroidery: Scalars['Int']['output'];
  fonts: Scalars['Int']['output'];
  graphics: Scalars['Int']['output'];
};

/**
 * ProductsDownloadContentWithBackgroundRemovedResponse defines the response emitted by the dedicated query
 * for accessing a shared content background removed asset type.
 */
export type ProductsDownloadContentWithBackgroundRemovedResponse = {
  __typename?: 'ProductsDownloadContentWithBackgroundRemovedResponse';
  errors: Array<ErrorMessage>;
  generatedImageUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  sourceImageUrl?: Maybe<Scalars['String']['output']>;
  state?: Maybe<SparkState>;
};

export type ProfileNudgePopup = {
  __typename?: 'ProfileNudgePopup';
  button: ProfileNudgePopupButton;
  text: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: SitePopupType;
};

export type ProfileNudgePopupButton = {
  __typename?: 'ProfileNudgePopupButton';
  backgroundColor: Scalars['String']['output'];
  link?: Maybe<Scalars['String']['output']>;
  text: Scalars['String']['output'];
};

export enum ProjectEditor {
  Fabric = 'Fabric',
  Polotno = 'Polotno'
}

export type ProjectListPagination = {
  __typename?: 'ProjectListPagination';
  currentPage: Scalars['Int']['output'];
  itemsPerPage: Scalars['Int']['output'];
  totalItems: Scalars['Int']['output'];
};

export type ProjectSort = {
  direction: SortDirection;
  field: ProjectSortField;
};

export enum ProjectSortField {
  CreateTime = 'createTime',
  DeleteTime = 'deleteTime',
  LastViewTime = 'lastViewTime',
  Name = 'name',
  Unknown = 'unknown',
  UpdateTime = 'updateTime'
}

export enum ProjectTemplateOrigin {
  Global = 'Global',
  Project = 'Project'
}

export type PublicUserProfile = {
  __typename?: 'PublicUserProfile';
  avatarUrl?: Maybe<Scalars['String']['output']>;
  displayName?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  following: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isCreativeFabrica?: Maybe<Scalars['Boolean']['output']>;
  isFollowed?: Maybe<Scalars['Boolean']['output']>;
  isPremium?: Maybe<Scalars['Boolean']['output']>;
  isTemporary: Scalars['Boolean']['output'];
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  profileUrls?: Maybe<PublicUserProfileUrls>;
  shopName?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
};

export enum PublicUserProfileUrlType {
  Crafter = 'Crafter',
  Designer = 'Designer',
  Teacher = 'Teacher',
  TheArtistry = 'TheArtistry'
}

export type PublicUserProfileUrls = {
  __typename?: 'PublicUserProfileUrls';
  crafter?: Maybe<Scalars['String']['output']>;
  designer?: Maybe<Scalars['String']['output']>;
  teacher?: Maybe<Scalars['String']['output']>;
  theArtistry?: Maybe<Scalars['String']['output']>;
};

export type Query = {
  __typename?: 'Query';
  advertisingGetCampaign: AdvertisingGetCampaignResponse;
  advertisingGetRandomNCampaigns: AdvertisingGetRandomNCampaignsResponse;
  advertisingGetTotalActionsByMonth: AdvertisingGetTotalActionsByMonthResponse;
  advertisingGetUploaded: AdvertisingGetUploadedResponse;
  advertisingListCampaign: AdvertisingListCampaignResponse;
  askCFGetQuestion: AskCfGetQuestionResponse;
  askCFGetTopic: AskGetTopicResponse;
  askCFGetTopics: AskGetTopicsResponse;
  askCFGetUploaded: AskCfGetUploadedResponse;
  askCFGetUserStats: AskGetUserStatsResponse;
  askCFHomeFeed: AskCfFeedResponse;
  askCFTopicFeed: AskCfFeedResponse;
  askCFUserAnsweredFeed: AskCfFeedResponse;
  askCFUserFeed: AskCfFeedResponse;
  cachedSiteData: SiteData;
  calculatePrices: PriceCalculationsResponse;
  cfmainGetEnterpriseRequest: CfMainGetEnterpriseRequestResponse;
  cfmainListEnterpriseRequests: CfMainListEnterpriseRequestsResponse;
  chipsForImage: Array<Scalars['String']['output']>;
  chipsForPrompt: Array<Scalars['String']['output']>;
  contentCountFavorites: ContentProductFavoriteCount;
  contentDownloadAssets: Array<AssetDownloadResponse>;
  /**
   * Allows the download of one or more assets uniquely identify by their asset id.
   *     By default this query will not increase the download count on those products  you should use
   *     the "contentIncreaseDownloadCountV2" mutation for that.
   */
  contentDownloadAssetsV2?: Maybe<AssetsDownloadResponseV2>;
  contentFetchCategory: ContentCategory;
  contentFetchLicense?: Maybe<ContentLicense>;
  contentFetchReview: ContentReview;
  contentIsProductFavorited: Scalars['Boolean']['output'];
  contentLicenseCount: Scalars['Int']['output'];
  contentLinkBlock?: Maybe<ContentLinkBlock>;
  contentListFavoriteLists: Array<ContentFavoriteList>;
  contentListFavorites: Array<ContentFavorite>;
  contentListProducts: ContentPagination;
  contentMyReview?: Maybe<ContentReview>;
  contentProduct?: Maybe<ContentProduct>;
  contentProductReviews: ContentProductReviews;
  contentSearchCategory: Array<ContentCategory>;
  contentUserFavoritesCount: ContentUserListFavoritesCount;
  contentUserListFavorites: ContentUserListFavorites;
  designReviewerGetReview: DesignReviewerGetReviewResponse;
  designReviewerListReviews: DesignReviewerListReviewsResponse;
  flow?: Maybe<Flow>;
  flowFeed: FlowFeed;
  flowFeedStatus: Array<FlowStatus>;
  flowImageGetReactions: FlowImageGetReactionsResponse;
  flowMostLikedFlows: Array<Scalars['String']['output']>;
  flowMostViewedFlows: Array<Scalars['String']['output']>;
  flowQueue: Scalars['Int']['output'];
  flowStatus: FlowStatus;
  flowViewers: FlowViewersResponse;
  flows: Array<Flow>;
  fontsSearch: FontsSearchResponse;
  helpConversation?: Maybe<HelpConversation>;
  helpWelcomeMessage: Scalars['String']['output'];
  isUploadAllowed: IsUploadAllowedResponse;
  me?: Maybe<AuthResponse>;
  /** @deprecated Use flowMostLikedFlows */
  mostLikedFlows: Array<Scalars['String']['output']>;
  /** @deprecated Use flowMostViewedFlows */
  mostViewedFlows: Array<Scalars['String']['output']>;
  paymentsCalculateTax: PaymentTotals;
  paymentsStripeSubscriptionIdToExtend?: Maybe<Scalars['String']['output']>;
  paymentsStripeSubscriptionIdToUpgrade?: Maybe<Scalars['String']['output']>;
  /** Provides the query that can be used to retrieve the asset with the background removed for the specified product. */
  productsDownloadContentWithBackgroundRemoved: ProductsDownloadContentWithBackgroundRemovedResponse;
  publicUserProfile: PublicUserProfile;
  publicUserProfileById: PublicUserProfile;
  searchPrompts: SearchPromptsResponse;
  siteData: SiteData;
  snippetsGetSnippet: SnippetsGetSnippetResponse;
  snippetsListSnippets: SnippetsListSnippetResponse;
  sparkArt: SparkArtGetResponse;
  sparkArtList: SparkArtListResponse;
  sparkCrystalline: SparkCrystallineGetResponse;
  sparkCrystallineList: SparkCrystallineListResponse;
  sparkGetUploaded: SparkGetUploadedResponse;
  sparkImageMixGet: SparkImageMixGetResponse;
  sparkImageMixList: SparkImageMixListResponse;
  sparkInpaintGet: SparkInpaintGetResponse;
  sparkInpaintList: SparkInpaintListResponse;
  sparkOutpaintGet: SparkOutpaintGetResponse;
  sparkOutpaintList: SparkOutpaintListResponse;
  sparkRemovedBackground: SparkRemoveBackgroundGetResponse;
  sparkRemovedBackgroundList: SparkRemovedBackgroundListResponse;
  storageInfo: StorageInfoResponse;
  studioProjectDownloadAssets: StudioProjectDownloadAssetsResponse;
  studioProjectsFolderGet: StudioProjectsFolderGetResponse;
  studioProjectsGetProject: StudioProjectsGetProjectResponse;
  studioProjectsGetProjectComments: StudioProjectsGetProjectCommentsResponse;
  studioProjectsGetTeam: StudioProjectsGetTeamResponse;
  studioProjectsListItemsInTrash: StudioProjectsListItemsInTrashResponse;
  studioProjectsListMostRecent: StudioProjectsListMostRecentResponse;
  studioProjectsListProjects: StudioProjectsListProjectsResponse;
  studioProjectsListProjectsInTrash: StudioProjectsListProjectsInTrashResponse;
  studioProjectsListProjectsSharedWithMe: StudioProjectsListProjectsSharedWithMeResponse;
  studioTemplatesList: StudioTemplateListResponse;
  studioUploadsGetFolder: StudioUploadsGetFolderResponse;
  studioUploadsGetTagsForUploads: StudioUploadsGetTagsForUploadsResponse;
  studioUploadsSearchFiles: StudioUploadsSearchFilesResponse;
  studioUploadsTags: StudioUploadsTagsResponse;
  teamsFetchOutstandingInvoiceUrl: TeamsFetchOutstandingInvoiceUrlResponse;
  /** @deprecated Use teamsPreviewUpcomingCharges instead */
  teamsFetchUpcomingInvoiceUrl: TeamsFetchUpcomingInvoiceUrlResponse;
  teamsGet: TeamsGetTeamResponse;
  teamsPreviewUpcomingCharges: TeamsPreviewUpcomingChargesResponse;
  transfersGetTransfer: TransfersGetTransferResponse;
  transfersListTransfers: ListTransfersResponse;
  user?: Maybe<User>;
  userBalance: UserBalance;
  validateToken?: Maybe<ValidateTokenResponse>;
  vectorizerGetImage: VectorizerGetImageResponse;
  vectorizerListImages: VectorizerListImagesResponse;
};


export type QueryAdvertisingGetCampaignArgs = {
  input: AdvertisingGetCampaignInput;
};


export type QueryAdvertisingGetRandomNCampaignsArgs = {
  input?: InputMaybe<AdvertisingGetRandomNCampaignsInput>;
};


export type QueryAdvertisingGetTotalActionsByMonthArgs = {
  input: AdvertisingGetTotalActionsByMonthInput;
};


export type QueryAdvertisingGetUploadedArgs = {
  input: AdvertisingGetUploadedInput;
};


export type QueryAdvertisingListCampaignArgs = {
  input: AdvertisingListCampaignInput;
};


export type QueryAskCfGetQuestionArgs = {
  input: AskCfGetQuestionInput;
};


export type QueryAskCfGetTopicArgs = {
  input: AskCfGetTopicInput;
};


export type QueryAskCfGetUploadedArgs = {
  input: AskCfGetUploadedInput;
};


export type QueryAskCfGetUserStatsArgs = {
  input: AskCfGetUserStatsInput;
};


export type QueryAskCfHomeFeedArgs = {
  input: AskCfHomeFeedInput;
};


export type QueryAskCfTopicFeedArgs = {
  input: AskCfTopicFeedInput;
};


export type QueryAskCfUserAnsweredFeedArgs = {
  input: AskCfUserAnsweredFeedInput;
};


export type QueryAskCfUserFeedArgs = {
  input: AskCfUserFeedInput;
};


export type QueryCachedSiteDataArgs = {
  language?: InputMaybe<Language>;
};


export type QueryCalculatePricesArgs = {
  input: CalculatePricesInput;
};


export type QueryCfmainGetEnterpriseRequestArgs = {
  input: CfMainGetEnterpriseRequestInput;
};


export type QueryCfmainListEnterpriseRequestsArgs = {
  input: CfMainListEnterpriseRequestsInput;
};


export type QueryChipsForImageArgs = {
  imageUrl: Scalars['String']['input'];
};


export type QueryChipsForPromptArgs = {
  prompt: Scalars['String']['input'];
};


export type QueryContentCountFavoritesArgs = {
  productId: Scalars['ID']['input'];
};


export type QueryContentDownloadAssetsArgs = {
  ids: Array<Scalars['ID']['input']>;
  source?: InputMaybe<ContentDownloadSource>;
};


export type QueryContentDownloadAssetsV2Args = {
  input: ContentDownloadAssetsInput;
};


export type QueryContentFetchCategoryArgs = {
  id: Scalars['ID']['input'];
  language: ContentLanguage;
};


export type QueryContentFetchLicenseArgs = {
  input: ContentFetchLicenseInput;
};


export type QueryContentFetchReviewArgs = {
  id: Scalars['ID']['input'];
};


export type QueryContentIsProductFavoritedArgs = {
  productId: Scalars['ID']['input'];
};


export type QueryContentLicenseCountArgs = {
  currentSubscriptionOnly?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryContentLinkBlockArgs = {
  id: Scalars['ID']['input'];
  language: ContentLanguage;
};


export type QueryContentListFavoriteListsArgs = {
  cursor?: InputMaybe<Scalars['String']['input']>;
};


export type QueryContentListFavoritesArgs = {
  input?: InputMaybe<ContentListFavoritesInput>;
};


export type QueryContentListProductsArgs = {
  categoryId: Scalars['ID']['input'];
  language: ContentLanguage;
  page: Scalars['Int']['input'];
  type: ContentPaginationType;
};


export type QueryContentMyReviewArgs = {
  productId: Scalars['ID']['input'];
};


export type QueryContentProductArgs = {
  id: Scalars['ID']['input'];
  increaseViewCount?: InputMaybe<Scalars['Boolean']['input']>;
  language: ContentLanguage;
};


export type QueryContentProductReviewsArgs = {
  input: ContentProductReviewsInput;
};


export type QueryContentSearchCategoryArgs = {
  allLevels?: InputMaybe<Scalars['Boolean']['input']>;
  language: ContentLanguage;
  name: Scalars['String']['input'];
  parentId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryContentUserFavoritesCountArgs = {
  input?: InputMaybe<ContentUserFavoritesCountInput>;
};


export type QueryContentUserListFavoritesArgs = {
  input?: InputMaybe<ContentUserListFavoritesInput>;
};


export type QueryDesignReviewerGetReviewArgs = {
  input: DesignReviewerGetReviewInput;
};


export type QueryDesignReviewerListReviewsArgs = {
  input: DesignReviewerListReviewsInput;
};


export type QueryFlowArgs = {
  id: Scalars['String']['input'];
  iterations?: InputMaybe<FlowIterationsInput>;
};


export type QueryFlowFeedArgs = {
  afterId?: InputMaybe<Scalars['String']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFlowImageGetReactionsArgs = {
  input: FlowImageGetReactionsInput;
};


export type QueryFlowQueueArgs = {
  flowID: Scalars['String']['input'];
};


export type QueryFlowStatusArgs = {
  flowID: Scalars['ID']['input'];
};


export type QueryFlowViewersArgs = {
  flowID: Scalars['ID']['input'];
};


export type QueryFlowsArgs = {
  afterId?: InputMaybe<Scalars['String']['input']>;
  take: Scalars['Int']['input'];
};


export type QueryFontsSearchArgs = {
  input?: InputMaybe<FontsSearchInput>;
};


export type QueryHelpConversationArgs = {
  channel: HelpChannel;
  conversationId: Scalars['ID']['input'];
};


export type QueryHelpWelcomeMessageArgs = {
  channel: HelpChannel;
  language?: InputMaybe<Language>;
};


export type QueryIsUploadAllowedArgs = {
  request?: InputMaybe<IsUploadAllowedRequest>;
};


export type QueryPaymentsCalculateTaxArgs = {
  address: AddressInput;
  currencyCode: AvailableCurrencyCode;
  euTaxId?: InputMaybe<Scalars['String']['input']>;
  lineItems: Array<PaymentLineItemInput>;
};


export type QueryPaymentsStripeSubscriptionIdToExtendArgs = {
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPaymentsStripeSubscriptionIdToUpgradeArgs = {
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryProductsDownloadContentWithBackgroundRemovedArgs = {
  productId: Scalars['ID']['input'];
};


export type QueryPublicUserProfileArgs = {
  slug: Scalars['String']['input'];
};


export type QueryPublicUserProfileByIdArgs = {
  id: Scalars['ID']['input'];
};


export type QuerySearchPromptsArgs = {
  input: SearchPromptsInput;
};


export type QuerySnippetsGetSnippetArgs = {
  input: SnippetsGetSnippetInput;
};


export type QuerySnippetsListSnippetsArgs = {
  input: SnippetsListSnippetInput;
};


export type QuerySparkArtArgs = {
  input: SparkArtGetInput;
};


export type QuerySparkArtListArgs = {
  input?: InputMaybe<SparkArtListInput>;
};


export type QuerySparkCrystallineArgs = {
  input: SparkCrystallineGetInput;
};


export type QuerySparkCrystallineListArgs = {
  input?: InputMaybe<SparkCrystallineListInput>;
};


export type QuerySparkGetUploadedArgs = {
  input: SparkGetUploadedInput;
};


export type QuerySparkImageMixGetArgs = {
  input: SparkImageMixGetInput;
};


export type QuerySparkImageMixListArgs = {
  input?: InputMaybe<SparkImageMixListInput>;
};


export type QuerySparkInpaintGetArgs = {
  input: SparkInpaintGetInput;
};


export type QuerySparkInpaintListArgs = {
  input?: InputMaybe<SparkInpaintListInput>;
};


export type QuerySparkOutpaintGetArgs = {
  input: SparkOutpaintGetInput;
};


export type QuerySparkOutpaintListArgs = {
  input?: InputMaybe<SparkOutpaintListInput>;
};


export type QuerySparkRemovedBackgroundArgs = {
  input: SparkRemoveBackgroundGetInput;
};


export type QuerySparkRemovedBackgroundListArgs = {
  input?: InputMaybe<SparkRemovedBackgroundListInput>;
};


export type QueryStorageInfoArgs = {
  teamId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryStudioProjectDownloadAssetsArgs = {
  input: StudioProjectDownloadAssetsInput;
};


export type QueryStudioProjectsFolderGetArgs = {
  input: StudioProjectsFolderGetInput;
};


export type QueryStudioProjectsGetProjectArgs = {
  projectId: Scalars['ID']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
};


export type QueryStudioProjectsGetProjectCommentsArgs = {
  input: StudioProjectsGetProjectCommentsInput;
};


export type QueryStudioProjectsGetTeamArgs = {
  input: StudioProjectsGetTeamInput;
};


export type QueryStudioProjectsListItemsInTrashArgs = {
  input: StudioProjectsListItemsInTrashInput;
};


export type QueryStudioProjectsListMostRecentArgs = {
  input: StudioProjectsListMostRecentInput;
};


export type QueryStudioProjectsListProjectsArgs = {
  input: StudioProjectsListProjectsInput;
};


export type QueryStudioProjectsListProjectsInTrashArgs = {
  input: StudioProjectsListProjectsInTrashInput;
};


export type QueryStudioProjectsListProjectsSharedWithMeArgs = {
  input: StudioProjectsListProjectsSharedWithMeInput;
};


export type QueryStudioTemplatesListArgs = {
  input: StudioTemplatesListInput;
};


export type QueryStudioUploadsGetFolderArgs = {
  input: StudioUploadsGetFolderInput;
};


export type QueryStudioUploadsGetTagsForUploadsArgs = {
  input: StudioUploadsGetTagsForUploadsInput;
};


export type QueryStudioUploadsSearchFilesArgs = {
  input?: InputMaybe<StudioUploadsSearchFilesInput>;
};


export type QueryStudioUploadsTagsArgs = {
  input?: InputMaybe<StudioUploadsTagsInput>;
};


export type QueryTeamsFetchOutstandingInvoiceUrlArgs = {
  input: TeamsFetchOutstandingInvoiceUrlInput;
};


export type QueryTeamsFetchUpcomingInvoiceUrlArgs = {
  input: TeamsFetchUpcomingInvoiceUrlInput;
};


export type QueryTeamsGetArgs = {
  input: TeamsGetTeamInput;
};


export type QueryTeamsPreviewUpcomingChargesArgs = {
  input: TeamsPreviewUpcomingChargesInput;
};


export type QueryTransfersGetTransferArgs = {
  input: TransfersGetTransferInput;
};


export type QueryTransfersListTransfersArgs = {
  input: ListTransfersInput;
};


export type QueryUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryVectorizerGetImageArgs = {
  input: VectorizerGetImageInput;
};

export type Question = {
  __typename?: 'Question';
  answers?: Maybe<Array<Answer>>;
  assets?: Maybe<Array<AskCfAsset>>;
  context?: Maybe<Scalars['String']['output']>;
  createTime: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  question: Scalars['String']['output'];
  questionSlug: Scalars['String']['output'];
  topic: Scalars['String']['output'];
  topicClassifierTimeout: Scalars['Boolean']['output'];
  topicSlug: Scalars['String']['output'];
  totalAnswers: Scalars['Int']['output'];
  user: PublicUserProfile;
  /** @deprecated Use user field instead */
  userId?: Maybe<Scalars['ID']['output']>;
};

export type RefreshTokenResponse = {
  __typename?: 'RefreshTokenResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  token?: Maybe<Scalars['String']['output']>;
};

export type RegisterInput = {
  deviceId: Scalars['String']['input'];
  email: Scalars['String']['input'];
  otp?: InputMaybe<Scalars['String']['input']>;
  password: Scalars['String']['input'];
  signIn?: InputMaybe<Scalars['Boolean']['input']>;
  terms: Scalars['Boolean']['input'];
};

export type RegisterResponse = {
  __typename?: 'RegisterResponse';
  challenge?: Maybe<AuthChallenge>;
  errors?: Maybe<Array<ErrorMessage>>;
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
};

export type RemoveBackgroundPublishImageInput = {
  id: Scalars['String']['input'];
};

export type RemoveBackgroundPublishImageResponse = {
  __typename?: 'RemoveBackgroundPublishImageResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  upload?: Maybe<StudioUploadsFile>;
};

export type ResetPasswordInput = {
  email: Scalars['String']['input'];
  key: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
};

export type ResetPasswordResponse = {
  __typename?: 'ResetPasswordResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type ReviewAspect = {
  __typename?: 'ReviewAspect';
  feedback: Scalars['String']['output'];
  rating: Scalars['Int']['output'];
};

export enum Role {
  Admin = 'Admin',
  Editor = 'Editor',
  NoAccess = 'NoAccess',
  Owner = 'Owner',
  Unknown = 'Unknown',
  Viewer = 'Viewer'
}

export enum RoleChangeStatus {
  AwaitingAdminApproval = 'AwaitingAdminApproval',
  Updated = 'Updated'
}

export type SearchPromptResponse = {
  __typename?: 'SearchPromptResponse';
  flowID: Scalars['String']['output'];
  imageID: Scalars['String']['output'];
  imageURL: Scalars['String']['output'];
  iterationID: Scalars['String']['output'];
  pageID?: Maybe<Scalars['String']['output']>;
  reactions: Scalars['Int']['output'];
  settings: FlowIterationSettings;
  userID: Scalars['String']['output'];
};

export type SearchPromptsInput = {
  limit: Scalars['Int']['input'];
  sort?: InputMaybe<SearchPromptsSort>;
  text: Scalars['String']['input'];
};

export type SearchPromptsResponse = {
  __typename?: 'SearchPromptsResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  prompts?: Maybe<Array<SearchPromptResponse>>;
};

export type SearchPromptsSort = {
  direction: SortDirection;
  field: SearchPromptsSortField;
};

export enum SearchPromptsSortField {
  CreateTime = 'createTime',
  Reactions = 'reactions'
}

export type SharedProjectsPagination = {
  __typename?: 'SharedProjectsPagination';
  currentPage: Scalars['Int']['output'];
  limit: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type SiteBannerCountdown = {
  __typename?: 'SiteBannerCountdown';
  text: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export enum SiteBannerType {
  AllAccess = 'all_access',
  AllAccessYearly = 'all_access_yearly',
  AllAccessYearlyExtend = 'all_access_yearly_extend',
  AwaitingRenewalConfirmation = 'awaiting_renewal_confirmation',
  CfPendingCancel = 'cf_pending_cancel',
  Classes = 'classes',
  Notification = 'notification',
  OnHoldSubscription = 'on_hold_subscription',
  PendingCancel = 'pending_cancel',
  SparkFreeTrial = 'spark_free_trial',
  Upsell = 'upsell',
  Verification = 'verification'
}

export type SiteCountdown = {
  __typename?: 'SiteCountdown';
  text: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type SiteCurrencies = {
  __typename?: 'SiteCurrencies';
  activeCurrency: Currency;
  currencies: Array<Currency>;
};

export type SiteData = {
  __typename?: 'SiteData';
  allAccessBanner?: Maybe<AllAccessBanner>;
  allAccessYearlyBanner?: Maybe<AllAccessYearlyBanner>;
  allAccessYearlyExtendBanner?: Maybe<AllAccessYearlyExtendBanner>;
  awaitingRenewalConfirmationBanner?: Maybe<AwaitingRenewalConfirmationBanner>;
  billing: Scalars['Boolean']['output'];
  classesBanner?: Maybe<ClassesBanner>;
  currencies: SiteCurrencies;
  newsletterPopup?: Maybe<NewsletterPopup>;
  notificationBanner?: Maybe<NotificationBanner>;
  notificationPopup?: Maybe<NotificationPopup>;
  onHoldSubscriptionBanner?: Maybe<OnHoldSubscriptionBanner>;
  productCounts: ProductCounts;
  profileNudgePopup?: Maybe<ProfileNudgePopup>;
  promo: SitePromotions;
  sparkFreeTrialBanner?: Maybe<SparkFreeTrialBanner>;
  undoCancelationBanner?: Maybe<UndoCancelationBanner>;
  upsellBanner?: Maybe<UpsellBanner>;
  verificationBanner?: Maybe<VerificationBanner>;
};

export enum SitePopupType {
  Newsletter = 'newsletter',
  ProfileNudge = 'profile_nudge',
  SitewidePopup = 'sitewide_popup'
}

export type SitePromotion = {
  __typename?: 'SitePromotion';
  discount: Scalars['Float']['output'];
  name: Scalars['String']['output'];
};

export type SitePromotions = {
  __typename?: 'SitePromotions';
  promotion?: Maybe<SitePromotion>;
  takeover?: Maybe<SiteTakeover>;
};

export type SiteTakeover = {
  __typename?: 'SiteTakeover';
  aboveNavBannerBtnColor: Scalars['String']['output'];
  aboveNavBannerBtnTextColor: Scalars['String']['output'];
  aboveNavBannerColor: Scalars['String']['output'];
  aboveNavBannerImg: Scalars['String']['output'];
  homepageFourButtonsColor: Scalars['String']['output'];
  homepageFourButtonsTextColor: Scalars['String']['output'];
  homepageHeroBtnColor: Scalars['String']['output'];
  homepageHeroBtnText: Scalars['String']['output'];
  homepageHeroBtnTextColor: Scalars['String']['output'];
  homepageHeroBtnUrl: Scalars['String']['output'];
  homepageHeroImg: Scalars['String']['output'];
  homepageHeroImgLeftImg: Scalars['String']['output'];
  homepageHeroImgRightImg: Scalars['String']['output'];
  homepageHeroImgUrl: Scalars['String']['output'];
  homepageHeroMblImg: Scalars['String']['output'];
  homepageHeroText: Scalars['String']['output'];
  homepageHeroTextColor: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  navMenuItemColor: Scalars['String']['output'];
  title: Scalars['String']['output'];
  websiteLogoImg: Scalars['String']['output'];
};

export type Snippet = {
  __typename?: 'Snippet';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  snippet: Scalars['String']['output'];
  snippetUrl: Scalars['String']['output'];
};

export enum SnippetView {
  LoggedUsersOnly = 'logged_users_only',
  Public = 'public',
  Unspecified = 'unspecified'
}

export type SnippetsCreateSnippetInput = {
  name: Scalars['String']['input'];
  snippet: Scalars['String']['input'];
};

export type SnippetsCreateSnippetResponse = {
  __typename?: 'SnippetsCreateSnippetResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  snippet?: Maybe<Snippet>;
};

export type SnippetsDeleteSnippetInput = {
  id: Scalars['ID']['input'];
};

export type SnippetsDeleteSnippetResponse = {
  __typename?: 'SnippetsDeleteSnippetResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  snippet?: Maybe<Snippet>;
};

export type SnippetsGetSnippetInput = {
  id: Scalars['ID']['input'];
};

export type SnippetsGetSnippetResponse = {
  __typename?: 'SnippetsGetSnippetResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  snippet?: Maybe<Snippet>;
};

export type SnippetsListPagination = {
  __typename?: 'SnippetsListPagination';
  currentPage: Scalars['Int']['output'];
  itemsPerPage: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type SnippetsListSnippetInput = {
  currentPage: Scalars['Int']['input'];
  itemsPerPage: Scalars['Int']['input'];
};

export type SnippetsListSnippetResponse = {
  __typename?: 'SnippetsListSnippetResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  pagination: SnippetsListPagination;
  snippets?: Maybe<Array<Snippet>>;
};

export type SnippetsUpdateSnippetInput = {
  id: Scalars['ID']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  snippet?: InputMaybe<Scalars['String']['input']>;
};

export type SnippetsUpdateSnippetResponse = {
  __typename?: 'SnippetsUpdateSnippetResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  snippet?: Maybe<Snippet>;
};

export type SocialLoginInput = {
  accessToken: Scalars['String']['input'];
  clientType: ClientType;
  type: AuthType;
};

export type SocialRedirectLoginInput = {
  clientType: ClientType;
  type: AuthType;
};

export type SocialRedirectLoginResponse = {
  __typename?: 'SocialRedirectLoginResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  target_url?: Maybe<Scalars['String']['output']>;
};

export enum SortDirection {
  Asc = 'asc',
  Desc = 'desc'
}

export type SparkArt = {
  __typename?: 'SparkArt';
  id: Scalars['ID']['output'];
  images: Array<SparkArtImage>;
  prompt: Scalars['String']['output'];
};

export enum SparkArtAspectRatio {
  Landscape_4_3 = 'landscape_4_3',
  Landscape_16_9 = 'landscape_16_9',
  Portrait_3_4 = 'portrait_3_4',
  Portrait_9_16 = 'portrait_9_16',
  Square = 'square'
}

export type SparkArtDeleteInput = {
  id: Scalars['String']['input'];
};

export type SparkArtDeleteResponse = {
  __typename?: 'SparkArtDeleteResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type SparkArtGenerateInput = {
  aspectRatio?: InputMaybe<SparkArtAspectRatio>;
  prompt: Scalars['String']['input'];
};

export type SparkArtGenerateResponse = {
  __typename?: 'SparkArtGenerateResponse';
  art?: Maybe<SparkArt>;
  errors?: Maybe<Array<ErrorMessage>>;
};

export type SparkArtGetInput = {
  id: Scalars['String']['input'];
};

export type SparkArtGetResponse = {
  __typename?: 'SparkArtGetResponse';
  art?: Maybe<SparkArt>;
  errors?: Maybe<Array<ErrorMessage>>;
};

export type SparkArtImage = {
  __typename?: 'SparkArtImage';
  id: Scalars['ID']['output'];
  originalUrl?: Maybe<Scalars['String']['output']>;
  state: SparkState;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
};

export type SparkArtListInput = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
};

export type SparkArtListPagination = {
  __typename?: 'SparkArtListPagination';
  currentPage: Scalars['Int']['output'];
  itemsPerPage: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type SparkArtListResponse = {
  __typename?: 'SparkArtListResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  list?: Maybe<Array<SparkArt>>;
  pagination?: Maybe<SparkArtListPagination>;
};

export type SparkArtRegisterInput = {
  ids: Array<Scalars['String']['input']>;
};

export type SparkBackgroundRemovedRegisterInput = {
  ids: Array<Scalars['String']['input']>;
};

export type SparkCrystalline = {
  __typename?: 'SparkCrystalline';
  id: Scalars['ID']['output'];
  images: Array<SparkCrystallineImage>;
  prompt: Scalars['String']['output'];
};

export type SparkCrystallineDeleteInput = {
  id: Scalars['String']['input'];
};

export type SparkCrystallineDeleteResponse = {
  __typename?: 'SparkCrystallineDeleteResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type SparkCrystallineGenerateInput = {
  prompt: Scalars['String']['input'];
};

export type SparkCrystallineGenerateResponse = {
  __typename?: 'SparkCrystallineGenerateResponse';
  crystalline?: Maybe<SparkCrystalline>;
  errors?: Maybe<Array<ErrorMessage>>;
};

export type SparkCrystallineGetInput = {
  id: Scalars['String']['input'];
};

export type SparkCrystallineGetResponse = {
  __typename?: 'SparkCrystallineGetResponse';
  crystalline?: Maybe<SparkCrystalline>;
  errors?: Maybe<Array<ErrorMessage>>;
};

export type SparkCrystallineImage = {
  __typename?: 'SparkCrystallineImage';
  id: Scalars['ID']['output'];
  originalUrl?: Maybe<Scalars['String']['output']>;
  state: SparkState;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
};

export type SparkCrystallineListInput = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
};

export type SparkCrystallineListPagination = {
  __typename?: 'SparkCrystallineListPagination';
  currentPage: Scalars['Int']['output'];
  itemsPerPage: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type SparkCrystallineListResponse = {
  __typename?: 'SparkCrystallineListResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  list?: Maybe<Array<SparkCrystalline>>;
  pagination?: Maybe<SparkCrystallineListPagination>;
};

export type SparkCrystallineRegisterInput = {
  ids: Array<Scalars['String']['input']>;
};

export type SparkFreeTrialBanner = {
  __typename?: 'SparkFreeTrialBanner';
  aboveButtonText: Scalars['String']['output'];
  backgroundImage: Scalars['String']['output'];
  bannerAboveNav: Scalars['String']['output'];
  bannerAboveNavState: Scalars['String']['output'];
  bannerIsMinimized: Scalars['Boolean']['output'];
  belowButtonText: Scalars['String']['output'];
  buttonText: Scalars['String']['output'];
  buttonUrl: Scalars['String']['output'];
  subtext: Scalars['String']['output'];
  text: Scalars['String']['output'];
  type: SiteBannerType;
};

export type SparkGetUploadedInput = {
  uploadId: Scalars['String']['input'];
};

export type SparkGetUploadedResponse = {
  __typename?: 'SparkGetUploadedResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  imageUrl?: Maybe<Scalars['String']['output']>;
};

export type SparkImageMix = {
  __typename?: 'SparkImageMix';
  id: Scalars['ID']['output'];
  imageUrl: Scalars['String']['output'];
  images: Array<SparkImageMixVersion>;
  prompt?: Maybe<Scalars['String']['output']>;
  strength: Scalars['Float']['output'];
  userId: Scalars['ID']['output'];
};

export type SparkImageMixDeleteAllResponse = {
  __typename?: 'SparkImageMixDeleteAllResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type SparkImageMixDeleteInput = {
  id: Scalars['ID']['input'];
};

export type SparkImageMixDeleteResponse = {
  __typename?: 'SparkImageMixDeleteResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type SparkImageMixGenerateInput = {
  imageUrl: Scalars['String']['input'];
  prompt?: InputMaybe<Scalars['String']['input']>;
  strength: Scalars['Float']['input'];
};

export type SparkImageMixGenerateResponse = {
  __typename?: 'SparkImageMixGenerateResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  imageMix: SparkImageMix;
};

export type SparkImageMixGetInput = {
  id: Scalars['ID']['input'];
};

export type SparkImageMixGetResponse = {
  __typename?: 'SparkImageMixGetResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  imageMix?: Maybe<SparkImageMix>;
};

export type SparkImageMixListInput = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
};

export type SparkImageMixListPagination = {
  __typename?: 'SparkImageMixListPagination';
  currentPage: Scalars['Int']['output'];
  itemsPerPage: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type SparkImageMixListResponse = {
  __typename?: 'SparkImageMixListResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  list?: Maybe<Array<SparkImageMix>>;
  pagination?: Maybe<SparkImageMixListPagination>;
};

export type SparkImageMixRegisterInput = {
  ids: Array<Scalars['String']['input']>;
};

export type SparkImageMixVersion = {
  __typename?: 'SparkImageMixVersion';
  id: Scalars['ID']['output'];
  originalUrl?: Maybe<Scalars['String']['output']>;
  state: SparkState;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
};

export type SparkInpaint = {
  __typename?: 'SparkInpaint';
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  prompt: Scalars['String']['output'];
  srcImageUrl: Scalars['String']['output'];
  srcInpaintAreaImageUrl: Scalars['String']['output'];
  srcThumbnailUrl: Scalars['String']['output'];
  state: SparkState;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
};

export type SparkInpaintDeleteAllResponse = {
  __typename?: 'SparkInpaintDeleteAllResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type SparkInpaintDeleteInput = {
  id: Scalars['ID']['input'];
};

export type SparkInpaintDeleteResponse = {
  __typename?: 'SparkInpaintDeleteResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type SparkInpaintGetInput = {
  id: Scalars['ID']['input'];
};

export type SparkInpaintGetResponse = {
  __typename?: 'SparkInpaintGetResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  inpaint?: Maybe<SparkInpaint>;
};

export type SparkInpaintImageInput = {
  imageUrl: Scalars['String']['input'];
  inpaintAreaImageUrl: Scalars['String']['input'];
  prompt: Scalars['String']['input'];
};

export type SparkInpaintImageResponse = {
  __typename?: 'SparkInpaintImageResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  inpaint?: Maybe<SparkInpaint>;
};

export type SparkInpaintListInput = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
};

export type SparkInpaintListPagination = {
  __typename?: 'SparkInpaintListPagination';
  currentPage: Scalars['Int']['output'];
  itemsPerPage: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type SparkInpaintListResponse = {
  __typename?: 'SparkInpaintListResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  list?: Maybe<Array<SparkInpaint>>;
  pagination: SparkInpaintListPagination;
};

export type SparkInpaintRegisterInput = {
  ids: Array<Scalars['String']['input']>;
};

export type SparkOutpaint = {
  __typename?: 'SparkOutpaint';
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  increaseDown: Scalars['Int']['output'];
  increaseLeft: Scalars['Int']['output'];
  increaseRight: Scalars['Int']['output'];
  increaseUp: Scalars['Int']['output'];
  prompt: Scalars['String']['output'];
  srcImageUrl: Scalars['String']['output'];
  srcThumbnailUrl: Scalars['String']['output'];
  state: SparkState;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  userId: Scalars['ID']['output'];
};

export type SparkOutpaintDeleteAllResponse = {
  __typename?: 'SparkOutpaintDeleteAllResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type SparkOutpaintDeleteInput = {
  id: Scalars['ID']['input'];
};

export type SparkOutpaintDeleteResponse = {
  __typename?: 'SparkOutpaintDeleteResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type SparkOutpaintGetInput = {
  id: Scalars['ID']['input'];
};

export type SparkOutpaintGetResponse = {
  __typename?: 'SparkOutpaintGetResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  outpaint?: Maybe<SparkOutpaint>;
};

export type SparkOutpaintImageInput = {
  imageUrl: Scalars['String']['input'];
  increaseDown: Scalars['Int']['input'];
  increaseLeft: Scalars['Int']['input'];
  increaseRight: Scalars['Int']['input'];
  increaseUp: Scalars['Int']['input'];
  prompt: Scalars['String']['input'];
};

export type SparkOutpaintImageResponse = {
  __typename?: 'SparkOutpaintImageResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  outpaint?: Maybe<SparkOutpaint>;
};

export type SparkOutpaintListInput = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
};

export type SparkOutpaintListPagination = {
  __typename?: 'SparkOutpaintListPagination';
  currentPage: Scalars['Int']['output'];
  itemsPerPage: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type SparkOutpaintListResponse = {
  __typename?: 'SparkOutpaintListResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  list?: Maybe<Array<SparkOutpaint>>;
  pagination: SparkOutpaintListPagination;
};

export type SparkOutpaintRegisterInput = {
  ids: Array<Scalars['String']['input']>;
};

export type SparkRegisterArtResponse = {
  __typename?: 'SparkRegisterArtResponse';
  arts?: Maybe<Array<SparkArt>>;
  errors?: Maybe<Array<ErrorMessage>>;
};

export type SparkRegisterBackgroundRemovedResponse = {
  __typename?: 'SparkRegisterBackgroundRemovedResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  removedBackgrounds?: Maybe<Array<SparkRemoveBackgroundImage>>;
};

export type SparkRegisterCrystallineResponse = {
  __typename?: 'SparkRegisterCrystallineResponse';
  crystallines?: Maybe<Array<SparkCrystalline>>;
  errors?: Maybe<Array<ErrorMessage>>;
};

export type SparkRegisterImageMixResponse = {
  __typename?: 'SparkRegisterImageMixResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  imagemix?: Maybe<Array<SparkImageMix>>;
};

export type SparkRegisterInpaintResponse = {
  __typename?: 'SparkRegisterInpaintResponse';
  Inpaints?: Maybe<Array<SparkInpaint>>;
  errors?: Maybe<Array<ErrorMessage>>;
};

export type SparkRegisterOutpaintResponse = {
  __typename?: 'SparkRegisterOutpaintResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  outpaints?: Maybe<Array<SparkOutpaint>>;
};

export type SparkRemoveBackgroundGetInput = {
  id: Scalars['String']['input'];
};

export type SparkRemoveBackgroundGetResponse = {
  __typename?: 'SparkRemoveBackgroundGetResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  removedBackground?: Maybe<SparkRemoveBackgroundImage>;
};

export type SparkRemoveBackgroundImage = {
  __typename?: 'SparkRemoveBackgroundImage';
  generatedImagelUrl?: Maybe<Scalars['String']['output']>;
  generatedThumbnailUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  sourceImageUrl?: Maybe<Scalars['String']['output']>;
  sourceThumbnailUrl?: Maybe<Scalars['String']['output']>;
  state: SparkState;
};

export type SparkRemoveBackgroundInput = {
  imageUrl: Scalars['String']['input'];
};

export type SparkRemoveBackgroundResponse = {
  __typename?: 'SparkRemoveBackgroundResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  removedBackground?: Maybe<SparkRemoveBackgroundImage>;
};

export type SparkRemovedBackgroundCancelInput = {
  id: Scalars['String']['input'];
};

export type SparkRemovedBackgroundCancelResponse = {
  __typename?: 'SparkRemovedBackgroundCancelResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type SparkRemovedBackgroundDeleteAllResponse = {
  __typename?: 'SparkRemovedBackgroundDeleteAllResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type SparkRemovedBackgroundDeleteInput = {
  id: Scalars['ID']['input'];
};

export type SparkRemovedBackgroundDeleteResponse = {
  __typename?: 'SparkRemovedBackgroundDeleteResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type SparkRemovedBackgroundListInput = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  itemsPerPage?: InputMaybe<Scalars['Int']['input']>;
};

export type SparkRemovedBackgroundListPagination = {
  __typename?: 'SparkRemovedBackgroundListPagination';
  currentPage: Scalars['Int']['output'];
  itemsPerPage: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type SparkRemovedBackgroundListResponse = {
  __typename?: 'SparkRemovedBackgroundListResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  list?: Maybe<Array<SparkRemoveBackgroundImage>>;
  pagination?: Maybe<SparkRemovedBackgroundListPagination>;
};

export type SparkRemovedBackgroundUploadsCreateSignedUrlInput = {
  uploadFile: Scalars['String']['input'];
};

export type SparkRemovedBackgroundUploadsCreateSignedUrlResponse = {
  __typename?: 'SparkRemovedBackgroundUploadsCreateSignedUrlResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  id: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

export enum SparkState {
  Cancelled = 'cancelled',
  Failed = 'failed',
  Generated = 'generated',
  Requested = 'requested'
}

export type SparkUploadsCreateSignedUrlInput = {
  uploadFile: Scalars['String']['input'];
};

export type SparkUploadsCreateSignedUrlResponse = {
  __typename?: 'SparkUploadsCreateSignedUrlResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  id: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

export type StatisticsRecordStudioEventInput = {
  eventType: StudioEventType;
};

export type StatisticsRecordStudioEventResponse = {
  __typename?: 'StatisticsRecordStudioEventResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type StatusMessage = {
  __typename?: 'StatusMessage';
  message: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export enum StatusType {
  Cancelled = 'cancelled',
  Completed = 'completed',
  Deleted = 'deleted',
  Failed = 'failed',
  Pending = 'pending',
  Unknown = 'unknown'
}

export type StorageEnableOveragesInput = {
  enableOverages: Scalars['Boolean']['input'];
  teamId: Scalars['String']['input'];
};

export type StorageEnableOveragesResponse = {
  __typename?: 'StorageEnableOveragesResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  storage?: Maybe<StorageInfo>;
};

export type StorageInfo = {
  __typename?: 'StorageInfo';
  available: StorageSize;
  blocks?: Maybe<BlocksRange>;
  enableOverages: Scalars['Boolean']['output'];
  maxFileSizeAllowed?: Maybe<StorageSize>;
  total: StorageSize;
  used: StorageSize;
};

export type StorageInfoResponse = {
  __typename?: 'StorageInfoResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  storage?: Maybe<StorageInfo>;
};

export type StorageSize = {
  __typename?: 'StorageSize';
  bytes: Scalars['Int']['output'];
  humanized: Scalars['String']['output'];
};

export type StorageUpdateSizeInput = {
  blocks: Scalars['Int']['input'];
  teamId: Scalars['String']['input'];
};

export type StorageUpdateSizeResponse = {
  __typename?: 'StorageUpdateSizeResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  storage?: Maybe<StorageInfo>;
};

export enum StudioEventType {
  AppOnboarding = 'appOnboarding',
  UserLogin = 'userLogin'
}

export enum StudioFolderType {
  BackgroundRemover = 'backgroundRemover',
  Unspecified = 'unspecified',
  User = 'user',
  Vectorizer = 'vectorizer'
}

export type StudioProject = {
  __typename?: 'StudioProject';
  activeRevision: StudioProjectRevision;
  createTime: Scalars['String']['output'];
  deleteTime: Scalars['String']['output'];
  folderId?: Maybe<Scalars['ID']['output']>;
  id: Scalars['ID']['output'];
  lastViewTime: Scalars['String']['output'];
  name: Scalars['String']['output'];
  owner: PublicUserProfile;
  /** @deprecated use owner instead */
  projectOwner?: Maybe<PublicUserProfile>;
  restoreTime: Scalars['String']['output'];
  team?: Maybe<Team>;
  templateId?: Maybe<Scalars['ID']['output']>;
  templateOrigin?: Maybe<ProjectTemplateOrigin>;
  updateTime: Scalars['String']['output'];
  userPermissions: Array<UserProjectPermission>;
};

export type StudioProjectComment = {
  __typename?: 'StudioProjectComment';
  id: Scalars['ID']['output'];
  message: Scalars['String']['output'];
  owner: Scalars['String']['output'];
  parentCommentId?: Maybe<Scalars['ID']['output']>;
  projectId: Scalars['String']['output'];
  status: StudioProjectCommentStatus;
  timestamp: Scalars['String']['output'];
  tool: StudioProjectCommentTool;
  usersTagged?: Maybe<Array<Scalars['String']['output']>>;
  usersToNotify?: Maybe<Array<Scalars['String']['output']>>;
  xCoordinate: Scalars['Int']['output'];
  yCoordinate: Scalars['Int']['output'];
};

export enum StudioProjectCommentStatus {
  Done = 'done',
  Open = 'open',
  Unspecified = 'unspecified'
}

export enum StudioProjectCommentTool {
  Board = 'board',
  Project = 'project',
  Unspecified = 'unspecified'
}

export type StudioProjectCreateFolderInput = {
  name: Scalars['String']['input'];
  parentFolderId?: InputMaybe<Scalars['ID']['input']>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type StudioProjectCreateFolderResponse = {
  __typename?: 'StudioProjectCreateFolderResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  folder?: Maybe<StudioProjectFolder>;
};

export type StudioProjectDeleteProjectInput = {
  id: Scalars['ID']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type StudioProjectDeleteProjectResponse = {
  __typename?: 'StudioProjectDeleteProjectResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type StudioProjectDownloadAssetsInput = {
  ids: Array<Scalars['ID']['input']>;
  projectId?: InputMaybe<Scalars['ID']['input']>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type StudioProjectDownloadAssetsResponse = {
  __typename?: 'StudioProjectDownloadAssetsResponse';
  assets: Array<AssetDownloadResponse>;
  errors: Array<ErrorMessage>;
};

export type StudioProjectEmptyTrashInput = {
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type StudioProjectEmptyTrashResponse = {
  __typename?: 'StudioProjectEmptyTrashResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type StudioProjectFolder = {
  __typename?: 'StudioProjectFolder';
  collaborators: Array<PublicUserProfile>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  folders: Array<StudioProjectFolder>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  owner: PublicUserProfile;
  parentFolderId?: Maybe<Scalars['ID']['output']>;
  projectCount?: Maybe<Scalars['Int']['output']>;
  projects: Array<StudioProject>;
  team?: Maybe<Team>;
  /** @deprecated use team instead */
  teamId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userPermissions: Array<UserProjectPermission>;
};

export type StudioProjectFolderDeleteInput = {
  folderId: Scalars['ID']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type StudioProjectFolderDeleteResponse = {
  __typename?: 'StudioProjectFolderDeleteResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type StudioProjectMoveProjectsInput = {
  folderId?: InputMaybe<Scalars['ID']['input']>;
  projectIds: Array<Scalars['ID']['input']>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type StudioProjectMoveProjectsResponse = {
  __typename?: 'StudioProjectMoveProjectsResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type StudioProjectOptimizeProjectInput = {
  flip?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  ignoreBackground?: InputMaybe<Scalars['Boolean']['input']>;
  returnFileType?: InputMaybe<Scalars['String']['input']>;
  team_id?: InputMaybe<Scalars['String']['input']>;
};

export type StudioProjectOptimizeProjectResponse = {
  __typename?: 'StudioProjectOptimizeProjectResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
  url?: Maybe<Scalars['String']['output']>;
};

export type StudioProjectRestoreProjectInput = {
  id: Scalars['ID']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type StudioProjectRestoreProjectResponse = {
  __typename?: 'StudioProjectRestoreProjectResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type StudioProjectRevision = {
  __typename?: 'StudioProjectRevision';
  createTime: Scalars['String']['output'];
  editor: ProjectEditor;
  fileUrl: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  isAutoSave: Scalars['Boolean']['output'];
  projectId: Scalars['String']['output'];
  projectName: Scalars['String']['output'];
  teamId?: Maybe<Scalars['String']['output']>;
  thumbnailLastUpdate?: Maybe<Scalars['String']['output']>;
  thumbnailUrl: Scalars['String']['output'];
  userId?: Maybe<Scalars['String']['output']>;
};

export type StudioProjectSharingLink = {
  __typename?: 'StudioProjectSharingLink';
  defaultAccess: StudioProjectSharingLinkAccess;
  id: Scalars['ID']['output'];
  project: StudioProject;
  url: Scalars['String']['output'];
  usersWithAccess: Array<StudioProjectSharingUserAccess>;
};

export enum StudioProjectSharingLinkAccess {
  CanEdit = 'canEdit',
  CanView = 'canView',
  NoAccess = 'noAccess'
}

export type StudioProjectSharingUserAccess = {
  __typename?: 'StudioProjectSharingUserAccess';
  access: StudioProjectSharingLinkAccess;
  user: User;
};

export enum StudioProjectUpdateFields {
  Content = 'content',
  Editor = 'editor',
  Name = 'name',
  TemplateId = 'templateId',
  TemplateOrigin = 'templateOrigin',
  Thumbnail = 'thumbnail'
}

export type StudioProjectsAddMemberInput = {
  email: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  role: Role;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type StudioProjectsAddMemberResponse = {
  __typename?: 'StudioProjectsAddMemberResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  status?: Maybe<InvitationStatus>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type StudioProjectsAddUserToProjectSharingLinkInput = {
  access: StudioProjectSharingLinkAccess;
  linkId: Scalars['ID']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type StudioProjectsAddUserToProjectSharingLinkResponse = {
  __typename?: 'StudioProjectsAddUserToProjectSharingLinkResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type StudioProjectsApproveInviteInput = {
  invitationCode: Scalars['String']['input'];
  invitationID?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['String']['input'];
};

export type StudioProjectsApproveInviteResponse = {
  __typename?: 'StudioProjectsApproveInviteResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type StudioProjectsApproveProjectMemberUpdateInput = {
  approvalId?: InputMaybe<Scalars['String']['input']>;
  invitationCode: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type StudioProjectsApproveProjectMemberUpdateResponse = {
  __typename?: 'StudioProjectsApproveProjectMemberUpdateResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type StudioProjectsChangeUserRoleInput = {
  projectId: Scalars['String']['input'];
  role: Role;
  teamId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type StudioProjectsChangeUserRoleResponse = {
  __typename?: 'StudioProjectsChangeUserRoleResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  status?: Maybe<RoleChangeStatus>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type StudioProjectsConfirmInviteInput = {
  invitationCode: Scalars['String']['input'];
  inviteId?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['String']['input'];
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type StudioProjectsConfirmInviteResponse = {
  __typename?: 'StudioProjectsConfirmInviteResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type StudioProjectsCreateFromTemplateInput = {
  teamId?: InputMaybe<Scalars['String']['input']>;
  templateId: Scalars['String']['input'];
};

export type StudioProjectsCreateFromTemplateResponse = {
  __typename?: 'StudioProjectsCreateFromTemplateResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  project?: Maybe<StudioProject>;
};

export type StudioProjectsCreateProjectCommentInput = {
  message: Scalars['String']['input'];
  parentCommentId?: InputMaybe<Scalars['ID']['input']>;
  projectId: Scalars['String']['input'];
  tool: StudioProjectCommentTool;
  usersTagged?: InputMaybe<Array<Scalars['String']['input']>>;
  usersToNotify?: InputMaybe<Array<Scalars['String']['input']>>;
  xCoordinate: Scalars['Int']['input'];
  yCoordinate: Scalars['Int']['input'];
};

export type StudioProjectsCreateProjectCommentResponse = {
  __typename?: 'StudioProjectsCreateProjectCommentResponse';
  comment?: Maybe<StudioProjectComment>;
  errors?: Maybe<Array<ErrorMessage>>;
};

export type StudioProjectsCreateProjectInput = {
  autoSave?: InputMaybe<Scalars['Boolean']['input']>;
  content: Scalars['String']['input'];
  editor?: InputMaybe<ProjectEditor>;
  folderId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
  templateOrigin?: InputMaybe<ProjectTemplateOrigin>;
};

export type StudioProjectsCreateProjectResponse = {
  __typename?: 'StudioProjectsCreateProjectResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  project?: Maybe<StudioProject>;
};

export type StudioProjectsCreateProjectSharingLinkInput = {
  defaultAccess: StudioProjectSharingLinkAccess;
  projectId: Scalars['ID']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type StudioProjectsCreateProjectSharingLinkResponse = {
  __typename?: 'StudioProjectsCreateProjectSharingLinkResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  projectSharingLink?: Maybe<StudioProjectSharingLink>;
};

export type StudioProjectsFolderGetInput = {
  folderId: Scalars['ID']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type StudioProjectsFolderGetResponse = {
  __typename?: 'StudioProjectsFolderGetResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  folder?: Maybe<StudioProjectFolder>;
};

export type StudioProjectsFolderRenameInput = {
  folderId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type StudioProjectsFolderRenameResponse = {
  __typename?: 'StudioProjectsFolderRenameResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type StudioProjectsFolderRestoreInput = {
  folderId: Scalars['ID']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type StudioProjectsFolderRestoreResponse = {
  __typename?: 'StudioProjectsFolderRestoreResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type StudioProjectsGetProjectCommentsInput = {
  projectId: Scalars['String']['input'];
};

export type StudioProjectsGetProjectCommentsResponse = {
  __typename?: 'StudioProjectsGetProjectCommentsResponse';
  comments?: Maybe<Array<StudioProjectComment>>;
  errors?: Maybe<Array<ErrorMessage>>;
};

export type StudioProjectsGetProjectResponse = {
  __typename?: 'StudioProjectsGetProjectResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  project?: Maybe<StudioProject>;
};

export type StudioProjectsGetTeamInput = {
  projectId: Scalars['String']['input'];
};

export type StudioProjectsGetTeamResponse = {
  __typename?: 'StudioProjectsGetTeamResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  team?: Maybe<Team>;
};

export type StudioProjectsListItemsInTrashInput = {
  folderId?: InputMaybe<Scalars['ID']['input']>;
  limit: Scalars['Int']['input'];
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  sort: ProjectSort;
  teamId?: InputMaybe<Scalars['ID']['input']>;
  timeFrame?: InputMaybe<TimeFrame>;
};

export type StudioProjectsListItemsInTrashResponse = {
  __typename?: 'StudioProjectsListItemsInTrashResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  folders?: Maybe<Array<StudioProjectFolder>>;
  projects?: Maybe<Array<StudioProject>>;
  templates?: Maybe<Array<Template>>;
};

export type StudioProjectsListMostRecentInput = {
  limit: Scalars['Int']['input'];
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type StudioProjectsListMostRecentResponse = {
  __typename?: 'StudioProjectsListMostRecentResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  projects?: Maybe<Array<StudioProject>>;
};

export type StudioProjectsListProjectsInTrashInput = {
  limit: Scalars['Int']['input'];
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  sort: ProjectSort;
  teamId?: InputMaybe<Scalars['ID']['input']>;
  timeFrame?: InputMaybe<TimeFrame>;
};

export type StudioProjectsListProjectsInTrashResponse = {
  __typename?: 'StudioProjectsListProjectsInTrashResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  projects?: Maybe<Array<StudioProject>>;
};

export type StudioProjectsListProjectsInput = {
  filterEmpty?: InputMaybe<Scalars['Boolean']['input']>;
  folderId?: InputMaybe<Scalars['ID']['input']>;
  limit: Scalars['Int']['input'];
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  sort: ProjectSort;
  teamId?: InputMaybe<Scalars['ID']['input']>;
  timeFrame?: InputMaybe<TimeFrame>;
};

export type StudioProjectsListProjectsResponse = {
  __typename?: 'StudioProjectsListProjectsResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  folders?: Maybe<Array<StudioProjectFolder>>;
  projects?: Maybe<Array<StudioProject>>;
};

export type StudioProjectsListProjectsSharedWithMeInput = {
  currentPage?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  ownedBy?: InputMaybe<OwnedBy>;
};

export type StudioProjectsListProjectsSharedWithMeResponse = {
  __typename?: 'StudioProjectsListProjectsSharedWithMeResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  pagination?: Maybe<SharedProjectsPagination>;
  teamsProjects?: Maybe<Array<StudioProjectsTeamSharedProjects>>;
  userProjects?: Maybe<Array<StudioProjectsUserSharedProjects>>;
};

export type StudioProjectsMarkProjectCommentDoneInput = {
  commentId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
};

export type StudioProjectsMarkProjectCommentDoneResponse = {
  __typename?: 'StudioProjectsMarkProjectCommentDoneResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type StudioProjectsRemoveUserInput = {
  projectId: Scalars['String']['input'];
  teamId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type StudioProjectsRemoveUserResponse = {
  __typename?: 'StudioProjectsRemoveUserResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type StudioProjectsTeamSharedProjects = {
  __typename?: 'StudioProjectsTeamSharedProjects';
  projects: Array<StudioProject>;
  team: Team;
};

export type StudioProjectsUpdateProjectInput = {
  autoSave?: InputMaybe<Scalars['Boolean']['input']>;
  content?: InputMaybe<Scalars['String']['input']>;
  editor?: InputMaybe<ProjectEditor>;
  name?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['ID']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
  templateId?: InputMaybe<Scalars['ID']['input']>;
  templateOrigin?: InputMaybe<ProjectTemplateOrigin>;
  thumbnail?: InputMaybe<Scalars['String']['input']>;
  updateFields: Array<StudioProjectUpdateFields>;
};

export type StudioProjectsUpdateProjectLastViewInput = {
  projectId: Scalars['ID']['input'];
};

export type StudioProjectsUpdateProjectLastViewResponse = {
  __typename?: 'StudioProjectsUpdateProjectLastViewResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type StudioProjectsUpdateProjectResponse = {
  __typename?: 'StudioProjectsUpdateProjectResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type StudioProjectsUserSharedProjects = {
  __typename?: 'StudioProjectsUserSharedProjects';
  owner: PublicUserProfile;
  projects: Array<StudioProject>;
};

export type StudioTemplateDeleteInput = {
  teamId?: InputMaybe<Scalars['String']['input']>;
  templateId: Scalars['String']['input'];
};

export type StudioTemplateDeleteResponse = {
  __typename?: 'StudioTemplateDeleteResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type StudioTemplateListResponse = {
  __typename?: 'StudioTemplateListResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  pagination?: Maybe<StudioTemplatesPagination>;
  templates?: Maybe<Array<Template>>;
};

export type StudioTemplatePublishFromProjectInput = {
  projectId: Scalars['String']['input'];
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type StudioTemplatePublishFromProjectResponse = {
  __typename?: 'StudioTemplatePublishFromProjectResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type StudioTemplateRestoreInput = {
  teamId?: InputMaybe<Scalars['String']['input']>;
  templateId: Scalars['String']['input'];
};

export type StudioTemplateRestoreResponse = {
  __typename?: 'StudioTemplateRestoreResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type StudioTemplatesListInput = {
  currentPage: Scalars['Int']['input'];
  itemsPerPage: Scalars['Int']['input'];
  searchTerm?: InputMaybe<Scalars['String']['input']>;
  teamId: Scalars['String']['input'];
};

export type StudioTemplatesPagination = {
  __typename?: 'StudioTemplatesPagination';
  currentPage: Scalars['Int']['output'];
  itemsPerPage: Scalars['Int']['output'];
  totalPages: Scalars['Int']['output'];
};

export type StudioUploadsAddTagToFilesInput = {
  fileIds: Array<Scalars['ID']['input']>;
  tagId: Scalars['ID']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type StudioUploadsAddTagToFilesResponse = {
  __typename?: 'StudioUploadsAddTagToFilesResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export enum StudioUploadsAssetType {
  AssetTypeBackground = 'ASSET_TYPE_BACKGROUND',
  AssetTypeElement = 'ASSET_TYPE_ELEMENT',
  AssetTypeFile = 'ASSET_TYPE_FILE',
  AssetTypeFont = 'ASSET_TYPE_FONT',
  AssetTypeIcon = 'ASSET_TYPE_ICON',
  AssetTypePattern = 'ASSET_TYPE_PATTERN',
  AssetTypePhoto = 'ASSET_TYPE_PHOTO',
  AssetTypeUnspecified = 'ASSET_TYPE_UNSPECIFIED'
}

export type StudioUploadsCreateFolderInput = {
  name: Scalars['String']['input'];
  parent_folder_id?: InputMaybe<Scalars['ID']['input']>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type StudioUploadsCreateFolderResponse = {
  __typename?: 'StudioUploadsCreateFolderResponse';
  created?: Maybe<StudioUploadsFolder>;
  errors?: Maybe<Array<ErrorMessage>>;
};

export type StudioUploadsCreateSignedDownloadUrlInput = {
  teamId?: InputMaybe<Scalars['String']['input']>;
  uploadId: Scalars['String']['input'];
};

export type StudioUploadsCreateSignedDownloadUrlResponse = {
  __typename?: 'StudioUploadsCreateSignedDownloadUrlResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  signedUrl: Scalars['String']['output'];
};

export type StudioUploadsCreateSignedUrlInput = {
  folderId?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  uploadFile: Scalars['String']['input'];
};

export type StudioUploadsCreateSignedUrlResponse = {
  __typename?: 'StudioUploadsCreateSignedUrlResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  signedUrl: Scalars['String']['output'];
};

export type StudioUploadsCreateTagInput = {
  color: Scalars['String']['input'];
  label: Scalars['String']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type StudioUploadsCreateTagResponse = {
  __typename?: 'StudioUploadsCreateTagResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  tag: StudioUploadsTag;
};

export type StudioUploadsDeleteFilesInput = {
  fileIds: Array<Scalars['ID']['input']>;
  folderId: Scalars['ID']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type StudioUploadsDeleteFilesResponse = {
  __typename?: 'StudioUploadsDeleteFilesResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type StudioUploadsDeleteFolderInput = {
  folderId: Scalars['ID']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type StudioUploadsDeleteFolderResponse = {
  __typename?: 'StudioUploadsDeleteFolderResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type StudioUploadsDeleteTagInput = {
  id: Scalars['ID']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type StudioUploadsDeleteTagResponse = {
  __typename?: 'StudioUploadsDeleteTagResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type StudioUploadsFile = {
  __typename?: 'StudioUploadsFile';
  createdAt: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['String']['output']>;
  folderId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  type: StudioUploadsAssetType;
  updatedAt?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
};

export type StudioUploadsFolder = {
  __typename?: 'StudioUploadsFolder';
  createdAt: Scalars['String']['output'];
  files: Array<StudioUploadsFile>;
  folderType: StudioFolderType;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  parentFolderId?: Maybe<Scalars['ID']['output']>;
  subfolders: Array<StudioUploadsSubFolder>;
  teamId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type StudioUploadsGetFolderInput = {
  folderId?: InputMaybe<Scalars['ID']['input']>;
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type StudioUploadsGetFolderResponse = {
  __typename?: 'StudioUploadsGetFolderResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  folder?: Maybe<StudioUploadsFolder>;
};

export type StudioUploadsGetTagsForUploadsInput = {
  teamId?: InputMaybe<Scalars['ID']['input']>;
  uploadId: Scalars['ID']['input'];
};

export type StudioUploadsGetTagsForUploadsResponse = {
  __typename?: 'StudioUploadsGetTagsForUploadsResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  tags: Array<StudioUploadsTag>;
};

export type StudioUploadsMoveFilesInput = {
  fileIds: Array<Scalars['ID']['input']>;
  folderId: Scalars['ID']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type StudioUploadsMoveFilesResponse = {
  __typename?: 'StudioUploadsMoveFilesResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  moved: Scalars['Boolean']['output'];
};

export type StudioUploadsRemoveTagFromFileInput = {
  fileIds: Array<Scalars['ID']['input']>;
  tagId: Scalars['ID']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type StudioUploadsRemoveTagFromFileResponse = {
  __typename?: 'StudioUploadsRemoveTagFromFileResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type StudioUploadsRenameFolderInput = {
  folderId: Scalars['ID']['input'];
  name: Scalars['String']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type StudioUploadsRenameFolderResponse = {
  __typename?: 'StudioUploadsRenameFolderResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  renamed: Scalars['Boolean']['output'];
};

export type StudioUploadsSearchFilesInput = {
  folderId?: InputMaybe<Scalars['ID']['input']>;
  search: Scalars['String']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type StudioUploadsSearchFilesResponse = {
  __typename?: 'StudioUploadsSearchFilesResponse';
  errors: Array<ErrorMessage>;
  files: Array<StudioUploadsFile>;
  folders: Array<StudioUploadsFolder>;
  tags: Array<StudioUploadsTag>;
};

export type StudioUploadsSubFolder = {
  __typename?: 'StudioUploadsSubFolder';
  folderType: StudioFolderType;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  totalFiles: Scalars['Int']['output'];
};

export type StudioUploadsTag = {
  __typename?: 'StudioUploadsTag';
  color: Scalars['String']['output'];
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  label: Scalars['String']['output'];
  teamId?: Maybe<Scalars['ID']['output']>;
  updatedAt?: Maybe<Scalars['String']['output']>;
};

export type StudioUploadsTagUpdate = {
  color?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
};

export enum StudioUploadsTagUpdateField {
  Color = 'color',
  Label = 'label'
}

export type StudioUploadsTagsInput = {
  teamId?: InputMaybe<Scalars['ID']['input']>;
};

export type StudioUploadsTagsResponse = {
  __typename?: 'StudioUploadsTagsResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  tags: Array<StudioUploadsTag>;
};

export type StudioUploadsUpdateTagInput = {
  id: Scalars['ID']['input'];
  teamId?: InputMaybe<Scalars['ID']['input']>;
  updateField: StudioUploadsTagUpdateField;
  updateTag: StudioUploadsTagUpdate;
};

export type StudioUploadsUpdateTagResponse = {
  __typename?: 'StudioUploadsUpdateTagResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type StudioUploadsUploadUpdatedInput = {
  id: Scalars['ID']['input'];
};

export type StudioUploadsUploadUpdatedResponse = {
  __typename?: 'StudioUploadsUploadUpdatedResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  upload?: Maybe<StudioUploadsFile>;
};

export type Subscription = {
  __typename?: 'Subscription';
  designReviewUpdates: DesignReview;
  flowActive: Scalars['Boolean']['output'];
  flowFeedStatusUpdated: FlowStatus;
  flowFeedUpdated: Flow;
  flowLiked: Scalars['Boolean']['output'];
  flowMostLikedFlowsUpdated: Array<Scalars['String']['output']>;
  flowMostViewedFlowsUpdated: Array<Scalars['String']['output']>;
  flowOnlineCount: Scalars['Int']['output'];
  flowQueueUpdated: FlowQueue;
  flowStatusUpdated: FlowStatus;
  flowUpdated: Flow;
  flowViewers: FlowViewersResponse;
  flowsFlowTipped: FlowsFlowTippedResponse;
  fontsUploadUpdated: FontsUploadUpdatedResponse;
  healthz: HealthZResponse;
  /** @deprecated Use flowMostLikedFlowsUpdated */
  mostLikedFlowsUpdated: Array<Scalars['String']['output']>;
  /** @deprecated Use flowMostViewedFlowsUpdated */
  mostViewedFlowsUpdated: Array<Scalars['String']['output']>;
  sparkRemovedBackgroundUpdates: SparkRemoveBackgroundImage;
  studioUploadsUploadUpdated: StudioUploadsUploadUpdatedResponse;
  transfersTransferUpdates: Transfer;
  userTipped: Scalars['Boolean']['output'];
  vectorizerImageUpdates: VectorizerImage;
  /** @deprecated Use flowViewers */
  viewers: FlowViewersResponse;
};


export type SubscriptionDesignReviewUpdatesArgs = {
  id: Scalars['String']['input'];
};


export type SubscriptionFlowActiveArgs = {
  flowID: Scalars['ID']['input'];
};


export type SubscriptionFlowLikedArgs = {
  flowId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionFlowQueueUpdatedArgs = {
  flowID?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionFlowStatusUpdatedArgs = {
  flowID: Scalars['ID']['input'];
};


export type SubscriptionFlowUpdatedArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionFlowViewersArgs = {
  flowId: Scalars['String']['input'];
};


export type SubscriptionFlowsFlowTippedArgs = {
  input: FlowFlowsTippedInput;
};


export type SubscriptionFontsUploadUpdatedArgs = {
  input: FontsUploadUpdatedInput;
};


export type SubscriptionStudioUploadsUploadUpdatedArgs = {
  input: StudioUploadsUploadUpdatedInput;
};


export type SubscriptionTransfersTransferUpdatesArgs = {
  id: Scalars['String']['input'];
};


export type SubscriptionUserTippedArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type SubscriptionVectorizerImageUpdatesArgs = {
  id: Scalars['String']['input'];
};


export type SubscriptionViewersArgs = {
  flowId: Scalars['String']['input'];
};

export enum SubscriptionType {
  AllAccessMonthly = 'allAccessMonthly',
  AllAccessMonthlyFreeTrial = 'allAccessMonthlyFreeTrial',
  AllAccessYearly = 'allAccessYearly',
  AllAccessYearlyFreeTrial = 'allAccessYearlyFreeTrial',
  AllAccessYearlyPromo = 'allAccessYearlyPromo'
}

export enum SubscriptionTypeUpgrade {
  AllAccessYearly = 'allAccessYearly',
  AllAccessYearlyPromo = 'allAccessYearlyPromo'
}

export type Subscriptions = {
  __typename?: 'Subscriptions';
  allAccess?: Maybe<Scalars['Boolean']['output']>;
  classes?: Maybe<Scalars['Boolean']['output']>;
  crafts?: Maybe<Scalars['Boolean']['output']>;
  embroidery?: Maybe<Scalars['Boolean']['output']>;
  fonts?: Maybe<Scalars['Boolean']['output']>;
  graphics?: Maybe<Scalars['Boolean']['output']>;
};

export type Team = {
  __typename?: 'Team';
  avatarColour?: Maybe<Scalars['String']['output']>;
  avatarInitials?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  members: Array<TeamMember>;
  name: Scalars['String']['output'];
  owner: PublicUserProfile;
  pendingInvitations?: Maybe<Array<InvitedTeamMember>>;
  scheduledDeletionTime?: Maybe<Scalars['String']['output']>;
  status: TeamStatus;
};

export type TeamMember = {
  __typename?: 'TeamMember';
  member: PublicUserProfile;
  role: Role;
};

export enum TeamStatus {
  Deactivated = 'Deactivated',
  Free = 'Free',
  Premium = 'Premium',
  Suspended = 'Suspended'
}

export type TeamsApproveAdminRequestInput = {
  invitationCode: Scalars['String']['input'];
  invitationId?: InputMaybe<Scalars['String']['input']>;
  teamId: Scalars['String']['input'];
};

export type TeamsApproveAdminRequestResponse = {
  __typename?: 'TeamsApproveAdminRequestResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type TeamsApproveNewTeamMemberInput = {
  invitationCode: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
};

export type TeamsApproveNewTeamMemberResponse = {
  __typename?: 'TeamsApproveNewTeamMemberResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type TeamsApproveTeamMemberRoleChangeInput = {
  invitationCode: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
};

export type TeamsApproveTeamMemberRoleChangeResponse = {
  __typename?: 'TeamsApproveTeamMemberRoleChangeResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type TeamsCancelInviteInput = {
  invitationId: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
};

export type TeamsCancelInviteResponse = {
  __typename?: 'TeamsCancelInviteResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type TeamsChangeUserRoleInput = {
  role: Role;
  teamId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type TeamsChangeUserRoleResponse = {
  __typename?: 'TeamsChangeUserRoleResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  status?: Maybe<ChangeUserRoleStatus>;
};

export type TeamsConfirmInviteInput = {
  invitationCode: Scalars['String']['input'];
  teamId: Scalars['String']['input'];
};

export type TeamsConfirmInviteResponse = {
  __typename?: 'TeamsConfirmInviteResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type TeamsCreateTeamInput = {
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

export type TeamsCreateTeamResponse = {
  __typename?: 'TeamsCreateTeamResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  team?: Maybe<Team>;
};

export type TeamsDeactivateTeamInput = {
  teamId: Scalars['String']['input'];
};

export type TeamsDeactivateTeamResponse = {
  __typename?: 'TeamsDeactivateTeamResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type TeamsFetchOutstandingInvoiceUrlInput = {
  teamId: Scalars['String']['input'];
};

export type TeamsFetchOutstandingInvoiceUrlResponse = {
  __typename?: 'TeamsFetchOutstandingInvoiceUrlResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  hasOutstandingInvoice?: Maybe<Scalars['Boolean']['output']>;
  /** @deprecated Use `url` instead */
  invoiceUrl?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type TeamsFetchUpcomingInvoiceUrlInput = {
  teamId: Scalars['String']['input'];
};

export type TeamsFetchUpcomingInvoiceUrlResponse = {
  __typename?: 'TeamsFetchUpcomingInvoiceUrlResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  hasUpcomingInvoice?: Maybe<Scalars['Boolean']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export type TeamsGetCheckoutUrlInput = {
  cancelRedirectUrl?: InputMaybe<Scalars['String']['input']>;
  teamId: Scalars['String']['input'];
};

export type TeamsGetCheckoutUrlResponse = {
  __typename?: 'TeamsGetCheckoutURLResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  url?: Maybe<Scalars['String']['output']>;
};

export type TeamsGetTeamInput = {
  teamId: Scalars['String']['input'];
};

export type TeamsGetTeamResponse = {
  __typename?: 'TeamsGetTeamResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  team?: Maybe<Team>;
};

export type TeamsInviteUsersInput = {
  invitedUser?: InputMaybe<TeamsInvitedUser>;
  teamId: Scalars['String']['input'];
  users?: InputMaybe<Array<TeamsInvitedUser>>;
};

export type TeamsInviteUsersResponse = {
  __typename?: 'TeamsInviteUsersResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  status?: Maybe<InvitationStatus>;
};

export type TeamsInvitedUser = {
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  role: Role;
};

export type TeamsLeaveTeamInput = {
  teamId: Scalars['String']['input'];
};

export type TeamsLeaveTeamResponse = {
  __typename?: 'TeamsLeaveTeamResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type TeamsPreviewUpcomingCharges = {
  __typename?: 'TeamsPreviewUpcomingCharges';
  hasUpcomingCharges: Scalars['Boolean']['output'];
  nextBillingTime: Scalars['String']['output'];
  seatCharges: TeamsPreviewUpcomingSeatCharges;
  storageCharges: TeamsPreviewUpcomingStorageCharges;
};

export type TeamsPreviewUpcomingChargesInput = {
  teamId: Scalars['String']['input'];
};

export type TeamsPreviewUpcomingChargesResponse = {
  __typename?: 'TeamsPreviewUpcomingChargesResponse';
  charges?: Maybe<TeamsPreviewUpcomingCharges>;
  errors?: Maybe<Array<ErrorMessage>>;
};

export type TeamsPreviewUpcomingSeatCharges = {
  __typename?: 'TeamsPreviewUpcomingSeatCharges';
  freeSeats: Scalars['Int']['output'];
  paidSeats: Scalars['Int']['output'];
  totalSeatsUsed: Scalars['Int']['output'];
  unpaidSeats: Scalars['Int']['output'];
};

export type TeamsPreviewUpcomingStorageCharges = {
  __typename?: 'TeamsPreviewUpcomingStorageCharges';
  paidStorageBlocks: Scalars['Int']['output'];
  usedStorageBlocks: Scalars['Int']['output'];
  usedStorageGbs: Scalars['Int']['output'];
};

export type TeamsRemoveUsersInput = {
  teamId: Scalars['String']['input'];
  userIds: Array<Scalars['String']['input']>;
};

export type TeamsRemoveUsersResponse = {
  __typename?: 'TeamsRemoveUsersResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  removedUserIds: Array<Scalars['String']['output']>;
  teamId: Scalars['String']['output'];
};

export type TeamsResendInviteInput = {
  invitationId: Scalars['String']['input'];
  inviteId?: InputMaybe<Scalars['String']['input']>;
  teamId: Scalars['String']['input'];
};

export type TeamsResendInviteResponse = {
  __typename?: 'TeamsResendInviteResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type TeamsUpdateTeamInput = {
  avatarColour?: InputMaybe<Scalars['String']['input']>;
  avatarInitials?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  logoUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  teamId: Scalars['String']['input'];
};

export type TeamsUpdateTeamResponse = {
  __typename?: 'TeamsUpdateTeamResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
  team?: Maybe<Team>;
};

export type Template = {
  __typename?: 'Template';
  contentUrl: Scalars['String']['output'];
  createTime: Scalars['String']['output'];
  /** @deprecated Use createTime */
  createdAt: Scalars['String']['output'];
  createdBy: PublicUserProfile;
  deleteTime?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  team?: Maybe<Team>;
  thumbnailUrl: Scalars['String']['output'];
};

export type TemplateFormatImageOnly = {
  __typename?: 'TemplateFormatImageOnly';
  image: CampaignAsset;
};

export type TemplateFormatImagesAndLogo = {
  __typename?: 'TemplateFormatImagesAndLogo';
  image: CampaignAsset;
  logo: CampaignAsset;
  textResources: TextResources;
};

export type TemplateFormatImagesAndText = {
  __typename?: 'TemplateFormatImagesAndText';
  mainImage: CampaignAsset;
  secondaryImage: CampaignAsset;
  textResources: TextResources;
};

export type TemplateFormatVideoAndImage = {
  __typename?: 'TemplateFormatVideoAndImage';
  image: CampaignAsset;
  textResources: TextResources;
  video: CampaignAsset;
};

export type TemplateFormatVideoOnly = {
  __typename?: 'TemplateFormatVideoOnly';
  video: CampaignAsset;
};

export type TextResources = {
  __typename?: 'TextResources';
  ctaText?: Maybe<Scalars['String']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type TimeFrame = {
  dateField: DateField;
  dateRange: DateRange;
};

export type Transfer = {
  __typename?: 'Transfer';
  accessCode?: Maybe<Scalars['String']['output']>;
  canceledAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  expiredAt?: Maybe<Scalars['DateTime']['output']>;
  failedAt?: Maybe<Scalars['DateTime']['output']>;
  files: Array<Maybe<TransferFile>>;
  filesCount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  message?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<PublicUserProfile>;
  recipients: Array<Maybe<TransferRecipient>>;
  status: TransferStatus;
  subject: Scalars['String']['output'];
  team?: Maybe<Team>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  zipFile?: Maybe<Scalars['String']['output']>;
  zipSize: Scalars['Int']['output'];
};

export type TransferFile = {
  __typename?: 'TransferFile';
  createdAt: Scalars['DateTime']['output'];
  errorMessage?: Maybe<Scalars['String']['output']>;
  failedAt?: Maybe<Scalars['DateTime']['output']>;
  fileName: Scalars['String']['output'];
  filePath: Scalars['String']['output'];
  fileSize: Scalars['Int']['output'];
  fileType: Scalars['String']['output'];
  id: Scalars['String']['output'];
  transferId: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uploadStatus: UploadStatus;
};

export type TransferRecipient = {
  __typename?: 'TransferRecipient';
  createdAt: Scalars['DateTime']['output'];
  downloaded: Scalars['Boolean']['output'];
  downloadedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  transferAccessCode?: Maybe<Scalars['String']['output']>;
  transferId: Scalars['String']['output'];
  userInfo?: Maybe<UserInfo>;
};

export type TransferSort = {
  direction: SortDirection;
  field: TransferSortField;
};

export enum TransferSortField {
  CreatedAt = 'createdAt',
  Unknown = 'unknown'
}

export enum TransferStatus {
  Canceled = 'canceled',
  Completed = 'completed',
  Failed = 'failed',
  Initialized = 'initialized',
  Ready = 'ready',
  RequiresOtpVerification = 'requiresOTPVerification',
  Sending = 'sending',
  Unknown = 'unknown',
  Waiting = 'waiting'
}

export type TransfersAddRecipientsInput = {
  ownerEmail?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
  transferId: Scalars['String']['input'];
  userInfos: Array<UserInfoInput>;
};

export type TransfersAddRecipientsResponse = {
  __typename?: 'TransfersAddRecipientsResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  recipients?: Maybe<Array<TransferRecipient>>;
};

export type TransfersCancelInput = {
  id: Scalars['String']['input'];
  ownerEmail?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type TransfersCancelResponse = {
  __typename?: 'TransfersCancelResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type TransfersConfirmOwnerEmailInput = {
  transferId: Scalars['String']['input'];
  verificationCode: Scalars['String']['input'];
};

export type TransfersConfirmOwnerEmailResponse = {
  __typename?: 'TransfersConfirmOwnerEmailResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type TransfersCreateSignedDownloadUrlInput = {
  accessCode: Scalars['String']['input'];
  transferId: Scalars['String']['input'];
};

export type TransfersCreateSignedDownloadUrlResponse = {
  __typename?: 'TransfersCreateSignedDownloadUrlResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  signedUrl: Scalars['String']['output'];
};

export type TransfersDeleteInput = {
  id: Scalars['String']['input'];
  ownerEmail?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type TransfersDeleteResponse = {
  __typename?: 'TransfersDeleteResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export enum TransfersExpiryDuration {
  OneDay = 'ONE_DAY',
  OneMonth = 'ONE_MONTH',
  OneWeek = 'ONE_WEEK',
  OneYear = 'ONE_YEAR',
  TenMinutes = 'TEN_MINUTES',
  ThreeDays = 'THREE_DAYS'
}

export type TransfersGetTransferInput = {
  accessCode?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  ownerEmail?: InputMaybe<Scalars['String']['input']>;
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type TransfersGetTransferResponse = {
  __typename?: 'TransfersGetTransferResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  transfer?: Maybe<Transfer>;
};

export type TransfersInitiateTransferInput = {
  expiresAt?: InputMaybe<TransfersExpiryDuration>;
  filesCount: Scalars['Int']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  ownerEmail?: InputMaybe<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type TransfersInitiateTransferResponse = {
  __typename?: 'TransfersInitiateTransferResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  id?: Maybe<Scalars['String']['output']>;
};

export type TransfersInitiateTransferV2Input = {
  expiresAt?: InputMaybe<TransfersExpiryDuration>;
  filesCount: Scalars['Int']['input'];
  message?: InputMaybe<Scalars['String']['input']>;
  ownerEmail?: InputMaybe<Scalars['String']['input']>;
  subject: Scalars['String']['input'];
  teamId?: InputMaybe<Scalars['String']['input']>;
};

export type TransfersInitiateTransferV2Response = {
  __typename?: 'TransfersInitiateTransferV2Response';
  errors?: Maybe<Array<ErrorMessage>>;
  id?: Maybe<Scalars['String']['output']>;
  status?: Maybe<TransferStatus>;
};

export type TransfersVerifyOwnerEmailInput = {
  ownerEmail: Scalars['String']['input'];
  transferId: Scalars['String']['input'];
};

export type TransfersVerifyOwnerEmailResponse = {
  __typename?: 'TransfersVerifyOwnerEmailResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type TransformedPricesResponse = {
  __typename?: 'TransformedPricesResponse';
  basePrice: Scalars['Int']['output'];
  currencyPrice: Scalars['Int']['output'];
};

export type UndoCancelationBanner = {
  __typename?: 'UndoCancelationBanner';
  backgroundColor: Scalars['String']['output'];
  bannerAboveNav: Scalars['String']['output'];
  bannerAboveNavState: Scalars['String']['output'];
  button: UndoCancelationBannerButton;
  countdown: SiteBannerCountdown;
  subscriptionId: Scalars['String']['output'];
  text: Scalars['String']['output'];
  type: SiteBannerType;
};

export type UndoCancelationBannerButton = {
  __typename?: 'UndoCancelationBannerButton';
  backgroundColor: Scalars['String']['output'];
  hoverTextColor: Scalars['String']['output'];
  link?: Maybe<Scalars['String']['output']>;
  onClick: Scalars['String']['output'];
  text: Scalars['String']['output'];
  textColor: Scalars['String']['output'];
};

export type UpdateUserResponse = {
  __typename?: 'UpdateUserResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type UpgradeSubscriptionResult = {
  __typename?: 'UpgradeSubscriptionResult';
  payInvoiceUrl?: Maybe<Scalars['String']['output']>;
  paymentStatus: PaymentStatus;
};

export enum UploadStatus {
  Completed = 'completed',
  Failed = 'failed',
  Pending = 'pending',
  Started = 'started',
  Unknown = 'unknown'
}

export enum UploadsApp {
  Studio = 'studio',
  Vectorizer = 'vectorizer'
}

export type UploadsCreateSignedUrlInputV2 = {
  app: UploadsApp;
  fileName: Scalars['String']['input'];
};

export type UploadsCreateSignedUrlResponseV2 = {
  __typename?: 'UploadsCreateSignedUrlResponseV2';
  errors?: Maybe<Array<ErrorMessage>>;
  id: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

export type UpsellBanner = {
  __typename?: 'UpsellBanner';
  backgroundColor: Scalars['String']['output'];
  button: UpsellBannerButton;
  countdown: SiteBannerCountdown;
  text: Scalars['String']['output'];
  type: SiteBannerType;
};

export type UpsellBannerButton = {
  __typename?: 'UpsellBannerButton';
  backgroundColor: Scalars['String']['output'];
  hoverTextColor: Scalars['String']['output'];
  link: Scalars['String']['output'];
  text: Scalars['String']['output'];
  textColor: Scalars['String']['output'];
};

export type User = {
  __typename?: 'User';
  affiliateInfo?: Maybe<AffiliateInfo>;
  avatarUrl?: Maybe<Scalars['String']['output']>;
  billingDetails: BillingDetails;
  bio: Scalars['String']['output'];
  canSwitchVisibility: Scalars['Boolean']['output'];
  capabilities: Array<Scalars['String']['output']>;
  contentInterests: Array<Scalars['String']['output']>;
  craftingTypes: Array<Scalars['String']['output']>;
  currency: Currency;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  flags?: Maybe<UserFlags>;
  following: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  isEligibleForFreeTrial: Scalars['Boolean']['output'];
  isEligibleForYearlyUpgrade: Scalars['Boolean']['output'];
  isTemporary: Scalars['Boolean']['output'];
  language: Language;
  lastName?: Maybe<Scalars['String']['output']>;
  login?: Maybe<Scalars['String']['output']>;
  machineTypes: Array<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  registeredAt?: Maybe<Scalars['DateTime']['output']>;
  roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  slug?: Maybe<Scalars['String']['output']>;
  stripeCustomerId: Scalars['String']['output'];
  subscriptions?: Maybe<Subscriptions>;
  superSkill?: Maybe<Scalars['String']['output']>;
  teams: Array<Team>;
  url?: Maybe<Scalars['String']['output']>;
};

export type UserBalance = {
  __typename?: 'UserBalance';
  balance: Scalars['Int']['output'];
};

export type UserFlags = {
  __typename?: 'UserFlags';
  hasNewUserNewsletter?: Maybe<Scalars['Boolean']['output']>;
  identifyUser?: Maybe<Scalars['Boolean']['output']>;
  isCreativeFabrica?: Maybe<Scalars['Boolean']['output']>;
  isGAdsSignup?: Maybe<Scalars['Boolean']['output']>;
  isNew?: Maybe<Scalars['Boolean']['output']>;
  isOnHoldSubscriber?: Maybe<Scalars['Boolean']['output']>;
  isUserVerificationRequired?: Maybe<Scalars['Boolean']['output']>;
};

export type UserInfo = {
  __typename?: 'UserInfo';
  address?: Maybe<EmailAddress>;
  user?: Maybe<UserProfile>;
};

export type UserInfoInput = {
  address?: InputMaybe<EmailAddressInput>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type UserInput = {
  billingDetails: BillingDetailsInput;
  referredByAffiliateId?: InputMaybe<Scalars['String']['input']>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
};

export type UserProfile = {
  __typename?: 'UserProfile';
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type UserProjectPermission = {
  __typename?: 'UserProjectPermission';
  projectRole: Role;
  teamRole: Role;
  user: PublicUserProfile;
};

export type UserUpdateFlagsInput = {
  identifyUser?: InputMaybe<Scalars['Boolean']['input']>;
  isGAdsSignup?: InputMaybe<Scalars['Boolean']['input']>;
  isNew?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum UserUpdateGdprConsent {
  News = 'news',
  None = 'none'
}

export type UserUpdateGdprInput = {
  consent: UserUpdateGdprConsent;
  page: Scalars['String']['input'];
  userID: Scalars['ID']['input'];
};

export type UsersGetInput = {
  id: Scalars['ID']['input'];
};

export type UsersGetResponse = {
  __typename?: 'UsersGetResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  user?: Maybe<User>;
};

export type ValidateTokenResponse = {
  __typename?: 'ValidateTokenResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  valid?: Maybe<Scalars['Boolean']['output']>;
};

export type VectorizerCancelImageVectorizationInput = {
  id: Scalars['String']['input'];
};

export type VectorizerCancelImageVectorizationResponse = {
  __typename?: 'VectorizerCancelImageVectorizationResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type VectorizerCreateImageResponse = {
  __typename?: 'VectorizerCreateImageResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  id?: Maybe<Scalars['String']['output']>;
};

export type VectorizerCreateInput = {
  fileName?: InputMaybe<Scalars['String']['input']>;
  url: Scalars['String']['input'];
};

export type VectorizerCreateSignedUploadUrl = {
  fileName: Scalars['String']['input'];
  fileSize?: InputMaybe<Scalars['Int']['input']>;
};

export type VectorizerCreateSignedUploadUrlResponse = {
  __typename?: 'VectorizerCreateSignedUploadUrlResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  id: Scalars['String']['output'];
  signedUrl: Scalars['String']['output'];
};

export type VectorizerDeleteAllImagesResponse = {
  __typename?: 'VectorizerDeleteAllImagesResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  ids?: Maybe<Array<Scalars['ID']['output']>>;
  success: Scalars['Boolean']['output'];
};

export type VectorizerDeleteImageInput = {
  id: Scalars['String']['input'];
};

export type VectorizerDeleteImageResponse = {
  __typename?: 'VectorizerDeleteImageResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  success: Scalars['Boolean']['output'];
};

export type VectorizerGetImageInput = {
  id: Scalars['String']['input'];
};

export type VectorizerGetImageResponse = {
  __typename?: 'VectorizerGetImageResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  image?: Maybe<VectorizerImage>;
};

export type VectorizerImage = {
  __typename?: 'VectorizerImage';
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  originalUrl?: Maybe<Scalars['String']['output']>;
  status: StatusType;
  thumbnailUrl?: Maybe<Scalars['String']['output']>;
  vectorizedUrl?: Maybe<Scalars['String']['output']>;
};

export type VectorizerImagesRegisterToUserInput = {
  ids: Array<Scalars['String']['input']>;
};

export type VectorizerImagesRegisterToUserResponse = {
  __typename?: 'VectorizerImagesRegisterToUserResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  images: Array<VectorizerImage>;
};

export type VectorizerListImagesResponse = {
  __typename?: 'VectorizerListImagesResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  images: Array<VectorizerImage>;
};

export type VectorizerPublishImageInput = {
  id: Scalars['String']['input'];
};

export type VectorizerPublishImageResponse = {
  __typename?: 'VectorizerPublishImageResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  upload?: Maybe<StudioUploadsFile>;
};

export type VerificationBanner = {
  __typename?: 'VerificationBanner';
  backgroundColor: Scalars['String']['output'];
  bannerAboveNav: Scalars['String']['output'];
  bannerAboveNavState: Scalars['String']['output'];
  button: VerificationBannerButton;
  text: Scalars['String']['output'];
  textColor: Scalars['String']['output'];
  type: SiteBannerType;
};

export type VerificationBannerButton = {
  __typename?: 'VerificationBannerButton';
  backgroundColor: Scalars['String']['output'];
  link: Scalars['String']['output'];
  text: Scalars['String']['output'];
  textColor: Scalars['String']['output'];
};

export type AskGetTopicResponse = {
  __typename?: 'askGetTopicResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  topic?: Maybe<AskCfTopic>;
};

export type AskGetTopicsResponse = {
  __typename?: 'askGetTopicsResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  topics?: Maybe<Array<AskCfTopic>>;
};

export type AskGetUserStatsResponse = {
  __typename?: 'askGetUserStatsResponse';
  errors?: Maybe<Array<ErrorMessage>>;
  stats?: Maybe<AskCfUserStats>;
};
