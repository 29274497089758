'use client'

import * as React from 'react'
import { useTranslations } from 'next-intl'
import * as Dialog from 'primitives/dialog'
import { tw } from 'utils/classnames'

import { LoginIcon } from '@ask-cf/assets/icons'
import { StyledLink } from '@ask-cf/components/styled-link'
import { SHOW_AUTH_MODAL } from '@ask-cf/config'
import { SimpleLoginModal } from './components/simple-login-modal'
import { ROUTES } from '@ask-cf/constants/app-routes'

export function LoginButton({
  className,
  spanClassName,
}: {
  className?: string
  spanClassName?: string
}) {
  const t = useTranslations('ask_cf.components.login_button')
  const [currentUrl, setCurrentUrl] = React.useState('')

  React.useEffect(() => {
    if (window) {
      setCurrentUrl(window.location.href)
    }
  }, [])

  return SHOW_AUTH_MODAL ? (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <div
          className={tw(
            'text-text-tertiary text-md flex flex-row items-center font-normal leading-6 hover:text-blue-700',
            className,
          )}
          role="button"
        >
          <LoginIcon />
          <span className={tw('ml-1', spanClassName)}>{t('label')}</span>
        </div>
      </Dialog.Trigger>
      <React.Suspense fallback={null}>
        <Dialog.Portal forceMount>
          <Dialog.Body className="z-50 p-[16px]">
            <SimpleLoginModal />
          </Dialog.Body>
        </Dialog.Portal>
      </React.Suspense>
    </Dialog.Root>
  ) : (
    <StyledLink
      href={ROUTES.LOGIN(currentUrl)}
      className="hover:text-blue-700"
      aria-label={t('label')}
    >
      <LoginIcon />
      <span className={tw('text-md ml-1 leading-6', spanClassName)}>{t('label')}</span>
    </StyledLink>
  )
}
