interface Data {
  me?: {
    user?: {
      id: string
      isTemporary: boolean
    } | null
  } | null
}

export function isLoggedInQuerySelector<D extends Data>(data?: D): data is D {
  return Boolean(getUserIdSelector(data) && data?.me?.user?.isTemporary === false)
}

export function isLoggedInSelector(me: Data['me']) {
  return isLoggedInQuerySelector({
    me,
  })
}

export function isLoggedInUserSelector({ isTemporary, id }: { isTemporary: boolean; id: string }) {
  return isLoggedInSelector({
    user: {
      id,
      isTemporary,
    },
  })
}

export function getUserIdSelector(data?: Data) {
  return data?.me?.user?.id
}
