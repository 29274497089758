import { motion, HTMLMotionProps } from 'framer-motion'
import { tw } from 'utils/classnames'

const styles = [
  'overflow-hidden',
  // min-height bcos sometimes we use spinners inside
  'min-h-[54px]',
  // hover effect styles
  'group/gradient relative transition-[filter] outline-none',
  // base styles
  'rounded-2 text-service-white flex items-center justify-center px-[16px] py-[15px] text-center text-base font-semibold transition-shadow focus:text-service-white hover:text-service-white hover:shadow-md focus:outline-none focus-visible:outline-2 focus-visible:outline-[rgba(78,_171,_48,_.65)]',
]

export function DialogLink({
  children,
  className,
  ...props
}: React.PropsWithChildren<HTMLMotionProps<'a'>>) {
  return (
    <motion.a
      whileHover={{ scale: 1.025 }}
      whileTap={{ scale: 1.0125 }}
      className={tw(...styles, className)}
      {...props}
      rel="noreferrer"
    >
      {children}
      <HoverEffect />
    </motion.a>
  )
}

export function DialogButton({
  children,
  className,
  ...props
}: React.PropsWithChildren<HTMLMotionProps<'button'>>) {
  return (
    <motion.button
      type="button"
      whileHover={{ scale: 1.025 }}
      whileTap={{ scale: 1.0125 }}
      {...props}
      className={tw(...styles, className)}
    >
      {children}
      <HoverEffect />
    </motion.button>
  )
}

// workout if on the safari browser
const isSafari =
  typeof window !== 'undefined' && /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

// hover effect is a little glimmer at the bottom of the button on hover
function HoverEffect() {
  if (isSafari) {
    return null
  }

  return (
    <div
      className={tw(
        // basic styles
        "after:absolute after:bottom-[-15px] after:left-[45px] after:right-[45px] after:block after:h-[20px] after:transition-opacity after:content-['']",
        // quirky effect styles
        'after:[clip-path:_inset(-200%_-30%_10px_-30%_round_29px)',
        // blur affect
        'after:[filter:blur(12.5px)]',
        // hover effects
        'after:opacity-0 group-hover/gradient:after:opacity-40',
        // appearance
        'after:bg-[#fff]',
      )}
    />
  )
}
