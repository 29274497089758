import { useTranslations } from 'next-intl'

const useFormatTimeSpan = () => {
  const t = useTranslations('ask_cf.components.time_span')

  const formatTimeSpan = (milliseconds: number): string => {
    const minuteInMilliseconds = 60 * 1000
    const hourInMilliseconds = 60 * minuteInMilliseconds
    const dayInMilliseconds = 24 * hourInMilliseconds
    const weekInMilliseconds = 7 * dayInMilliseconds
    const monthInMilliseconds = 30 * dayInMilliseconds
    const yearInMilliseconds = 365.25 * dayInMilliseconds

    const getSpanLabelTranslationKey = (base: string, count: number) => {
      return `${base}.${count === 1 ? 'singular' : 'plural'}`
    }

    if (milliseconds >= yearInMilliseconds) {
      const years = Math.floor(milliseconds / yearInMilliseconds)
      const translationKey = getSpanLabelTranslationKey('years_ago', years)
      return t(translationKey, { count: years })
    } else if (milliseconds >= monthInMilliseconds) {
      const months = Math.floor(milliseconds / monthInMilliseconds)
      const translationKey = getSpanLabelTranslationKey('months_ago', months)
      return t(translationKey, { count: months })
    } else if (milliseconds >= weekInMilliseconds) {
      const weeks = Math.floor(milliseconds / weekInMilliseconds)
      const translationKey = getSpanLabelTranslationKey('weeks_ago', weeks)
      return t(translationKey, { count: weeks })
    } else if (milliseconds >= dayInMilliseconds) {
      const days = Math.floor(milliseconds / dayInMilliseconds)
      const translationKey = getSpanLabelTranslationKey('days_ago', days)
      return t(translationKey, { count: days })
    } else if (milliseconds >= hourInMilliseconds) {
      const hours = Math.floor(milliseconds / hourInMilliseconds)
      const translationKey = getSpanLabelTranslationKey('hours_ago', hours)
      return t(translationKey, { count: hours })
    } else if (milliseconds >= minuteInMilliseconds) {
      const minutes = Math.floor(milliseconds / minuteInMilliseconds)
      const translationKey = getSpanLabelTranslationKey('minutes_ago', minutes)
      return t(translationKey, { count: minutes })
    } else {
      return t('now')
    }
  }

  return formatTimeSpan
}

export { useFormatTimeSpan }
