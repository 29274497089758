import { useLogInMutation } from 'codegen/generated/user'
import { ChallengeType } from 'codegen/types'
import { AnimatePresence, motion } from 'framer-motion'
import { SpinnerIcon, User01Icon } from 'primitives/icons'
import { EyeOffIcon } from 'primitives/icons/editor'
import * as React from 'react'
import { UserAuthResponse, getDeviceId, saveToken } from 'utils/auth'
import { isError } from 'utils/js'

import { isLoggedInSelector } from 'auth/selectors'
import { DialogButton } from '../dialog-button'
import { LoginInput } from '../login-input'

const CF_URL = process.env.NEXT_PUBLIC_CF_URL || process.env.VITE_CF_URL

export function LoginForm({
  onRegister,
  onOtp,
  onSuccess,
  formState,
}: {
  onRegister(formState: FormData): void
  onOtp(formState: FormData): void
  onSuccess(user: UserAuthResponse): void
  formState: FormData | null
}) {
  const loginMutation = useLogInMutation({
    onSuccess: async function onSuccessCallback(data) {
      if (data?.login?.token) {
        await saveToken(data.login.token, 'login').catch(() => {
          throw new Error('CF_INVALID_TOKEN')
        })
        onSuccess(data.login)
        return
      }

      // otp is handled in the onSuccess of the mutation in the form callback
      // as it needs access to the form data
      if (data.login?.challenge?.type === ChallengeType.ChallengeOtp) {
        return
      }

      if (!data.login?.challenge) {
        throw new Error('CF_INVALID_USER')
      }

      throw new Error('UNKNOWN_LOGIN_ERROR')
    },
  })
  const ref = React.useRef<HTMLFormElement>(null)

  function onSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const formData = new FormData(ref.current!)

    const email = formData.get('email')?.toString() ?? ''
    const password = formData.get('password')?.toString() ?? ''
    const deviceId = getDeviceId()

    loginMutation.mutate(
      {
        input: {
          email,
          deviceId,
          password,
        },
      },
      {
        onSuccess: function onSuccessCallback(data) {
          if (data.login?.challenge?.type === ChallengeType.ChallengeOtp) {
            if (data.login.challenge.otp) {
              formData.set('otp', data.login.challenge.otp?.toString() ?? '')
            }

            onOtp(formData)
          }
        },
      },
    )
  }

  function onRegisterClick(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault()

    onRegister(new FormData(ref.current!))
  }

  function resolveErrorMessage() {
    if (loginMutation.isLoading || !loginMutation.isError) {
      return null
    }

    if (loginMutation.isPaused) {
      return (
        <>It seems like you are offline, please check your internet connection and try again.</>
      )
    }

    if (isError(loginMutation.error) && loginMutation.error.message === 'CF_INVALID_USER') {
      return (
        <>
          The password you entered for the email address{' '}
          <b className="break-all">{loginMutation.variables?.input?.email}</b> is incorrect.{' '}
          <a
            href={`${CF_URL}/my-account/lost-password/`}
            target="_blank"
            rel="noreferrer"
            className="font-bold"
          >
            Forgot your password?
          </a>
        </>
      )
    }

    return (
      <>
        Opps, something went wrong.
        <br />
        Please try again or get in touch if the problem persists.
      </>
    )
  }

  const errorMessage = resolveErrorMessage()

  const disabled =
    loginMutation.isLoading ||
    isLoggedInSelector(loginMutation.data?.login) ||
    Boolean(loginMutation.data?.login?.token) ||
    loginMutation.data?.login?.challenge?.type === ChallengeType.ChallengeOtp

  return (
    <form ref={ref} onSubmit={onSubmit} className="contents w-full">
      <div className="mb-[16px] mt-auto w-full space-y-[16px]">
        <h2 className="text-left text-xl font-extrabold text-[#272b2f]">Log in</h2>

        <div>
          <label className="mb-[4px] block cursor-pointer text-sm text-[#4b5563]" htmlFor="email">
            Email
          </label>

          <LoginInput.Root>
            <LoginInput.Field
              required
              defaultValue={formState?.get('email')?.toString()}
              autoComplete="email"
              type="email"
              name="email"
              id="email"
              className="pr-[42px]"
              autoFocus
              disabled={loginMutation.isLoading}
            />
            <LoginInput.IconRight className="text-[#9ca3af]">
              <img
                src={User01Icon.src}
                width="24"
                height="24"
                className="h-auto w-[24px] [filter:invert(76%)_sepia(6%)_saturate(464%)_hue-rotate(179deg)_brightness(84%)_contrast(93%)]"
              />
            </LoginInput.IconRight>
          </LoginInput.Root>
        </div>

        <div>
          <label
            className="mb-[4px] block cursor-pointer text-sm text-[#4b5563]"
            htmlFor="password"
          >
            Password
          </label>

          <LoginInput.Root>
            <LoginInput.Field
              required
              autoComplete="current-password"
              defaultValue={formState?.get('password')?.toString()}
              type="password"
              name="password"
              id="password"
              className="pr-[42px]"
              disabled={disabled}
            />
            <LoginInput.IconRight className="text-[#9ca3af]">
              <img
                src={EyeOffIcon.src}
                width="24"
                height="24"
                className="h-auto w-[24px] [filter:invert(76%)_sepia(6%)_saturate(464%)_hue-rotate(179deg)_brightness(84%)_contrast(93%)]"
              />
            </LoginInput.IconRight>
          </LoginInput.Root>
        </div>

        <div className="space-y-[24px]">
          <label className="flex cursor-pointer gap-2 text-[#4b5563] hover:text-[#272b2f]">
            <input type="checkbox" defaultChecked id="remember" name="remember" />
            <p className="text-sm">Stay logged in</p>
          </label>

          <div>
            <AnimatePresence initial={false}>
              {errorMessage ? (
                <motion.div
                  exit={{
                    height: 0,
                    opacity: 0,
                  }}
                  initial={{
                    height: 0,
                    opacity: 0,
                  }}
                  animate={{
                    height: 'auto',
                    opacity: 1,
                  }}
                  className="overflow-hidden"
                >
                  <p className="mt-4 text-center text-[#ff3b00]">{errorMessage}</p>
                </motion.div>
              ) : null}
            </AnimatePresence>
          </div>

          <DialogButton disabled={disabled} type="submit" className="w-full bg-violet-500">
            {disabled ? (
              <SpinnerIcon className="text-service-white h-[16px] w-[16px] animate-spin" />
            ) : (
              'Log in'
            )}
          </DialogButton>

          <a
            className="block text-sm text-violet-500 underline hover:text-[#272b2f]"
            href={`${CF_URL}/my-account/lost-password/`}
            target="_blank"
            rel="noreferrer"
          >
            I forgot my password
          </a>
        </div>
      </div>
      <div className="mt-auto w-full">
        <p className="mb-[16px] text-center">Don&apos;t have an account yet?</p>

        <DialogButton type="button" onClick={onRegisterClick} className="w-full bg-green-500">
          Create a free account
        </DialogButton>
      </div>
    </form>
  )
}
