export function isError<V>(value: V | Error): value is Error {
  return value instanceof Error
}

const errorMessages: Set<string> = new Set([
  'Failed to fetch', // chrome
  'NetworkError when attempting to fetch resource.', // firefox
  'The Internet connection appears to be offline.', // safari 16
  'Load failed', // safari 17+
  'fetch failed', // undici (Node.js)
  'Network request failed', // another possible message
])

export function isNetworkError(error: unknown): boolean {
  if (
    !(error instanceof Error) ||
    error?.name !== 'TypeError' ||
    typeof error?.message !== 'string'
  ) {
    return false
  }

  // special case for Safari 17+ with a generic message and no error stack.
  if (error?.message === 'Load failed') {
    return typeof error.stack === 'undefined'
  }

  if (typeof error === 'string') {
    return errorMessages.has(error)
  }

  return errorMessages.has(error?.message)
}
