// https://www.radix-ui.com/docs/primitives/components/dropdown-menu
import * as RadixProgress from '@radix-ui/react-progress'

export function Root(props: RadixProgress.ProgressProps) {
  return <RadixProgress.Root {...props} />
}

export function Indicator(props: RadixProgress.ProgressIndicatorProps) {
  return <RadixProgress.Indicator {...props} />
}
