import { LoginForm } from 'components/login-form'
import { LoginOtp } from 'components/login-otp-form'
import { AnimatePresence, motion } from 'framer-motion'
import * as Dialog from 'primitives/dialog'
import * as React from 'react'
import { UserAuthResponse } from 'utils/auth'
import { mergeFormData } from 'utils/dom'
import { useRefetchAuth } from 'auth/use-refetch-auth'

import { useSetUserQuery } from '@ask-cf/hooks/use-user-data'

type Step = 'login' | 'login-otp'

function useDialogState() {
  const [stepState, setStep] = React.useState<Step | { step: Step; direction: 1 | -1 }>('login')

  const step = typeof stepState === 'string' ? stepState : stepState.step
  const direction = typeof stepState === 'string' ? 1 : stepState.direction

  return { step, direction, setStep }
}

export function SimpleLoginModal() {
  const { step, direction, setStep } = useDialogState()
  const [formState, setFormState] = React.useState<FormData | null>(null)
  const { onOpenChange } = Dialog.useDialog()
  const { refetchAuth } = useRefetchAuth()
  const { setUserQuery } = useSetUserQuery()
  async function onSuccess(user: UserAuthResponse) {
    setUserQuery({ me: user })
    await refetchAuth()
    onOpenChange(false)
  }

  return (
    <div
      className="bg-background-primary relative flex h-full max-h-[85vh] w-full max-w-[497px] flex-col overflow-hidden rounded-[16px] text-gray-800"
      style={{ '--direction': direction, '--gutter': '32px' }}
      key="direction"
    >
      <div className="relative grow">
        <AnimatePresence initial={false}>
          {step === 'login' ? (
            <AnimatedDiv key="login">
              <LoginForm
                formState={formState}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onRegister={() => {}}
                onOtp={nextFormState => {
                  setFormState(mergeFormData(formState, nextFormState))
                  setStep('login-otp')
                }}
                onSuccess={onSuccess}
              />
            </AnimatedDiv>
          ) : null}
          {step === 'login-otp' ? (
            <AnimatedDiv key="login-otp">
              <LoginOtp formState={formState} onSuccess={onSuccess} />
            </AnimatedDiv>
          ) : null}
        </AnimatePresence>
      </div>
      <Dialog.CloseX className="bg-transparent text-[#6b7280] hover:bg-[#eeeff2] hover:text-[#6b7280]" />
    </div>
  )
}

function AnimatedDiv(props: React.ComponentProps<typeof motion.div>) {
  return (
    <div
      style={{
        '--scroller-gutter': '10px',
        '--scroller-thumb-initial-color': '#6B7280',
        '--scroller-thumb-color': '#6B7280',
      }}
      className="scroller-md absolute inset-0 overflow-x-hidden text-[#6B7280]"
    >
      <motion.div
        {...props}
        initial={{
          x: 'calc(var(--direction, 1) * 40px)',
          opacity: 0,
        }}
        animate={{ x: 0, opacity: 1 }}
        exit={{
          x: 'calc(var(--direction, 1) * -40px)',
          opacity: 0,
        }}
        transition={{
          duration: 0.25,
          delay: 0.1,
          ease: 'easeOut',
        }}
        className="flex min-h-full w-full flex-col items-center justify-center p-[var(--gutter)] pt-0"
      />
    </div>
  )
}
